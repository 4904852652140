import {
  Button,
  Flex,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePaymentSchedule, getAssessmentName, Note } from '@hb/shared'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { updateSharedNote } from '../../../../backend/functions'
import { useApp } from '../../../../contexts'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { UserContext } from '../../../../contexts/UserContext'
import { useAppRole } from '../../../../hooks/backend/user/useAppRole'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { useMobileLayout } from '../../../../hooks/useMobileLayout'
import { ActionButton } from '../../../Buttons/ActionButton'
import { NoteForm } from '../../../forms/NoteForm'
import LoadingIcon from '../../../LoadingIcon'
import { DefaultModal } from '../../../Modals/DefaultModal'
import { AssessmentDocuments } from '../Documents/AssessmentDocuments'
import { DraftView } from '../DraftView'
import { SelectAssessmentBox } from '../SelectAssessmentBox'
import { PaymentsDue } from './PaymentsDue'
import { PaymentsContext } from './PaymentsProvider'
import { ReceivedPayments } from './ReceivedPayments/ReceivedPayments'

export const NoPayments = () => {
  const { modal } = useContext(PaymentsContext)
  const { user, selectedAssessment } = useContext(UserContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const [isLoading, setIsLoading] = useState(false)
  const { payments, adminRef } = selectedAssessment || {}
  const { appName } = useApp()
  const update = useUpdateDoc()
  const generate = useCallback(async () => {
    if (payments?.due) {
      return processResponse({ error: 'Patient already has payments' })
    }
    if (!selectedAssessment || !user) return processResponse({ error: 'no assessment' })
    setIsLoading(true)
    const res = await update(
      adminRef,
      'payments',
      generatePaymentSchedule(user, selectedAssessment),
    )
    setIsLoading(false)
    return processResponse(res)
  }, [user, adminRef, payments, processResponse, selectedAssessment, update])
  return (
    <Flex direction="column" bg="white" p={4} shadow="md">
      <Text fontSize="md">No payments scheduled for {user?.name}</Text>
      {appName === 'app' ? (
        <Flex mt={2}>
          <ActionButton isLoading={isLoading} onClick={generate} size="sm">
            Generate Payment Schedule
          </ActionButton>
          <ActionButton size="sm" ml={2} onClick={modal.onOpen}>
            Manual Entry
          </ActionButton>
        </Flex>
      ) : null}
    </Flex>
  )
}

const PaymentsNote = () => {
  const { appName } = useApp()
  const { selectedAssessment, assessmentId } = useContext(UserContext)
  const {
    paymentsNote, adminRef, practiceRef, sharedPaymentNote,
  } = selectedAssessment || {}
  const { processResponse } = useContext(PopUpMessageContext)
  const handleSubmitSharedNote = useCallback(
    async (data: Note) => {
      if (!assessmentId) return processResponse({ error: 'No assessment selected' })
      const { text } = data
      try {
        await updateSharedNote({
          appName,
          assessmentId,
          note: text,
          noteId: 'sharedPaymentNote',
        })
        return processResponse({ success: 'Note saved' })
      } catch (err: any) {
        return processResponse({ error: err.message || 'Error saving note' })
      }
    },
    [appName, assessmentId, processResponse],
  )

  const update = useUpdateDoc('payments')
  const { mobileLayout, ref: contentRef } = useMobileLayout(700)
  return (
    <Flex
      gap={2}
      flexFlow={mobileLayout ? 'column' : 'row'}
      ref={contentRef}
      w="100%"
    >
      <NoteForm
        note={paymentsNote}
        placeholder={
          appName === 'providers-app'
            ? 'Payments note (visible to practice only)'
            : 'Payments note (visible to HB only)'
        }
        onSubmit={(v) => update(
          appName === 'providers-app' ? practiceRef : adminRef,
          'paymentsNote',
          v,
        )
        }
      />
      <NoteForm
        onSubmit={handleSubmitSharedNote}
        placeholder="Shared note (visible to HB and practice)"
        note={sharedPaymentNote}
      />
    </Flex>
  )
}

export const AssessmentPayments = ({ small }: { small?: boolean }) => {
  const {
    selectedAssessment, loading, assessmentId, user,
  } = useContext(UserContext)
  const patientId = user?.id
  const { appName } = useApp()
  const appRole = useAppRole()
  const {
    midwife, payments, drafts,
  } = selectedAssessment || {}
  const name = useMemo(
    () => (selectedAssessment ? getAssessmentName(selectedAssessment) : ''),
    [selectedAssessment],
  )
  if (!midwife && loading) return <LoadingIcon />
  return (
    <SelectAssessmentBox overlay>
      {midwife ? (
        <Stack direction="column" spacing={3}>
          <PaymentsNote/>
          {payments && Object.keys(payments).length ? (
            <>
              <PaymentsDue />
              {appName === 'app' || appRole === 'super-admin' ? (
                <ReceivedPayments small={small} />
              ) : null}
            </>
          ) : (
            <NoPayments />
          )}
          {appName === 'app' ? (
            <DraftView
              name="Receipts/Invoices Template"
              draft={drafts?.invoicesReceiptsTemplates}
              templateKey="invoiceAndReceipt"
            />
          ) : null}
          {assessmentId && patientId ? (
            <AssessmentDocuments
              adminView
              patientId={patientId}
              category="invoiceAndReceipt"
              assessmentId={assessmentId}
            />
          ) : null}
        </Stack>
      ) : (
        <Text p={4}>
          <i>No midwife assigned to {name}</i>
        </Text>
      )}
    </SelectAssessmentBox>
  )
}

export const PaymentsPopUp = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Tooltip placement="top" label="Patient Payments">
        <Button
          onClick={onOpen}
          bg="green.600"
          color="white"
          position="absolute"
          left={2}
          top={2}
          size="xs"
        >
          $
        </Button>
      </Tooltip>
      <DefaultModal
        size="2xl"
        overlayHeader
        isOpen={isOpen}
        bodyProps={{ p: 2 }}
        onClose={onClose}
        render={() => <AssessmentPayments />}
      />
    </>
  )
}
