import { ChatIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  AssessmentSnippet,
  AssessmentStage,
  ASSESSMENTS_ADMIN,
  CollectionFilter,
  colors,
  DataColumn,
  DataColumnRenderProps,
  DataColumns,
  formatDollarValue,
  getDateString,
  getPracticeAssessmentCollectionPath,
  getReverseName,
  getTotalFromInsurer,
  getTotalFromPatient,
  LogEntry,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  WithId,
} from '@hb/shared'
import { doc } from 'firebase/firestore'

import React, {
  FC, useCallback, useContext, useMemo,
} from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../../backend/db'
import { DataListContext } from '../../../contexts/DataListContext'
import { DataGridContext } from '../../../contexts/UserGridContext'
import { useUpdateDoc } from '../../../hooks'
import { useLogInfo } from '../../../hooks/backend/user/usePopulatedUser'
import assignmentIcon from '../../../icons/assignment_green.svg'
import { ActionButton } from '../../Buttons/ActionButton'
import { DisplayButtonBase } from '../../Buttons/DisplayButton'
import { RedFlagIcon, RedFlagPopover } from '../../DataView/RedFlagPopover'
import { EllipsisText } from '../../EllipsisText'
import { getStatusButton } from '../../Status/hooks'
import { LogEntryPopover, LogEntryView } from '../../Users/Profile/Log/LogEntry'
import {
  getIsUrgent,
  UrgentPopoverContent,
} from '../../Users/Profile/UrgentPopover'
import { SortColorCircle } from '../../Users/UrgentBadge'
import { monthDivider } from '../dividers/monthDivider'
import { SortButton } from '../SortButton'
import { assignedAdminsColumn } from './users/AssignedAdminsPopover'
import { ListUrgentHeader } from './users/ListUrgentPopover'
import {
  getDeliveredOnColumn,
  getOverduePaymentColumn,
  getRenderPracticeAssessmentNextAction,
  getUnreadMessagesColumn,
  insurerColumn,
  renderAssessmentNextAction,
} from './users/shared'

type AssessmentColumn = DataColumn<AssessmentSnippet | PracticeAssessmentData>

type AdminAssessmentColumn = DataColumn<AssessmentSnippet>

const providerColumn: AssessmentColumn = {
  title: 'Provider',
  Header: () => <SortButton sortKey="midwifeName">PROVIDER</SortButton>,
  sortKey: 'midwifeName',
  Render: ({ data }) => <EllipsisText text={data?.midwifeName || 'None'} />,
}

const getNameColor = (snippet: AssessmentSnippet) => {
  const { inquiryRank, stages } = snippet || {}

  switch (inquiryRank) {
    case 1:
      return 'green.500'
    case 2:
      return 'yellow.500'
    case 3:
      return stages.includes('Inquiry') ? 'red.500' : '#2D3748'
    default:
      return '#2D3748'
  }
}

const nextActionMobile: AssessmentColumn['mobile'] = {
  width: 275,
  Render: (props) => (
    <Center gap={2} pl={1} borderRadius={4} w="100%" flex={1}>
      <Text
        lineHeight={1}
        fontFamily="Hero-New"
        fontSize="xs"
        whiteSpace="nowrap"
        fontWeight={600}
        color="gray.500"
      >
        NEXT ACTION
      </Text>
      <Flex flex={1} minW="0">
        {renderAssessmentNextAction(props as any)}
      </Flex>
    </Center>
  ),
}

const getPracticeNextActionMobile = (
  practiceId: string,
): AssessmentColumn['mobile'] => ({
  width: 275,
  Render: (props) => (
    <Center gap={2} pl={1} borderRadius={4} w="100%" flex={1}>
      <Text
        lineHeight={1}
        fontFamily="Hero-New"
        fontSize="xs"
        whiteSpace="nowrap"
        fontWeight={600}
        color="gray.500"
      >
        NEXT ACTION
      </Text>
      <Flex flex={1} minW="0">
        {getRenderPracticeAssessmentNextAction(practiceId)(props as any)}
      </Flex>
    </Center>
  ),
})

export const assessmentNextActionColumn: AssessmentColumn = {
  title: 'Next Action Date',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="nextActionDate">
      <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
        NEXT ACTION
      </Text>
    </SortButton>
  ),
  width: 350,
  isLazy: true,
  sortKey: 'nextActionDate',
  Render: renderAssessmentNextAction as AssessmentColumn['Render'],
  mobile: nextActionMobile,
}

const getPracticeAssessmentNextActionColumn = (
  practiceId: string,
): AssessmentColumn => ({
  title: 'Next Action Date',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="nextActionDate">
      <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
        NEXT ACTION
      </Text>
    </SortButton>
  ),
  width: 300,
  isLazy: true,
  sortKey: 'nextActionDate',
  Render: getRenderPracticeAssessmentNextAction(
    practiceId,
  ) as AssessmentColumn['Render'],
  mobile: getPracticeNextActionMobile(practiceId),
})

const assessmentNeededFilter: CollectionFilter = ['inquiryRank', '==', 1]
const signOnFormCompleteFilter: CollectionFilter = ['inquiryRank', '==', 2]
const signedOnByPracticeFilter: CollectionFilter = ['inquiryRank', '==', 3]
const StatusFilterButton = ({
  filters,
  filter,
  color,
  text,
  onFilterClick,
  onFilterRemove,
}: {
  filters: Array<CollectionFilter>
  filter: CollectionFilter
  color: string
  text: string
  onFilterClick: () => void
  onFilterRemove: () => void
}) => (
  <HStack>
    <Button onClick={onFilterClick} color={color} variant="link" size="sm">
      {text}
    </Button>
    {filters.includes(filter) ? (
      <Button
        px={2}
        bg={color}
        color="white"
        variant="link"
        size="sm"
        onClick={onFilterRemove}
      >
        FILTER
        <CloseIcon width={3} ml={2} />
      </Button>
    ) : null}
  </HStack>
)

const renderNameColumn = (isMobile: boolean) => ({
  data,
  app,
  tabName,
}: DataColumnRenderProps<
    WithId<AssessmentSnippet | PracticeAssessmentData>
  >) => {
  const { id, status } = data || {}
  const { appName } = app
  const { buttonText, buttonColor } = getStatusButton(
    'assessment',
    status || 'incomplete',
  )
  const color = getNameColor(data as AssessmentSnippet)
  return (
      <Flex w="100%" align="center">
        <Link
          style={{
            opacity: 1,
            flex: 1,
            minWidth: 0,
          }}
          to={`${
            appName === 'app' ? '/admin' : ''
          }/assessments/${id}/${tabName === 'Claim' ? 'claims' : 'assessment'}`}
        >
          <Tooltip placement="top" hasArrow bg={buttonColor} label={buttonText}>
            <Button
              fontWeight={500}
              justifyContent="flex-start"
              fontSize="md"
              w="100%"
              minW="0"
              alignItems="center"
              color={color}
              variant="link"
              textDecoration={isMobile ? 'underline' : 'none'}
            >
              <Text
                style={{
                  textDecoration: isMobile ? 'underline' : 'none',
                }}
                textAlign="left"
                isTruncated
              >
                {data ? getReverseName(data) : 'No name entered'}
              </Text>
            </Button>
          </Tooltip>
        </Link>
      </Flex>
  )
}

export const nameStatusColumn: AssessmentColumn = {
  title: 'Patient Name / Status',
  Header: () => {
    const { filterBy, filters, removeFilter } = useContext(DataListContext)
    return (
      <Flex align="center">
        <SortButton sortKey="lname">NAME</SortButton>
        <Text mr={3}>/</Text>
        <SortButton defaultDir="desc" sortKey="inquiryRank">
          STATUS
        </SortButton>
        <Popover isLazy trigger="hover">
          <PopoverTrigger>
            <ActionButton
              _hover={{ bg: `${colors.green.hex}88` }}
              bg={colors.green.hex}
              border="none"
              color="white"
              px={2}
              lineHeight="12px"
              size="xs"
              colorScheme="blue"
            >
              KEY
            </ActionButton>
          </PopoverTrigger>
          <PopoverContent w="auto">
            <PopoverArrow />
            <Stack spacing={1} p={2}>
              <StatusFilterButton
                color="green.500"
                filter={assessmentNeededFilter}
                filters={filters}
                text="Questionnaire submitted, assessment needed"
                onFilterClick={() => filterBy(assessmentNeededFilter)}
                onFilterRemove={() => removeFilter('inquiryRank')}
              />
              <StatusFilterButton
                color="yellow.500"
                filter={signOnFormCompleteFilter}
                filters={filters}
                text="Sign on form complete, but not signed on by practice"
                onFilterClick={() => filterBy(signOnFormCompleteFilter)}
                onFilterRemove={() => removeFilter('inquiryRank')}
              />
              <StatusFilterButton
                color="red.500"
                filter={signedOnByPracticeFilter}
                filters={filters}
                text="Signed on by practice, but not on auth list"
                onFilterClick={() => filterBy(signedOnByPracticeFilter)}
                onFilterRemove={() => removeFilter('inquiryRank')}
              />
            </Stack>
          </PopoverContent>
        </Popover>
      </Flex>
    )
  },
  sortKey: 'lname',
  width: 220,
  Render: renderNameColumn(false),
  mobile: {
    width: 220,
    Render: renderNameColumn(true),
  },
}

const renderPracticeSiteNameColumn = (isMobile: boolean) => ({
  data,
  app: { appName },
}: DataColumnRenderProps<
    WithId<AssessmentSnippet | PracticeAssessmentData>
  >) => (
    <Link
      style={{ opacity: 1, flex: 1, width: '100%' }}
      to={`${appName === 'app' ? '/admin' : ''}/assessments/${
        data.id
      }/assessment`}
    >
      <Tooltip
        placement="top"
        hasArrow
        bg={data.clearedOn ? colors.green.hex : 'gray.600'}
        label={
          data.clearedOn ? 'Cleared for coverage' : 'Not cleared for coverage'
        }
      >
        <Button
          fontWeight={500}
          justifyContent="flex-start"
          fontSize="md"
          px={1}
          gap={1}
          maxW="100%"
          color={data.clearedOn ? colors.green.hex : 'gray.600'}
          variant="link"
        >
          <Text
            textAlign="left"
            flex={1}
            minW="0"
            textDecor={isMobile ? 'underline' : 'none'}
            isTruncated
          >
            {data ? getReverseName(data) : 'No name entered'}
          </Text>
          <ExternalLinkIcon width={3} />
        </Button>
      </Tooltip>
    </Link>
)

const practiceNameColumn: AssessmentColumn = {
  title: 'Patient Name',
  Header: () => <SortButton sortKey="lname">NAME</SortButton>,
  sortKey: 'lname',
  Render: renderPracticeSiteNameColumn(false),
  mobile: {
    width: 300,
    Render: renderPracticeSiteNameColumn(true),
  },
}

export const redFlagsColumn: AdminAssessmentColumn = {
  title: 'Red Flags',
  Header: () => (
    <SortButton noArrow sortKey="redFlagText">
      <Center px="6px">
        <RedFlagPopover />
      </Center>
    </SortButton>
  ),
  flexProps: {
    justify: 'center',
  },
  width: 50,
  Render: ({ data: { redFlagText, id }, cell, preview: { openPreview } }) => (
    <Flex mx="auto">
      <RedFlagIcon
        onClick={(e) => openPreview({
          id,
          item: 'redFlags',
          position: { x: e.clientX, y: e.clientY },
          cell,
        })
        }
        color={redFlagText && redFlagText !== 'zzz' ? 'yellow.600' : 'gray.400'}
      />
    </Flex>
  ),
}

const AssessmentUrgentContent = ({
  snippet,
}: {
  snippet: WithId<AssessmentSnippet>
}) => {
  const { item, collection } = useContext(DataGridContext)
  const { id } = snippet || {}

  const docId = useMemo(
    () => (collection === 'claims' ? item?.assessmentId : id),
    [collection, id, item],
  )
  const updateRef = useMemo(
    () => (docId
      ? doc(
        db,
        ['assessment-snippets', 'claims'].includes(collection)
          ? 'assessments-admin'
          : collection,
        docId,
      )
      : null),
    [collection, docId],
  )
  return <UrgentPopoverContent item={snippet} updateRef={updateRef} />
}

export const assessmentUrgentColumn: AssessmentColumn = {
  title: 'Urgent',
  Header: () => (
    <Box px={1} py="0.2rem">
      <ListUrgentHeader urgentKey="isUrgent" sortKey="urgentSort" />
    </Box>
  ),
  // sortKey: 'urgentSort',
  flexProps: { justify: 'center' },
  width: 40,
  Render: ({ data, admins }) => {
    const snippet = data as WithId<AssessmentSnippet>
    const { urgentSort } = snippet || {}
    const isUrgent = getIsUrgent(snippet)
    return (
      <Center px={1}>
        {/* <Tooltip placement='top' hasArrow label={assignedAdmins?.map((a) => getFullName(a)).join(', ') || 'No admin assigned'}> */}
        <Popover placement="right" isLazy strategy="fixed">
          <PopoverTrigger>
            <Box>
              <SortColorCircle
                withAdmins
                admins={admins}
                px={1}
                color={
                  urgentSort && urgentSort !== 'zzz' ? urgentSort : 'gray.300'
                }
                urgent={isUrgent}
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <AssessmentUrgentContent snippet={snippet} />
            <PopoverArrow />
          </PopoverContent>
        </Popover>
      </Center>
    )
  },
}

export const eddColumn: AssessmentColumn = {
  title: 'EDD',
  Header: () => (
    <Stack
      align="center"
      direction="row"
      mr={1}
      px={2}
      spacing={3}
      width="100%"
    >
      <Flex align="center" flexGrow={1}>
        <SortButton sortKey="edd">EDD</SortButton>
      </Flex>
    </Stack>
  ),
  // flexProps: { width: '100%' },
  dividers: [monthDivider],
  width: 100,
  sortKey: 'edd',
  Render: (props) => (
    <Stack
      direction="row"
      spacing={3}
      pl={1}
      pr={3}
      width="100%"
      justify="flex-start"
      align="center"
    >
      <Text p={1}>
        {props.data?.edd ? getDateString(props.data.edd, 'short') : 'None'}
      </Text>
    </Stack>
  ),
  mobile: {
    width: 130,
    Render: ({ data }) => {
      const { edd } = data || {}
      return (
        <Center
          py={1}
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg="gray.100"
          h="28px"
          borderRadius={4}
          w="100%"
          flex={1}
        >
          <Text
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color="gray.500"
          >
            EDD:
          </Text>
          <Text
            lineHeight={1}
            fontFamily="Open Sans"
            fontSize="sm"
            color="gray.600"
          >
            {edd ? getDateString(edd, 'short') : 'None'}
          </Text>
        </Center>
      )
    },
  },
}

const getRenderAssessmentLog = (
  collection: string,
  isPractice: boolean,
  isMobile: boolean,
): FC<DataColumnRenderProps<WithId<AssessmentSnippet>>> => ({ data }) => {
  const { id } = data || {}

  const log = isPractice ? (data as any).log : data?.logSnippet
  const {
    lastCreatedKey, lastCreated, lastUpdated, lastUpdatedKey,
  } = useLogInfo(log)

  // const formatted = formatPhoneNumber(phone)
  // if (isScrolling) {
  //   return (
  //     <Center w='100%'>
  //       <Spinner />
  //     </Center>
  //   )
  // }
  const reverseName = getReverseName(data)
  const update = useUpdateDoc()
  const onSave = useCallback(
    async (updated: LogEntry, createdOn?: number) => {
      const docRef = doc(db, collection, id)
      return update(docRef, 'log', {
        [createdOn || Date.now()]: updated,
      })
    },
    [update, id],
  )

  const chatIcon = <ChatIcon color={lastUpdated ? 'blue.400' : 'gray.600'} />

  return (
      <Stack flex={1} spacing={1} direction="row">
        <Popover closeOnBlur={false} isLazy strategy="fixed">
          <PopoverTrigger>
            <DisplayButtonBase
              size={isMobile ? 'sm' : 'md'}
              aria-label="log"
              icon={
                isMobile ? (
                  <Flex color="gray.600" gap={1} align="center">
                    {chatIcon}
                    <Text fontSize="xs">LOG</Text>
                  </Flex>
                ) : (
                  chatIcon
                )
              }
            />
          </PopoverTrigger>
          <PopoverContent w="auto" px={2} py={2}>
            <PopoverCloseButton />
            <PopoverArrow />
            <Text fontFamily="Hero-New" fontSize="md" fontWeight={600} px={2}>
              {reverseName}
            </Text>
            {log && lastCreatedKey ? (
              <Stack spacing={1} width="349px" divider={<StackDivider />}>
                <Box>
                  <Text
                    pt={1}
                    px={2}
                    fontWeight={500}
                    color="gray.600"
                    fontSize="sm"
                  >
                    Last created
                  </Text>
                  <LogEntryView
                    update={(updated) => onSave(updated, lastCreatedKey)}
                    createdOn={lastCreatedKey}
                    {...lastCreated!}
                  />
                </Box>
                {lastUpdatedKey && lastCreatedKey !== lastUpdatedKey ? (
                  <Box w="100%" position="relative">
                    <Text fontWeight={500} color="gray.600" fontSize="sm">
                      Last updated
                    </Text>
                    <LogEntryView
                      createdOn={lastUpdatedKey}
                      {...lastUpdated!}
                    />
                  </Box>
                ) : null}
              </Stack>
            ) : (
              <Text w="300px" px={2} color="gray.399">
                No log entries
              </Text>
            )}
            <LogEntryPopover isNew onSubmit={(added) => onSave(added)}>
              <Button
                aria-label="add log entry"
                ml="auto"
                fontWeight={499}
                size="xs"
                height="auto"
                py={1}
                color="gray.499"
                m={1}
                variant="outline"
              >
                + ADD ENTRY
              </Button>
            </LogEntryPopover>
          </PopoverContent>
        </Popover>
      </Stack>
  )
}

export const assessmentLogColumn: AdminAssessmentColumn = {
  title: 'Log / Contact Info',
  Header: () => <Text>LOG</Text>,
  isLazy: true,
  width: 60,
  Render: getRenderAssessmentLog(ASSESSMENTS_ADMIN, false, false),
  mobile: {
    width: 60,
    Render: getRenderAssessmentLog(ASSESSMENTS_ADMIN, false, true),
  },
}

export const getPracticeAssessmentLogColumn = (
  practiceId: string,
): AdminAssessmentColumn => ({
  title: 'Log / Contact Info',
  Header: () => <Text>LOG / CONTACT</Text>,
  isLazy: true,
  width: 160,
  Render: getRenderAssessmentLog(
    getPracticeAssessmentCollectionPath(practiceId),
    true,
    false,
  ),
  mobile: {
    width: 275,
    Render: getRenderAssessmentLog(
      getPracticeAssessmentCollectionPath(practiceId),
      true,
      true,
    ),
  },
})

const prmColumn: AdminAssessmentColumn = {
  title: 'PRM',
  Header: () => (
    <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
      PRM
    </Text>
  ),
  Render: ({ data }) => (
    <Text>{data?.prm ? formatDollarValue(data.prm) : 'None'}</Text>
  ),
  width: 100,
  mobile: {
    width: 100,
    Render: ({ data }) => {
      const { prm } = data || {}
      return (
        <Flex gap={1} align="center">
          <Text fontWeight={600}>PRM</Text>
          <Text color="gray.700" fontSize="md">
            {formatDollarValue(prm || 0)}
          </Text>
        </Flex>
      )
    },
  },
}

const receivedInsurancePaymentsColumn: AdminAssessmentColumn = {
  title: 'Received Insurance Payments',
  Header: () => (
    <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
      $ INS
    </Text>
  ),
  Render: ({ data }) => {
    const total = getTotalFromInsurer(data)
    return <Text>{formatDollarValue(total || 0)}</Text>
  },
  width: 100,
  mobile: {
    width: 250,
    Render: ({ data }) => {
      const total = getTotalFromPatient(data)
      return (
        <Flex gap={2} align="center">
          <Text fontSize="md" fontWeight={600}>
            Received from Insurance
          </Text>
          <Text fontSize="lg">{formatDollarValue(total || 0)}</Text>
        </Flex>
      )
    },
  },
}

const receivedPatientPaymentsColumn: AdminAssessmentColumn = {
  title: 'Received Patient Payments',
  Header: () => (
    <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
      $ PATIENT
    </Text>
  ),
  width: 100,
  Render: ({ data }) => {
    const total = getTotalFromPatient(data)
    return <Text>{formatDollarValue(total || 0)}</Text>
  },
  mobile: {
    width: 250,
    Render: ({ data }) => {
      const total = getTotalFromPatient(data)
      return (
        <Flex gap={2} align="center">
          <Text fontSize="md" fontWeight={600}>
            Received from Patient
          </Text>
          <Text fontSize="lg">{formatDollarValue(total || 0)}</Text>
        </Flex>
      )
    },
  },
}

const balanceTowardPrmColumn: AdminAssessmentColumn = {
  title: 'Balance Toward PRM',
  Header: () => (
    <Box>
      <Tooltip label="Balance Toward PRM" placement="top" hasArrow>
        <Text fontSize="sm" color="gray.600" fontFamily="Hero-New">
          BAL
        </Text>
      </Tooltip>
    </Box>
  ),
  Render: ({ data }) => (
    <Text>
      {data?.balanceTowardPrm !== undefined
        ? formatDollarValue(data.balanceTowardPrm)
        : 'None'}
    </Text>
  ),
  width: 100,
}

const signedOnColumn: AssessmentColumn = {
  title: 'Signed On Date',
  Header: () => {
    const { sortKey } = useContext(DataListContext)
    return (
      <SortButton noArrow sortKey="signedOnDate">
        <Tooltip
          placement="top"
          hasArrow
          label="Patient completed sign-on forms"
        >
          <Image
            filter={
              sortKey === 'signedOnDate' ? 'grayscale(0%)' : 'grayscale(100%)'
            }
            transition="filter 300ms"
            height="20px"
            src={assignmentIcon}
          />
        </Tooltip>
      </SortButton>
    )
  },
  width: 30,
  sortKey: 'signedOnDate',
  Render: ({ data }) => {
    const { signedOnDate } = data || {}
    return (
      <Center width="100%">
        <Tooltip
          placement="top"
          hasArrow
          bg={signedOnDate ? colors.green.hex : '#777'}
          label={
            signedOnDate
              ? `Patient completed sign on forms on ${getDateString(signedOnDate, 'short')}`
              : 'Patient has not completed sign on forms'
          }
        >
          <Image
            filter={`grayscale(${signedOnDate ? 0 : 100}%)`}
            height="20px"
            src={assignmentIcon}
          />
        </Tooltip>
      </Center>
    )
  },
  mobile: {
    width: 160,
    Render: ({ data }) => {
      const { signedOnDate } = data || {}
      return (
        <Center
          height="28px"
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg={signedOnDate ? colors.green.hex : 'gray.100'}
          borderRadius={4}
          w="100%"
          flex={1}
        >
          <Image
            filter={`brightness(${signedOnDate ? 300 : 100}%)`}
            height="18px"
            src={assignmentIcon}
          />
          <Text
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color={signedOnDate ? 'white' : 'gray.500'}
          >
            {signedOnDate ? 'SIGNED ON' : 'NOT SIGNED ON'}
          </Text>
        </Center>
      )
    },
  },
}

export const assessmentColumns: DataColumns<
  AssessmentSnippet | PracticeAssessmentData
> = {
  urgent: assignedAdminsColumn as AssessmentColumn,
  unreadMessages: getUnreadMessagesColumn(),
  overduePayment: getOverduePaymentColumn(),
  signedOn: signedOnColumn,
  edd: eddColumn,
  deliveredOn: getDeliveredOnColumn(),
  name: nameStatusColumn,
  // // urgent: assessmentUrgentColumn,
  // redFlags: redFlagsColumn,
  insurer: insurerColumn as AssessmentColumn,
  midwife: providerColumn as AssessmentColumn,
  nextAction: assessmentNextActionColumn as AssessmentColumn,
  log: assessmentLogColumn as AssessmentColumn,
  redFlags: redFlagsColumn as AssessmentColumn,
}

export const getProviderAssessmentColumns = (
  stage: PracticeAssessmentStage | AssessmentStage | undefined,
  practiceId: string,
  superAdmin: boolean,
): DataColumns<AssessmentSnippet | PracticeAssessmentData> => {
  const columns: DataColumns<AssessmentSnippet | PracticeAssessmentData> = superAdmin
    ? {
      unreadMessages: getUnreadMessagesColumn(),
      signedOn: signedOnColumn,
      edd: eddColumn,
      deliveredOn: getDeliveredOnColumn(),
      name: practiceNameColumn,
      // insurer: insurerColumn as AssessmentColumn,
      nextAction: getPracticeAssessmentNextActionColumn(practiceId),
      // log: getPracticeAssessmentLogColumn(practiceId) as AssessmentColumn,
      prm:
            stage === 'Complete' ? balanceTowardPrmColumn : (prmColumn as any),
      receivedInsurancePayments: receivedInsurancePaymentsColumn as any,
      receivedPatientPayments: receivedPatientPaymentsColumn as any,
      overduePayment: getOverduePaymentColumn(),
    }
    : {
      unreadMessages: getUnreadMessagesColumn(),
      overduePayment: getOverduePaymentColumn(),
      signedOn: signedOnColumn,
      edd: eddColumn,
      deliveredOn: getDeliveredOnColumn(),
      name: practiceNameColumn,
      // insurer: insurerColumn as AssessmentColumn,
      nextAction: getPracticeAssessmentNextActionColumn(practiceId),
      // log: getPracticeAssessmentLogColumn(practiceId) as AssessmentColumn,
    }
  return columns
}
