import { WarningIcon } from '@chakra-ui/icons'
import {
  BadgeProps, Box, Center, Circle, forwardRef, Tooltip,
} from '@chakra-ui/react'
import { LocalCollectionState } from '@hb/shared/collections'
import { UserRoleItem } from '@hb/shared/types'
import React from 'react'
import { useAdminsWithColor } from '../../hooks/backend/user/useAdminsWithColor'

const fallbackColor = 'rgb(200,200,200)'
export const SortColorCircle = forwardRef<
  Omit<BadgeProps, 'color'> & { urgent?: boolean, withAdmins?: boolean, color?: string | null, admins: LocalCollectionState<UserRoleItem> },
  'span'
>(({
  color, urgent, withAdmins, admins, ...props
}, ref) => {
  const { text } = useAdminsWithColor(admins, withAdmins, color)
  return (
    <Box p="3px" bg="#efefef" borderRadius="full" boxShadow="0 0 5px #00000088">
      <Tooltip
        bg={color || undefined}
        color="white"
        textShadow="1px 1px 3px black"
        placement="top"
        hasArrow
        label={text}
      >
        <Center pos='relative'>
          <Circle
            ref={ref}
            cursor="pointer"
            bg={color || fallbackColor}
            size="18px"
            {...props}
          />
          {
            urgent ? (
              <WarningIcon
                pos="absolute"
                pointerEvents='none'
                color='whiteAlpha.800'
                w={3}
                h={3}
              />
            ) : null
          }
        </Center>
      </Tooltip>
    </Box>
  )
})

export const UrgentBadge = ({
  urgent,
  admins,
  ...props
}: BadgeProps & { urgent?: boolean, color: string, admins: LocalCollectionState<UserRoleItem> }) => (
    <SortColorCircle withAdmins admins={admins} urgent={urgent} {...props} />
)
