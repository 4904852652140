import {
  Box,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  ASSESSMENTS_ADMIN,
  ASSESSMENT_SNIPPETS,
  getPracticeAssessmentCollectionPath,
  medicaidProvidersCollection,
  providersCollection,
} from '@hb/shared/collections'
import { colors, FieldTypes } from '@hb/shared/constants'
import { getNestedUserFields } from '@hb/shared/fields'
import inNetwork, {
  checkboxDeductibleBasedOnCalendarYearField,
} from '@hb/shared/fields/callIn/inNetwork'
import { maximumReimbursableChargesField } from '@hb/shared/fields/callIn/maximumReimbursableCharges'
import {
  followStateMandatesCheckbox,
  planDesignField,
  stateMandatesNotesField,
} from '@hb/shared/fields/callIn/planDesignAndStateMandates'
import { reimbursementOptionField } from '@hb/shared/fields/callIn/reimbursementOption'
import {
  AdminAssessmentData,
  Alert,
  Assessment,
  AssessmentSnippet,
  CheckboxField,
  Field,
  FieldMapValue,
  PopulatedAssessmentInterface,
  PopulatedUser,
} from '@hb/shared/types'
import {
  arrayToObject,
  defaultStageValidate,
  getAlertText,
  getAssessmentName,
  getCorrectedValue,
  getCurrentlyOnMedicaidPlan,
  getDateString,
  getSelectedCoverage,
  isField,
  mergeAssessmentData,
} from '@hb/shared/utils'
import { doc } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { ASSESSMENTS_REF } from '../../collections/collections'
import { useCollection } from '../../collections/hooks/useCollection'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess'
import { updateAssessmentField } from '../../hooks/backend/assessments'
import { useDocument } from '../../hooks/backend/useDocument'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { ActionLog } from '../ActionLog'
import { ActionButton } from '../Buttons'
import { Container } from '../Container'
import { CopyId } from '../CopyId'
import { Editable, EditableRow } from '../forms/Input'
import { PreviewSpan } from '../PreviewSpan'
import { StatusButton } from '../Status/StatusButton'
import { CollapsibleInsurancePlansView } from './InsurancePlansView'

const deductibleCountsCheckbox: CheckboxField = {
  ...(inNetwork.children.deductibleCountsTowardOOPM as CheckboxField),
  type: FieldTypes.CHECKBOX,
}

const noBenefitsCheckbox: CheckboxField = {
  placeholder: 'No out of network benefits',
  type: FieldTypes.CHECKBOX,
}

const AlertsView = ({
  alerts,
  miscarried,
}: {
  alerts?: Record<number, string | Alert>
  miscarried?: boolean
}) => (
  <VStack
    my={2}
    border="1px solid #990000"
    borderRadius={3}
    spacing={0}
    align="flex-start"
  >
    <Text
      pl={2}
      bg="red.600"
      color="white"
      width="100%"
      fontSize="sm"
      mb={1}
      fontWeight="bold"
    >
      ALERTS
    </Text>
    <HStack
      spacing={1}
      divider={<StackDivider />}
      align="flex-start"
      flexFlow="row wrap"
      px={1}
      pb={1}
    >
      {miscarried ? (
        <Text
          bg="red.500"
          color="white"
          fontSize="sm"
          px={2}
          borderRadius={4}
          fontWeight="bold"
        >
          MISCARRIED
        </Text>
      ) : null}
      {Object.entries(alerts || {}).map(([date, alert]) => (
        <Flex key={`${date}`} pl={1} align="center">
          <Text mr={2} color="gray.700" fontSize="sm" fontWeight={600}>
            {getDateString(parseInt(date, 10), 'short')}
          </Text>
          <Text>{getAlertText(alert)}</Text>
        </Flex>
      ))}
    </HStack>
  </VStack>
)

const HoverSummary = ({
  assessment,
  assessmentId,
}: {
  assessment: Assessment | PopulatedAssessmentInterface
  assessmentId: string
}) => {
  const providersData = useCollection(providersCollection)
  const providers = providersData?.items
  const medicaidProvidersData = useCollection(medicaidProvidersCollection)
  const medicaidProviders = medicaidProvidersData?.items

  const mergedData = useMemo(
    () => mergeAssessmentData(assessment),
    [assessment],
  )
  const selectedCoverageData = useMemo(
    () => getSelectedCoverage(
      assessment?.data || {},
      arrayToObject([...(providers || []), ...(medicaidProviders || [])])
          || {},
    ),
    [assessment, medicaidProviders, providers],
  )

  const selectedCoverageCorrection = useMemo(
    () => getSelectedCoverage(
      assessment?.corrections || {},
      arrayToObject([...(providers || []), ...(medicaidProviders || [])])
          || {},
    ),
    [assessment, medicaidProviders, providers],
  )

  const coveragePath = useMemo(() => {
    const isMedicaid = getCurrentlyOnMedicaidPlan(mergedData)
    return isMedicaid
      ? 'insurance-info.medicaidCoverage'
      : 'insurance-info.primaryCoverage'
  }, [mergedData])

  const noBenefits = useMemo(
    () => getCorrectedValue(
      assessment,
      `${coveragePath}.out-of-network.noBenefits`,
    ),
    [assessment, coveragePath],
  )

  const { processResponse } = useContext(PopUpMessageContext)
  const handleUpdate = useCallback(
    (path: string, updated: FieldMapValue) => updateAssessmentField(assessmentId, path, updated)
      .then(() => processResponse({ success: 'Answers updated!' }))
      .catch((err) => processResponse({ error: err.message })),
    [assessmentId, processResponse],
  )

  const [editingSide, setEditingSide] = useState<'right' | 'left' | null>(null)
  const onLeftOpen = useCallback(() => {
    setEditingSide('left')
  }, [])

  const onRightOpen = useCallback(() => {
    setEditingSide('right')
  }, [])

  const onEditClose = useCallback(() => {
    setEditingSide(null)
  }, [])

  const { leftWidth, rightWidth } = useMemo(() => {
    if (editingSide === 'left') {
      return { leftWidth: '600px', rightWidth: '0px' }
    }
    if (editingSide === 'right') {
      return { leftWidth: '0px', rightWidth: '600px' }
    }
    return { leftWidth: '300px', rightWidth: '300px' }
  }, [editingSide])
  return (
    <VStack
      overflow="hidden"
      borderRadius={6}
      maxW="600px"
      spacing={0}
      divider={<Divider />}
    >
      <HStack divider={<StackDivider />} align="flex-start" spacing={0}>
        <VStack
          display={editingSide === 'right' ? 'none' : 'flex'}
          w={leftWidth}
          fontWeight={400}
          spacing={0}
          align="flex-start"
        >
          <HStack
            w="100%"
            borderBottom="1px solid #cdcdcd"
            bg={colors.green.hex}
          >
            <Text pl={2} color="white" fontWeight={600}>
              In Network
            </Text>
          </HStack>
          <EditableRow
            small
            index={0}
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            onSubmit={(v) => handleUpdate(`${coveragePath}.in-network.deductible`, v)
            }
            field={inNetwork.children.deductible as Field}
            label="Deductible"
            value={selectedCoverageData?.['in-network']?.deductible}
            correction={selectedCoverageCorrection?.['in-network']?.deductible}
          />
          <EditableRow
            small
            index={1}
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            onSubmit={(v) => handleUpdate(`${coveragePath}.in-network.coinsurance`, v)
            }
            field={inNetwork.children.coinsurance as Field}
            label="Coinsurance"
            value={selectedCoverageData?.['in-network']?.coinsurance}
            correction={selectedCoverageCorrection?.['in-network']?.coinsurance}
          />
          <EditableRow
            small
            index={2}
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            onSubmit={(v) => handleUpdate(`${coveragePath}.in-network.outOfPocketMax`, v)
            }
            field={inNetwork.children.outOfPocketMax as Field}
            label="Out of pocket max"
            value={selectedCoverageData?.['in-network']?.outOfPocketMax}
            correction={
              selectedCoverageCorrection?.['in-network']?.outOfPocketMax
            }
          />
          <Editable
            index={3}
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            field={deductibleCountsCheckbox}
            value={
              selectedCoverageData?.['in-network']?.deductibleCountsTowardOOPM
            }
            correction={
              selectedCoverageCorrection?.['in-network']
                ?.deductibleCountsTowardOOPM
            }
            onSubmit={(v) => handleUpdate(
              `${coveragePath}.in-network.deductibleCountsTowardOOPM`,
              v,
            )
            }
          />
          <EditableRow
            index={4}
            label="Maximum reimbursable charges"
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            field={maximumReimbursableChargesField.children.amount as Field}
            value={
              selectedCoverageData?.['out-of-network']
                ?.maximumReimbursableCharges?.amount
            }
            correction={
              selectedCoverageCorrection?.['out-of-network']
                ?.maximumReimbursableCharges?.amount
            }
            onSubmit={(v) => handleUpdate(
              `${coveragePath}.out-of-network.maximumReimbursableCharges.amount`,
              v,
            )
            }
          />
        </VStack>
        <VStack
          display={editingSide === 'left' ? 'none' : 'flex'}
          w={rightWidth}
          fontWeight={400}
          spacing={0}
          align="flex-start"
        >
          <HStack
            w="100%"
            borderBottom="1px solid #cdcdcd"
            bg={colors.green.hex}
          >
            <Text pl={2} color="white" fontWeight={600}>
              Out of Network
            </Text>
          </HStack>
          <Editable
            index={0}
            openCallback={onRightOpen}
            closeCallback={onEditClose}
            field={noBenefitsCheckbox}
            value={selectedCoverageData?.['out-of-network']?.noBenefits}
            correction={
              selectedCoverageCorrection?.['out-of-network']?.noBenefits
            }
            style={{ width: '100%' }}
            onSubmit={(v) => handleUpdate(`${coveragePath}.out-of-network.noBenefits`, v)
            }
          />
          {noBenefits ? null : (
            <>
              <EditableRow
                small
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                index={1}
                onSubmit={(v) => handleUpdate(`${coveragePath}.out-of-network.deductible`, v)
                }
                field={inNetwork.children.deductible as Field}
                label="Deductible"
                value={selectedCoverageData?.['out-of-network']?.deductible}
                correction={
                  selectedCoverageCorrection?.['out-of-network']?.deductible
                }
              />
              <EditableRow
                small
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                index={2}
                onSubmit={(v) => handleUpdate(`${coveragePath}.out-of-network.coinsurance`, v)
                }
                field={inNetwork.children.coinsurance as Field}
                label="Coinsurance"
                value={selectedCoverageData?.['out-of-network']?.coinsurance}
                correction={
                  selectedCoverageCorrection?.['out-of-network']?.coinsurance
                }
              />
              <EditableRow
                small
                index={3}
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                onSubmit={(v) => handleUpdate(
                  `${coveragePath}.out-of-network.outOfPocketMax`,
                  v,
                )
                }
                field={inNetwork.children.outOfPocketMax as Field}
                label="Out of pocket max"
                value={selectedCoverageData?.['out-of-network']?.outOfPocketMax}
                correction={
                  selectedCoverageCorrection?.['out-of-network']?.outOfPocketMax
                }
              />
              <Editable
                index={4}
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                field={deductibleCountsCheckbox}
                value={
                  selectedCoverageData?.['out-of-network']
                    ?.deductibleCountsTowardOOPM
                }
                correction={
                  selectedCoverageCorrection?.['out-of-network']
                    ?.deductibleCountsTowardOOPM
                }
                onSubmit={(v) => handleUpdate(
                  `${coveragePath}.out-of-network.deductibleCountsTowardOOPM`,
                  v,
                )
                }
              />
            </>
          )}

          <Editable
            index={5}
            openCallback={onRightOpen}
            closeCallback={onEditClose}
            field={checkboxDeductibleBasedOnCalendarYearField}
            value={
              selectedCoverageData?.['in-network']
                ?.deductibleBasedOnCalendarYear
            }
            correction={
              selectedCoverageCorrection?.['in-network']
                ?.deductibleBasedOnCalendarYear
            }
            onSubmit={(v) => handleUpdate(
              `${coveragePath}.in-network.deductibleBasedOnCalendarYear`,
              v,
            )
            }
          />
        </VStack>
      </HStack>
      <VStack w="100%" fontWeight={400} spacing={0} align="flex-start">
        <HStack w="100%" borderBottom="1px solid #cdcdcd" bg={colors.green.hex}>
          <Text color="white" pl={2} fontWeight={600}>
            Plan Design
          </Text>
        </HStack>
        <EditableRow
          small
          index={6}
          label="Plan Design"
          field={planDesignField}
          value={
            selectedCoverageData?.['plan-design-and-state-mandates']?.planDesign
          }
          correction={
            selectedCoverageCorrection?.['plan-design-and-state-mandates']
              ?.planDesign
          }
          onSubmit={(v) => handleUpdate(
            `${coveragePath}.plan-design-and-state-mandates.planDesign`,
            v,
          )
          }
        />
        <Editable
          small
          index={7}
          field={followStateMandatesCheckbox}
          value={
            selectedCoverageData?.['plan-design-and-state-mandates']
              ?.followsStateMandates
          }
          correction={
            selectedCoverageCorrection?.['plan-design-and-state-mandates']
              ?.followsStateMandates
          }
          onSubmit={(v) => handleUpdate(
            `${coveragePath}.plan-design-and-state-mandates.followsStateMandates`,
            v,
          )
          }
        />
        <EditableRow
          label="State mandates notes"
          small
          index={8}
          field={stateMandatesNotesField}
          value={
            selectedCoverageData?.['plan-design-and-state-mandates']
              ?.followsStateMandatesNote
          }
          correction={
            selectedCoverageCorrection?.['plan-design-and-state-mandates']
              ?.followsStateMandatesNote
          }
          onSubmit={(v) => handleUpdate(
            `${coveragePath}.plan-design-and-state-mandates.followsStateMandatesNote`,
            v,
          )
          }
        />
        <EditableRow
          label="Reimbursement option"
          small
          index={9}
          field={reimbursementOptionField}
          value={
            selectedCoverageData?.['out-of-network']?.reimbursementOption
              ?.optionText
          }
          correction={
            selectedCoverageCorrection?.['out-of-network']?.reimbursementOption
              ?.optionText
          }
          onSubmit={(v) => handleUpdate(
            `${coveragePath}.out-of-network.reimbursementOption.optionText`,
            v,
          )
          }
        />
      </VStack>
    </VStack>
  )
}

export const AssessmentPreview: React.FC<{
  user: PopulatedUser
  id: string
  assessment?: Assessment | PopulatedAssessmentInterface
  admin?: boolean
  selected?: boolean
  onSelect: () => void
  buttonText?: string
}> = ({
  assessment, onSelect, id, buttonText = 'Open', selected,
}) => {
  const assessmentRef = useMemo(() => doc(ASSESSMENTS_REF, id), [id])

  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const update = useUpdateDoc()
  const { data: adminData } = useDocument<AdminAssessmentData>(
    ASSESSMENTS_ADMIN,
    appName === 'providers-app' ? null : id,
  )
  const { data: assessmentSnippet } = useDocument<AssessmentSnippet>(
    appName === 'providers-app'
      ? getPracticeAssessmentCollectionPath(selectedPracticeId || '')
      : ASSESSMENT_SNIPPETS,
    id,
  )
  const {
    submittedOn,
    sentOn,
    submittedBy,
    answersUpdatedOn,
    answersUpdatedBy,
    correctionsUpdatedBy,
    correctionsUpdatedOn,
    archivedOn,
    patientId,
    resultsViewedOn,
    previousResults,
    sentBy,
  } = assessment || {}
  const {
    alerts, miscarried, draftSavedBy, draftSavedOn,
  } = adminData || {}

  const hasAlerts = useMemo(
    () => alerts && !!Object.keys(alerts).length,
    [alerts],
  )

  const nestedUserFields = useMemo(
    () => getNestedUserFields(true, assessment || undefined),
    [assessment],
  )
  const mergedData = useMemo(
    () => mergeAssessmentData(assessment || undefined),
    [assessment],
  )
  const missingFields = useMemo(() => {
    const missing = defaultStageValidate(nestedUserFields, true)(mergedData)
    if (missing) {
      const missingNames = Object.keys(missing).map((key) => {
        const child = nestedUserFields.children[key]
        return isField(child) ? child.placeholder : child.name
      })
      return missingNames
    }
    return []
  }, [mergedData, nestedUserFields])
  const edd = useMemo(() => mergedData?.delivery?.edd, [mergedData])

  const { status, deliveredOn } = assessmentSnippet || {}

  return (
    <Container
      shadow="md"
      style={{
        padding: '1rem',
        boxSizing: 'border-box',
        height: 'auto',
        alignItems: 'flex-start',
        background: 'white',
        boxShadow: selected ? 'inset 0 0 6px rgb(255, 180, 180)' : undefined,
        border: selected ? '1px solid #cdcdcd' : undefined,
        borderRadius: '4px',
        minHeight: 40,
        margin: '5px 0px',
      }}
    >
      <HStack w="100%" mb={0} fontWeight={600} justify="space-between">
        <Editable
          onSubmit={(v) => update(assessmentRef, 'name', v)}
          value={assessment ? getAssessmentName(assessment) : 'Loading...'}
          field={{ type: FieldTypes.TEXT, placeholder: 'Assessment Name' }}
          style={{
            flex: 1,
            minWidth: 0,
          }}
          dataCellProps={{
            fontSize: '16px',
            fontFamily: 'hero-new',
            minWidth: 0,
            textOverflow: 'ellipsis',
            maxW: '100%',
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            flex: 1,
            fontWeight: 500,
          }}
        />
        {appName === 'app' ? (
          <Popover
            strategy="fixed"
            placement="bottom-end"
            isLazy
            closeOnBlur={false}
            trigger="hover"
          >
            <PopoverTrigger>
              <ActionButton
                size="sm"
                variant="solid"
                onClick={onSelect}
                ml="auto"
              >
                {buttonText}
              </ActionButton>
            </PopoverTrigger>
            <PopoverContent boxShadow="1px 1px 6px #00000077" width="auto">
              <PopoverArrow />
              {assessment ? (
                <HoverSummary assessmentId={id} assessment={assessment} />
              ) : (
                <HStack>
                  <CircularProgress isIndeterminate size={5} />
                  <Text>Loading...</Text>
                </HStack>
              )}
            </PopoverContent>
          </Popover>
        ) : (
          <ActionButton size="sm" variant="solid" onClick={onSelect} ml="auto">
            {buttonText}
          </ActionButton>
        )}
      </HStack>
      {hasAlerts || miscarried ? (
        <AlertsView miscarried={miscarried} alerts={alerts} />
      ) : null}
      <StatusButton
        type="assessment"
        status={status || 'awaiting-questionnaire'}
      />
      <Box w="100%">
        <Box px={2} pb={2}>
          <HStack align="center">
            <Text fontWeight="bold" color="gray.500" fontSize="sm">
              EDD
            </Text>
            <Text>{edd ? getDateString(edd, 'short') : 'None'}</Text>
          </HStack>
          <HStack mb={1} align="center">
            <Text fontWeight="bold" color="gray.500" fontSize="sm">
              DELIVERED
            </Text>
            <Text>{deliveredOn ? getDateString(deliveredOn) : 'No'}</Text>
          </HStack>
          {status !== 'incomplete' ? (
            <VStack spacing={1} align="flex-start">
              {resultsViewedOn ? (
                <ActionLog
                  action="Assessment viewed"
                  by={patientId}
                  on={resultsViewedOn}
                />
              ) : null}
              {sentOn ? (
                <ActionLog action="Assessment sent" on={sentOn} by={sentBy} />
              ) : null}
              {draftSavedBy || draftSavedOn ? (
                <ActionLog
                  action="Draft saved"
                  on={draftSavedOn}
                  by={draftSavedBy}
                />
              ) : null}
              {previousResults?.length
                ? previousResults.map((res) => (
                    <ActionLog
                      key={`${res.sentOn}`}
                      action="Previous assessment sent"
                      on={res.sentOn || undefined}
                      by={res.sentBy || undefined}
                    />
                ))
                : null}
              {correctionsUpdatedBy || correctionsUpdatedOn ? (
                <ActionLog
                  action="Corrections updated"
                  on={correctionsUpdatedOn}
                  by={correctionsUpdatedBy}
                />
              ) : null}
              {answersUpdatedBy || answersUpdatedOn ? (
                <ActionLog
                  action="Answers updated"
                  on={answersUpdatedOn}
                  by={answersUpdatedBy}
                />
              ) : null}
              {submittedBy || submittedOn ? (
                <ActionLog
                  action="Answers submitted"
                  on={submittedOn}
                  by={submittedBy}
                />
              ) : null}
            </VStack>
          ) : (
            <Box>
              <PreviewSpan
                style={{
                  width: '100%',
                  fontWeight: 600,
                  color: colors.red.hex,
                }}
              >
                Questionnaire Incomplete:
              </PreviewSpan>
              {missingFields ? (
                <Box>
                  {missingFields.map((fieldName) => (
                    <Text key={fieldName} style={{ color: colors.red.hex }}>
                      {fieldName}
                    </Text>
                  ))}
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
        {appName === 'app' ? (
          <Box w="100%" mt={2}>
            <CollapsibleInsurancePlansView />
          </Box>
        ) : null}
        {archivedOn ? (
          <Text fontSize="sm" color="red.600" fontWeight="bold">
            ARCHIVED {getDateString(archivedOn, 'short')}{' '}
          </Text>
        ) : null}
      </Box>
      <CopyId item={{ id }} />
    </Container>
  )
}
