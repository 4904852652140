import { MIDWIVES } from '@hb/shared/collections/collections/practice'
import { Practice } from '@hb/shared/types/providers'
import React, {
  createContext, PropsWithChildren, useContext, useMemo, useState,
} from 'react'
import { useApp } from '../../../contexts/AppContext'
import { usePracticeAccess } from '../../../contexts/PracticeAccess/PracticeAccessProvider'
import { useDocument } from '../../../hooks/backend/useDocument'

type TemplatePracticeTemplateContextData = {
  practice: Practice | null
  selectPractice: (practiceId: string) => void
  practiceId: string | null
  isLoading: boolean
}
const TemplatePracticeDataContext = createContext<TemplatePracticeTemplateContextData>({
  selectPractice: () => {},
  practiceId: null,
  practice: null,
  isLoading: false,
})
const useTemplatePracticeData = () => {
  const { appName } = useApp()
  const [manualPracticeId, setManualPracticeId] = useState<string | null>(
    null,
  )
  const { selectedPracticeId } = usePracticeAccess()
  const practiceId = useMemo(
    () => (appName === 'providers-app' ? selectedPracticeId : manualPracticeId),
    [selectedPracticeId, appName, manualPracticeId],
  )

  const { data: practice, loading: practiceLoading } = useDocument<Practice>(
    MIDWIVES,
    practiceId,
  )

  return useMemo(
    () => ({
      practiceId,
      selectPractice: setManualPracticeId,
      practice,
      isLoading: practiceLoading,
    }),
    [practiceId, practice, practiceLoading],
  )
}

export const TemplatePracticeDataProvider = ({ children }: PropsWithChildren) => {
  const contextData = useTemplatePracticeData()
  return (
    <TemplatePracticeDataContext.Provider value={contextData}>
      {children}
    </TemplatePracticeDataContext.Provider>
  )
}

export const useTemplatePractice = () => useContext(TemplatePracticeDataContext)
