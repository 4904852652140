import { ChevronDownIcon, LinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image, Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  AbilityClaim,
  AbilityClaimError,
  Claim, CLAIMS_ABILITY_DETAIL,
  colors,
  FieldMapValue,
  getDateString,
  getDateTimeString,
  getInovalonClaimStatusColor,
  WithId,
} from '@hb/shared'

import React, { useContext, useMemo, useState } from 'react'
import { useDocument } from '../../../../../hooks/backend/useDocument'
import { useFormattedValue } from '../../../../../hooks/useFormattedValue'
import { Expandable } from '../../../../Expandable'
import { ClaimInfo } from '../ClaimInfo'
import { serviceTypeField } from './fields'
import { LinkAbilityClaim } from './LinkAbilityClaim'
import { NullifyClaimPopover } from './NullifyClaim'

type ClaimsViewData = {
  selectedId: string | null
  select: (id: string) => void
  updatingAbilityId: string | null
  setUpdatingAbilityId: (id: string | null) => void
  creatingNewLegacy: boolean
  setCreatingNewLegacy: (val: boolean) => void
  creatingNewAbilityClaim: boolean
  setCreatingNewAbilityClaim: (val: boolean) => void
}

const ClaimsViewContext = React.createContext<ClaimsViewData>({
  selectedId: null,
  select: () => {},
  updatingAbilityId: null,
  setUpdatingAbilityId: () => {},
  creatingNewLegacy: false,
  setCreatingNewLegacy: () => {},
  creatingNewAbilityClaim: false,
  setCreatingNewAbilityClaim: () => {},
})

export const toClaimName = (data: FieldMapValue) => `${data?.serviceType?.toString().toUpperCase() || 'NO SERVICE TYPE'}${
  data.serviceType === 'other' && data.serviceTypeOther
    ? ` (${data.serviceTypeOther})`
    : ''
}: ${data.status?.toString().toUpperCase()} - ${
  data.serviceDates || 'No dates of service'
} - ${data.cptCodes || 'No cpt codes'}`

const AbilityClaimErrorView = ({ error }: { error: AbilityClaimError }) => {
  const { messsage, name, related_fields } = error
  const fieldName = useMemo(() => {
    const [field] = related_fields || []
    return field?.name || ''
  }, [related_fields])
  const [expanded, setExpanded] = useState(false)
  const statusColor = error.override_enabled === 'Y' ? 'yellow.500' : 'red.500'
  return (
    <VStack
      spacing={0}
      borderRadius={4}
      w="100%"
      borderWidth="2px"
      borderColor={statusColor}
    >
      <HStack w="100%" p={2} bg={statusColor} justify="space-between">
        <VStack align="flex-start" spacing={0}>
          {fieldName ? (
            <Text fontSize="sm" color="white">
              {fieldName.toUpperCase()}{' '}
            </Text>
          ) : null}
          <Text color="white">{name}</Text>
        </VStack>
        <IconButton
          minH="0"
          minW="0"
          _hover={{ bg: 'whiteAlpha.400' }}
          h="auto"
          p={2}
          bg="transparent"
          onClick={() => setExpanded(!expanded)}
          aria-label={expanded ? 'collapse' : 'expand'}
          color="white"
          icon={<ChevronDownIcon />}
        />
      </HStack>
      <Collapse in={expanded} style={{ width: '100%' }}>
        <Text px={2} py={1} whiteSpace="pre-wrap">
          {decodeURI(messsage)}
        </Text>
      </Collapse>
    </VStack>
  )
}
const AbilityClaimErrors = ({
  errors,
}: {
  errors?: Array<AbilityClaimError>
}) => {
  const { overridden, notOverridden } = useMemo(() => {
    const over: Array<AbilityClaimError> = []
    const notOver: Array<AbilityClaimError> = []
    errors?.forEach((e) => {
      if (e.override_enabled === 'Y') over.push(e)
      else notOver.push(e)
    })
    return { overridden: over, notOverridden: notOver }
  }, [errors])
  if (!errors?.length) return null
  return (
    <VStack w="100%" align="flex-start">
      <Text color="red.600" decoration="underline">
        ERRORS
      </Text>
      {notOverridden.map((e, i) => (
        <AbilityClaimErrorView key={`error_${i}`} error={e} />
      ))}
      <Expandable
        header={() => (
          <Text py={1} textDecor="underline" color="yellow.600">
            OVERRIDDEN ERRORS
          </Text>
        )}
      >
        <VStack w="100%">
          {overridden.map((e, i) => (
            <AbilityClaimErrorView key={`error_${i}`} error={e} />
          ))}
        </VStack>
      </Expandable>
    </VStack>
  )
}

export const AbilityClaimContent = ({
  claim,
  error,
  systemClaimId,
  systemClaim,
  preview,
  withErrors,
}: {
  claim: AbilityClaim | null
  systemClaim?: WithId<Claim> | null
  systemClaimId: string
  error: string | null
  withErrors?: boolean
  preview?: boolean
}) => {
  const { setUpdatingAbilityId } = useContext(ClaimsViewContext)
  const firstService = useMemo(
    () => Object.values(claim?.Services || {})[0],
    [claim],
  )
  const serviceType = useFormattedValue(
    serviceTypeField,
    systemClaim?.serviceType,
  )
  const statusColor = useMemo(
    () => getInovalonClaimStatusColor(claim?.TransStatus),
    [claim],
  )

  const canResubmit = useMemo(
    () => claim
      && ['ERROR', 'VALIDATED', 'ACK_PENDING'].includes(claim?.TransStatus || ''),
    [claim],
  )
  if (error) {
    return <Text color="red">{error}</Text>
  }
  if (claim) {
    return (
      <>
        {withErrors ? <AbilityClaimErrors errors={claim?.errors} /> : null}
        <ClaimInfo header="SERVICE TYPE">
          <Text isTruncated>{serviceType || 'NONE'}</Text>
        </ClaimInfo>
        <ClaimInfo header="PATIENT CONTROL NO.">
          <Text isTruncated>{claim.PatientCtlNo || 'NONE'}</Text>
        </ClaimInfo>
        {/* <ClaimInfo header='ID'>
          <Text>{claim.TransID || 'NONE'}</Text>
        </ClaimInfo> */}
        <ClaimInfo header="STATUS">
          <HStack>
            <Text color={statusColor}>{claim.TransStatus || 'NONE'}</Text>
            {canResubmit ? (
              <Button
                bg={colors.green.hex}
                color="white"
                onClick={() => setUpdatingAbilityId(systemClaim?.abilityId || null)
                }
                size="xs"
              >
                RESUBMIT
              </Button>
            ) : null}
          </HStack>
        </ClaimInfo>
        <ClaimInfo header="SERVICE DATE">
          <Text>
            {firstService?.FromDate
              ? `${getDateString(firstService.FromDate, 'short')}`
              : 'NONE'}
          </Text>
        </ClaimInfo>
        <ClaimInfo header="CHARGE">
          <Flex gap={2} align="center">
            <Text>${claim.ClaimCharge}</Text>
            {preview ? null : (
              <NullifyClaimPopover
                systemClaimId={systemClaimId}
                systemClaim={systemClaim}
              />
            )}
          </Flex>
        </ClaimInfo>
        <ClaimInfo header="CREATED ON">
          <Text isTruncated>
            {claim.CreateDate
              ? getDateTimeString(new Date(claim.CreateDate), 'short')
              : 'NONE'}
          </Text>
        </ClaimInfo>
        <Tooltip
          bg="#d6cf38"
          color="#251f21"
          hasArrow
          placement="top"
          label="Open in Inovalon (Log into Ease All-Payer first)"
        >
          <IconButton
            variant="ghost"
            borderRadius="full"
            aria-label="Open in Inovalon"
            onClick={(e) => {
              e.stopPropagation()
              window.open(
                `https://portal.focusedi.com/claim/view/${systemClaim?.abilityId}/prof`,
                '_blank',
              )
            }}
            icon={
              <Image
                height="20px"
                src="/images/Inovalon-Logo.svg"
                alt="Ability Logo"
              />
            }
          />
        </Tooltip>
      </>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}

const AbilityClaimMoreContent = ({
  claim,
  loading,
}: {
  claim: AbilityClaim | null
  loading: boolean
}) => {
  if (claim) {
    return (
      <>
        <ClaimInfo header="CLAIM NO.">
          <Text isTruncated>{claim?.ClaimID?.ClaimNo || 'NONE'}</Text>
        </ClaimInfo>
        <ClaimInfo header="CREATE MODE">
          <Text isTruncated>{claim.CreateMode || 'NONE'}</Text>
        </ClaimInfo>

        {/* <ClaimInfo header='ID'>
          <Text>{claim.TransID || 'NONE'}</Text>
        </ClaimInfo> */}
      </>
    )
  }
  if (loading) {
    return (
      <HStack>
        <CircularProgress
          isIndeterminate
          size="24px"
          color={colors.green.hex}
        />
        <Text>Loading...</Text>
      </HStack>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}
export const AbilityClaimView = ({
  claim,
  withMore,
  claimId,
}: {
  claim?: Claim
  withMore?: boolean
  claimId: string | null
}) => {
  const { abilityId } = claim || {}
  const {
    data: abilityClaim,
    loading,
    error,
  } = useDocument<AbilityClaim>(CLAIMS_ABILITY_DETAIL, abilityId)
  const [isRelinking, setIsRelinking] = useState(false)
  if (!claimId) return null
  return (
    <VStack spacing={0} width="100%" align="flex-start">
      {loading ? (
        <HStack justify="center" w="100%" p={2} align="center">
          <Spinner color="gray.400" />
          <Text color="gray.600">Loading claim from ability...</Text>
        </HStack>
      ) : (
        <VStack w="100%" position="relative" spacing={0} align="flex-start">
          {abilityId && !isRelinking ? (
            <>
              <Tooltip bg="#afbd20" label="Re-link ability claim">
                <Box pos="absolute" right={0} top={0}>
                  <Button
                    disabled={loading}
                    _hover={{ bg: '#cedf40' }}
                    onClick={() => setIsRelinking(true)}
                    bg="#afbd20"
                    size="xs"
                  >
                    <LinkIcon color="white" />
                  </Button>
                </Box>
              </Tooltip>
              <AbilityClaimContent
                withErrors
                systemClaim={claim}
                systemClaimId={claimId}
                error={error}
                claim={abilityClaim}
              />
            </>
          ) : (
            <LinkAbilityClaim
              onCancel={() => setIsRelinking(false)}
              isRelink={isRelinking}
              onComplete={() => setIsRelinking(false)}
              claim={claim}
              claimId={claimId}
              assessmentId={null}
            />
          )}
          {abilityClaim && withMore && !isRelinking ? (
            <AbilityClaimMoreContent loading={loading} claim={abilityClaim} />
          ) : null}
        </VStack>
      )}
    </VStack>
  )
}
