import { LinkIcon } from '@chakra-ui/icons'
import {
  CircularProgress,
  Flex,
  HStack,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  adminsCollection,
  assessmentsCollection,
  Collection,
  DocData,
  getPracticeAssessmentsCollection,
  getPracticePatientsCollection,
  invoicesCollection,
  midwivesCollection,
  patientsCollection,
} from '@hb/shared/collections'
import { colors } from '@hb/shared/constants'
import { PracticeInvoice } from '@hb/shared/invoicing'
import {
  AppName,
  AssessmentSnippet,
  PracticeAssessmentData,
  PracticeWithAdmin,
  UserRoleItem,
  UserWithPracticeData,
  WebpageMetadata,
} from '@hb/shared/types'
import {
  getAssessmentName,
  getDateString,
  getFullName,
  getValidUrl,
} from '@hb/shared/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchWebpageMetadata } from '../../../backend/functions'
import { useApp } from '../../../contexts/AppContext'
import { usePracticeAccess } from '../../../contexts/PracticeAccess/PracticeAccessProvider'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'
import assessmentIcon from '../../../icons/assessment.svg'
import invoiceIcon from '../../../icons/payments.svg'
import personIcon from '../../../icons/person_fill.svg'
import practiceIcon from '../../../icons/pregnancy.svg'
import { isHBPathname } from './utils'

const deconstructHref = (href: string) => {
  const validUrl = getValidUrl(href)
  if (!validUrl) throw new Error('Invalid URL')
  const url = new URL(validUrl)
  return {
    hostname: url.hostname,
    pathname: url.pathname,
  }
}
// Define a generic type Wrapper that takes a type parameter T
// type Wrapper<T> = { value: T };

// Define the TransformUnion type function
type TransformUnion<U, T extends Collection> = U extends DocData
  ? T extends Collection
    ? Collection<U>
    : never
  : never
type InternalLinkTypeId =
  | 'assessments'
  | 'patients'
  | 'practices'
  | 'invoices'
  | 'admins'
  | 'your-assessment'
type InternalLinkDataTypes = {
  assessments: AssessmentSnippet | PracticeAssessmentData
  patients: UserRoleItem | UserWithPracticeData
  practices: PracticeWithAdmin
  invoices: PracticeInvoice
  admins: UserRoleItem
  'your-assessment': any
}
type InternalLinkType<Id extends InternalLinkTypeId> = {
  id: Id
  name: string
  itemName?: string
  icon: string
  getPath: (
    appName: AppName,
    practiceId: string | undefined | null,
    id: string
  ) => string | null
  getItemName?: (item: InternalLinkDataTypes[Id]) => string
  getItemSubtitle?: (item: InternalLinkDataTypes[Id]) => string
  getCollection?: (
    appName: AppName,
    practiceId: string | undefined | null
  ) => TransformUnion<InternalLinkDataTypes[Id], Collection>
}
type InternalLink = {
  type: InternalLinkTypeId
  id: string
}

const linkTypes: { [Key in InternalLinkTypeId]: InternalLinkType<Key> } = {
  assessments: {
    id: 'assessments',
    name: 'Assessments',
    itemName: 'Assessment',
    icon: assessmentIcon,
    getPath: (appName, _, id) => (appName === 'app' ? `/admin/assessments/${id}` : `/assessments/${id}`),
    getCollection: (appName, practiceId) => {
      if (appName === 'app') return assessmentsCollection
      if (!practiceId) throw new Error('Practice id is required')
      return getPracticeAssessmentsCollection(practiceId)
    },
    getItemName: (item) => getFullName(item),
    getItemSubtitle: (item) => getAssessmentName(item),
  },
  patients: {
    id: 'patients',
    name: 'Patients',
    itemName: 'Patient',
    icon: personIcon,
    getPath: (appName, _, id) => (appName === 'app' ? `/admin/patients/${id}` : `/patients/${id}`),
    getCollection: (appName, practiceId) => {
      if (appName === 'app') return patientsCollection
      if (!practiceId) throw new Error('Practice id is required')
      return getPracticePatientsCollection(practiceId)
    },
    getItemName: (item) => getFullName(item),
  },
  practices: {
    id: 'practices',
    name: 'Practices',
    itemName: 'Practice',
    icon: practiceIcon,
    getPath: (appName, _, id) => `/${appName}/practices/${id}`,
    getCollection: () => midwivesCollection,
    getItemName: (item) => item.name,
  },
  invoices: {
    id: 'invoices',
    name: 'Invoices',
    itemName: 'Invoice',
    icon: invoiceIcon,
    getPath: (appName, _, id) => (appName === 'app' ? `/super-admin/invoices/${id}` : `/invoices/${id}`),
    getCollection: () => invoicesCollection,
    getItemName: (item) => {
      if (item.invoiceNumber) return `Invoice #${item.invoiceNumber}`
      return item.scheduledFor
        ? `Invoice scheduled for ${getDateString(item.scheduledFor, 'short')}`
        : `Invoice due on ${getDateString(item.dueDate, 'short')}`
    },
  },
  admins: {
    id: 'admins',
    name: 'HB Admins',
    itemName: 'Admin',
    icon: personIcon,
    getPath: (appName, _, id) => (appName === 'app' ? `/admin/admins/${id}` : `/hb-admins/${id}`),
    getCollection: () => adminsCollection,
    getItemName: (item) => getFullName(item),
  },
  'your-assessment': {
    id: 'your-assessment',
    name: 'Your Assessment',
    icon: assessmentIcon,
    getPath: (appName) => (appName !== 'app' ? '/assessment' : null),
  },
}

// const linkIcons: Record<InternalLinkType, string> = {
//   assessment: assessmentIcon,
//   patient: personIcon,
//   practice: practiceIcon,
//   invoice: invoiceIcon,
//   admin: personIcon,
// }

const getInternalLink = (pathname: string): InternalLink | null => {
  let normalized = pathname.startsWith('/admin') ? pathname.slice(6) : pathname

  normalized = pathname.startsWith('/super-admin')
    ? pathname.slice(12)
    : normalized

  const [type, id = '', id2 = ''] = normalized.split('/').filter((i) => !!i)
  switch (type) {
    case 'assessments':
      return { type: 'assessments', id }
    case 'patients':
      return { type: 'patients', id }
    case 'practices':
      return { type: 'practices', id }
    case 'invoices':
      return { type: 'invoices', id: id2 }
    case 'admins':
      return { type: 'admins', id }
    case 'assessment':
      return { type: 'your-assessment', id }
    default:
      return null
  }
}

const InternalLinkView = ({ link }: { link: InternalLink }) => {
  const { id, type } = link
  const { appName, appDisplayName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const linkType = useMemo(() => linkTypes[type], [type])
  const path = useMemo(
    () => linkType.getPath(appName, selectedPracticeId || '', id),
    [linkType, id, appName, selectedPracticeId],
  )

  const collection = useMemo(() => {
    if (!linkType.getCollection) return null
    return linkType.getCollection(appName, selectedPracticeId)
  }, [linkType, appName, selectedPracticeId])

  const { item } = useCollectionItem(collection as Collection<any> | null, id)

  const displayedName = useMemo(() => {
    if (item && linkType.getItemName) return linkType.getItemName(item)
    return linkType.itemName || linkType.name
  }, [item, linkType])

  const displayedSubtitle = useMemo(() => {
    if (item && linkType.getItemSubtitle) return linkType.getItemSubtitle(item)
    return ''
  }, [item, linkType])

  const body = (
    <HStack
      display="inline-flex"
      spacing={1}
      py={1}
      px={2}
      my={1}
      as="span"
      bg="white"
      borderRadius={6}
      boxShadow="1px 1px 3px #00000077"
    >
      <Image
        opacity={0.7}
        maxW="20px"
        height="20px"
        src={linkType.icon}
        alt="icon"
      />
      <Flex flexFlow="column">
        <span style={{ lineHeight: 1, color: colors.blue.hex }}>
          {displayedName}
        </span>
        {displayedSubtitle ? (
          <Text
            as='span'
            lineHeight={1.1}
            color="gray.600"
            style={{
              fontFamily: 'Open Sans',
              fontSize: '0.9rem',
              flex: 1,
              minWidth: 0,
            }}
          >
            {displayedSubtitle}
          </Text>
        ) : null}
      </Flex>
    </HStack>
  )
  if (!path) {
    return (
      <Tooltip
        hasArrow
        whiteSpace="none"
        textAlign="center"
        placement="top"
        bg="whitesmoke"
        color="gray.700"
        fontSize="xs"
        label={`This link is unavailable on the ${appDisplayName}`}
      >
        {/* <span style={{ cursor: 'default', opacity: 0.7 }}>{body}</span> */}
        {body}
      </Tooltip>
    )
  }
  return (
    <Link to={path}>
      <Tooltip
        whiteSpace="none"
        textAlign="center"
        hasArrow
        placement="top"
        color="whitesmoke"
        bg={colors.blue.hex}
        label={`Link to ${linkType.itemName || linkType.name}`}
      >
        {body}
      </Tooltip>
    </Link>
  )
}

// fetch link and display title and favicon
export const LinkPreview = ({ href }: { href: string }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [webpageData, setWebpagedata] = useState<WebpageMetadata | null>(null)
  const { title, iconUrl, description } = webpageData || {}

  useEffect(() => {
    fetchWebpageMetadata({ url: href })
      .then(({ data }) => {
        setLoading(false)
        setWebpagedata(data)
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message)
      })
  }, [href])

  const body = loading ? (
    <span style={{ padding: '0.18rem 0.3rem' }}>
      <CircularProgress color={colors.green.hex} isIndeterminate size={5} />
      <span style={{ color: '#777', marginLeft: '0.3rem' }}>
        Loading preview...
      </span>
    </span>
  ) : (
    <>
      <span
        style={{ display: 'inline-flex', flexFlow: 'column', width: '100%' }}
      >
        <span
          style={{
            display: 'inline-flex',
            flexFlow: 'row',
            gap: 4,
            alignItems: 'center',
          }}
        >
          {iconUrl && (
            <Image src={iconUrl} alt="favicon" maxW="16px" maxH="16px" />
          )}
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontFamily: 'Hero-New',
              fontSize: '0.9rem',
              color: error ? colors.red.hex : '#777777',
              lineHeight: 1,
              padding: '0.2rem 0',
              flex: 1,
              minWidth: 0,
            }}
          >
            {title || error || 'Unable to get page title'}
          </span>
          <LinkIcon w="12px" />
        </span>
        {description ? (
          <Text
            noOfLines={2}
            lineHeight={1.2}
            style={{
              fontFamily: 'Open Sans',
              fontSize: '0.9rem',
              color: '#999999',
              flex: 1,
              minWidth: 0,
            }}
          >
            {description}
          </Text>
        ) : null}
      </span>
    </>
  )

  return (
    <Flex
      maxW="100%"
      as="span"
      display="inline-flex"
      flexFlow="column"
      cursor="pointer"
    >
      <Flex
        as="span"
        px={2}
        py={1}
        align="center"
        gap={1}
        bg="white"
        maxW="100%"
        borderRadius={6}
        boxShadow="1px 1px 3px #00000077"
      >
        {body}
      </Flex>
    </Flex>
  )
}

const ExternalLink = ({ href }: { href: string }) => {
  const displayed = useMemo(() => {
    const noProtocol = href.replace(/^https?:\/\//, '').replace('www.', '')
    return noProtocol.endsWith('/') ? noProtocol.slice(0, -1) : noProtocol
  }, [href])

  return (
    <a target="_blank" href={href}>
      <span style={{ color: colors.blue.hex }}>{displayed}</span>
    </a>
  )
}

export const SiteLink = ({ href }: { href: string }) => {
  const { hostname, pathname } = useMemo(() => deconstructHref(href), [href])

  const isInternal = useMemo(() => isHBPathname(hostname), [hostname])
  if (isInternal) {
    const internalLink = getInternalLink(pathname)
    if (internalLink) {
      return <InternalLinkView link={internalLink} />
    }
  }
  return <ExternalLink href={href} />
}
