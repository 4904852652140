import { Center, Text, VStack } from '@chakra-ui/react'
import { NextAction } from '@hb/shared/types'
import { getPlanNextAction, getPlansArray } from '@hb/shared/utils'
import React, { useContext, useMemo } from 'react'
import { UserContext } from '../../../../contexts'
import { Expandable } from '../../../Expandable'
import { Loading } from '../../../Loading'
import { CoverageNextAction } from '../NextAction'

export const PlanNextActions = () => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)

  const { plans, nextActions } = selectedAssessment || {}

  const plansArr = useMemo(() => getPlansArray(plans || {}), [plans])
  const planNextActions = useMemo(
    () => plansArr.reduce(
      (acc, curr) => {
        acc[curr.id] = getPlanNextAction(nextActions || {}, curr.id)
        return acc
      },
        {} as Record<string, NextAction | undefined>,
    ),
    [nextActions, plansArr],
  )

  if (!plansArr.length) {
    return null
  }
  return (
    <Expandable
      borderTop="1px solid #cdcdcd"
      style={{ width: '100%' }}
      initExpanded
      headerProps={{ bg: 'white' }}
      header={() => (
        <Text fontWeight={600} color="#777" w="100%" px={2} py={1}>
          Coverage Next Actions
        </Text>
      )}
    >
      {assessmentId ? (
        <VStack bg="#efefef" spacing={0} w="100%">
          {plansArr.map((plan) => (
            <CoverageNextAction
              coverage={plan}
              key={plan.id}
              assessmentId={assessmentId}
              id={plan.id}
              nextAction={planNextActions[plan.id]}
            />
          ))}
        </VStack>
      ) : (
        <Center>
          <Loading text="Loading insurance plans..." />
        </Center>
      )}
    </Expandable>
  )
}
