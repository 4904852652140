import {
  Center,
  Flex,
  Spinner,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'
import { useApp } from '../../../contexts/AppContext'
import { ScreenContext } from '../../../contexts/ScreenContext'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { UserContext } from '../../../contexts/UserContext'
import { useUserFromId } from '../../../hooks/backend/user/useUserFromId'
import { useAuth } from '../../../store/auth'
import { AssessmentSummary } from '../../Assessments/AssessmentSummary'
import { DefaultModal } from '../../Modals/DefaultModal'
import { AssessmentAuthorizations } from './Authorizations/AssessmentAuthorizations'
import { Visits } from './Charting/Visits'
import { AssessmentClaims } from './Claims/AssessmentClaims'
import { AssessmentFiles } from './Files/AssessmentFiles'
import { useProfileTabNames } from './hooks'
import { AssessmentLog } from './Log/AssessmentLog'
import { Resizable } from './Log/Resizable'
import { PatientSummary } from './PatientSummary'
import { AssessmentPayments } from './Payments/AssessmentPayments'
import { PaymentsDueModal } from './Payments/PaymentsDue/PaymentsDueModal'
import { PaymentsProvider } from './Payments/PaymentsProvider'
import { ProfileHeader } from './ProfileHeader'
import { UserProfileProps } from './types'
import { UrgentAlertsPopUp } from './UrgentAlertsPopUp'

const ProfileTabsPanel = ({
  width,
  height,
}: {
  width: number
  height: number
}) => {
  const { appName } = useApp()
  const { assessmentId } = useContext(UserContext)
  const { isMobile } = useContext(ScreenContext)
  return (
    <TabPanels
      // @ts-ignore
      overflowY={isMobile ? 'unset' : 'auto'}
      style={{ width: 'auto' }}
      bg="#f3f3f3"
      height={isMobile ? 'auto' : `${height}px`}
      // height={contentHeight}
    >
      <TabPanel bg="gray.100" minH="100%" px={0}>
        <PatientSummary />
      </TabPanel>
      <TabPanel bg="gray.100" minH="100%" py={2} px={0}>
        <AssessmentSummary />
      </TabPanel>
      {appName === 'app' && assessmentId ? (
        <TabPanel p={isMobile ? 1 : 2}>
          <AssessmentPayments />
        </TabPanel>
      ) : null}
      {appName === 'app' && assessmentId ? (
        <TabPanel p={0}>
          <AssessmentAuthorizations />
        </TabPanel>
      ) : null}
      {appName === 'app' && assessmentId ? (
        <TabPanel p={0}>
          <AssessmentClaims />
        </TabPanel>
      ) : null}
      {/* {appName === 'app' ? ( */}
      {assessmentId ? (
        <TabPanel>
          <AssessmentFiles />
        </TabPanel>
      ) : null}
      {assessmentId ? (
        <TabPanel p={0}>
          <Visits height={height - 40} width={width} />
        </TabPanel>
      ) : null}
      {/* ) : null} */}
    </TabPanels>
  )
}

export const UserProfile: React.FC<UserProfileProps> = ({
  userId,
  assessmentId,
  selectAssessment,
  tab,
  onTabSelect,
  claimId,
  onClose,
}) => {
  const me = useAuth((s) => s.user)
  const userData = useUserFromId(
    true,
    selectAssessment,
    userId,
    assessmentId,
    claimId,
  )
  const { appName } = useApp()

  const profileTabNames = useProfileTabNames()
  const index = useMemo(
    () => (tab ? profileTabNames.indexOf(tab) : 0),
    [tab, profileTabNames],
  )
  const { height, width: sWidth, isMobile } = useContext(ScreenContext)
  const [headerHeight, setHeaderHeight] = useState(0)

  const { modalHeight, contentHeight, width } = useMemo(() => {
    const mHeight = height - 50
    const cHeight = isMobile ? mHeight : mHeight - headerHeight
    const w = sWidth - (isMobile ? 20 : 50)
    return { modalHeight: mHeight, contentHeight: cHeight, width: w }
  }, [height, sWidth, isMobile, headerHeight])

  const { user, selectedAssessment, loading } = userData
  const { loading: assessmentLoading } = selectedAssessment || {}
  const { reverseName } = user || {}
  useEffect(() => {
    if (reverseName) document.title = `${reverseName} - HB`
    return () => {
      document.title = 'Hamilton Billing'
    }
  }, [reverseName])

  const isOpen = useMemo(
    () => !!userId || !!assessmentId || !!claimId,
    [claimId, userId, assessmentId],
  )

  return (
    <UserContext.Provider value={userData}>
      <ThemeContext.Provider
        value={{ hideLabels: true, placeholderAbove: true }}
      >
        <PaymentsProvider>
          <DefaultModal
            isOpen={isOpen}
            closeOnOverlayClick={false}
            overlayHeader
            render={() => (
              <>
                {me && user && !loading && !assessmentLoading ? (
                  // <Stack
                  //   spacing={[4, 4, 4, 4, 2]}
                  //   direction={['column', 'column', 'column', 'column', 'row']}
                  // >
                  <Flex height={modalHeight} flexFlow="column">
                    <Tabs
                      index={index}
                      onChange={(i) => onTabSelect(profileTabNames[i])}
                      style={{
                        height: `${contentHeight}px`,
                        background: 'rgba(255,255,255,0.9)',
                      }}
                      isLazy
                    >
                      <ProfileHeader
                        onResize={(size) => {
                          setHeaderHeight(size.height)
                        }}
                      />

                      {isMobile || appName === 'providers-app' ? (
                        <ProfileTabsPanel
                          height={contentHeight}
                          width={width}
                        />
                      ) : (
                        <Resizable
                          width={width}
                          height={contentHeight}
                          localStorageId="user-profile-resize"
                          leftChild={(leftWidth) => (
                            <ProfileTabsPanel
                              height={contentHeight}
                              width={leftWidth}
                            />
                          )}
                          rightChild={(rightWidth) => (
                            <AssessmentLog
                              width={rightWidth}
                              // width={logWidth}
                              // setWidth={setLogWidth}
                              maxHeight={contentHeight}
                            />
                          )}
                        />
                      )}
                    </Tabs>
                  </Flex>
                ) : (
                  // </Stack>
                  <Center color="gray.600" px={2} py={4} bg="#FFC7BC">
                    <Spinner mr={2} />
                    <Text>Loading...</Text>
                  </Center>
                )}
                <PaymentsDueModal />
                {assessmentLoading || loading ? null : <UrgentAlertsPopUp />}
              </>
            )}
            size="6xl"
            // size='6xl'
            onClose={onClose}
            contentProps={{
              // m: '50px',
              padding: 0,
              maxH: modalHeight,
              height: modalHeight,
              maxWidth: '100%',
              width,
              // height: modalHeight,
              bg: '#efefef',
              overflow: 'hidden',
            }}
            bodyProps={{
              height: modalHeight,
              width,
              padding: 0,
              maxHeight: modalHeight,
              overflow: 'hidden',
              display: 'flex',
              flexFlow: 'column',
              overflowY: isMobile ? 'auto' : 'hidden',
            }}
          />
        </PaymentsProvider>
      </ThemeContext.Provider>
    </UserContext.Provider>
  )
}
