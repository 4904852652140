import { ShortcutArgs, TemplateKey } from '@hb/shared/types'
import React, {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react'

export type EditMode = 'template' | 'document'
type TemplateDataContextValue = {
  shortcutArgs: ShortcutArgs
  type: TemplateKey
  editMode: EditMode
}
export const TemplateDataContext = createContext<TemplateDataContextValue>({
  type: 'assessments',
  shortcutArgs: {},
  editMode: 'template',
})

export const TemplateDataProvider = ({
  data,
  type,
  editMode,
  children,
}: PropsWithChildren<{ data: ShortcutArgs; type: TemplateKey, editMode: EditMode }>) => {
  const value = useMemo<TemplateDataContextValue>(
    () => ({ shortcutArgs: data, type, editMode }),
    [data, type, editMode],
  )
  return (
    <TemplateDataContext.Provider value={value}>
      {children}
    </TemplateDataContext.Provider>
  )
}

export const useTemplateData = () => useContext(TemplateDataContext)
