import React from 'react'
import { useAuth } from '../../store/auth'
import { ContactFormModal } from './Contact/ContactFormModal'
import { EmailVerificationModal } from './EmailVerificationModal'
import { TwoFactorAuthModal } from './MultiFactor/TwoFactorAuthModal'
import { NameEntryModal } from './NameEntryModal'
import { PushNotificationsSettingsModal } from './PushNotificationsSettingsModal'

export const RootModals = () => {
  const claims = useAuth((s) => s.claims)
  return (
    <>
      <PushNotificationsSettingsModal />
      <NameEntryModal />
      {claims?.email_verified ? null : <EmailVerificationModal />}
      <TwoFactorAuthModal />
      <ContactFormModal />
    </>
  )
}
