import { EmailIcon } from '@chakra-ui/icons'
import {
  Badge,
  CircularProgress,
  Divider,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  adminRoles,
  colors,
  DropdownField,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  PracticeAccess,
  PracticeUserAccess,
  PracticeUserRoleItem,
  PRACTICE_ACCESS,
  UpdateCallback,
  UserRoleItem,
  USER_ROLES,
} from '@hb/shared'
import { FORM_ERROR, ValidationErrors } from 'final-form'
import { doc, updateDoc } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { getUserIdFromEmail } from '../../../backend'
import { addPracticeUser, editPracticeUser } from '../../../backend/practice'
import { PRACTICE_ACCESS_REF } from '../../../collections/collections'
import { PopUpMessageContext } from '../../../contexts'
import { useDocument } from '../../../hooks/backend/useDocument'
import { useArrayFromRecord } from '../../../hooks/useRecordFromArray'
import { ActionButton, DeleteButton } from '../../Buttons'
import { Expandable } from '../../Expandable'
import { Editable, EditableRow, SimpleForm } from '../../forms'
import { DefaultModal } from '../../Modals/DefaultModal'
import { usePracticeUsers } from '../hooks'

const step1Field: FieldMap = {
  name: 'Step 1: Enter user email',
  children: {
    email: {
      placeholder: 'Email',
      type: FieldTypes.EMAIL,
    },
  },
}

const LoadingMessage = ({ message }: { message: string }) => (
  <HStack spacing={2} borderRadius={4} p={2} w="100%">
    <CircularProgress size={6} color={colors.green.hex} isIndeterminate />
    <Text>{message}</Text>
  </HStack>
)

const practiceRoleField: DropdownField = {
  placeholder: 'Role',
  type: FieldTypes.DROPDOWN,
  options: [
    { text: 'Practice Super Admin', id: 'super-admin' },
    { text: 'Practice Admin', id: 'admin' },
  ],
}

const newUserField: FieldMap = {
  name: 'New Account',
  children: {
    fname: {
      placeholder: 'First Name',
      type: FieldTypes.TEXT,
    },
    lname: {
      placeholder: 'Last Name',
      type: FieldTypes.TEXT,
    },
    role: practiceRoleField,
  },
}

export const PracticeUserBadge = ({
  user,
  selected,
  index,
  select,
}: {
  user: Partial<PracticeUserRoleItem>
  selected?: boolean
  index?: number
  select?: () => void
}) => (
  <HStack
    borderRadius={4}
    color="white"
    tabIndex={index}
    py={2}
    _hover={{
      bg: 'green.400',
    }}
    cursor={select ? 'pointer' : 'default'}
    px={3}
    onClick={select}
    transition="background 300ms"
    bg={selected ? colors.green.hex : 'blackAlpha.500'}
    w="100%"
  >
    <VStack align="flex-start" spacing={0}>
      <Text lineHeight={1} color="gray.50" fontSize="sm">
        {user.email}
      </Text>
      <Text fontWeight={500} fontFamily="Hero-New" lineHeight={1}>
        {user.fname} {user.lname}
      </Text>
    </VStack>
    <Badge ml="auto">{user.role || 'No role'}</Badge>
  </HStack>
)

const SelectOrAddPracticeAdmin = ({
  searchedEmail,
  fetchedUserId,
  practiceId,
  onClose,
  practiceAccess,
}: {
  searchedEmail: string
  fetchedUserId: string | null
  onClose: () => void
  practiceId: string
  practiceAccess: PracticeAccess | null
}) => {
  const { loading, data: existingUser } = useDocument<UserRoleItem>(
    USER_ROLES,
    fetchedUserId || null,
  )

  const existingUserAccessRole = useMemo(
    () => (fetchedUserId
      ? practiceAccess?.users?.[fetchedUserId]?.role || null
      : null),
    [fetchedUserId, practiceAccess],
  )

  const existingUserAccess = useMemo<Partial<PracticeUserAccess> | null>(
    () => (existingUser
      ? {
        fname: existingUser.fname,
        lname: existingUser.lname,
        nickname: existingUser.nickname,
        email: existingUser.email,
        role: existingUserAccessRole || undefined,
      }
      : null),
    [existingUser, existingUserAccessRole],
  )

  const onSubmit = useCallback(
    async (val: FieldMapValue): Promise<UpdateCallback> => {
      try {
        const { fname, lname, role } = val
        if (fetchedUserId) {
          await editPracticeUser({ userId: fetchedUserId, role, practiceId })
          onClose()
          return { success: 'User added to practice' }
        }
        await addPracticeUser({
          email: searchedEmail,
          fname,
          lname,
          role,
          practiceId,
        })
        onClose()
        return { success: 'User added to practice' }
      } catch (err: any) {
        return { error: err?.message || 'Error searching for user' }
      }
    },
    [fetchedUserId, practiceId, searchedEmail, onClose],
  )

  const onSubmitForValidation = useCallback(
    async (val: FieldMapValue): Promise<ValidationErrors> => {
      try {
        const res = await onSubmit(val)
        if (res.error) return { [FORM_ERROR]: res.error }
        return undefined
      } catch (err: any) {
        return { [FORM_ERROR]: err?.message || 'Error searching for user' }
      }
    },
    [onSubmit],
  )

  if (loading) {
    return <LoadingMessage message="Loading user data..." />
  }
  return (
    <HStack spacing={2} borderRadius={4} p={2} w="100%">
      {existingUser && existingUserAccess ? (
        <VStack align="flex-start" w="100%" px={2} spacing={1}>
          <Text color="gray.600">Step 2: Confirm user and select role</Text>
          <PracticeUserBadge user={existingUserAccess} />
          <EditableRow
            initEditing
            label="Role"
            onSubmit={(role) => onSubmit({ role })}
            field={practiceRoleField}
            value={existingUser.role}
          />
        </VStack>
      ) : (
        <VStack align="flex-start" w="100%" px={2} spacing={0}>
          <Text fontSize="sm" color="gray.600">
            No user found with email {searchedEmail}
          </Text>
          <Text color="gray.600">Step 2: Enter user details</Text>
          <SimpleForm onSubmit={onSubmitForValidation} field={newUserField} />
        </VStack>
      )}
    </HStack>
  )
}

const AddPracticeUserModal = ({
  onClose,
  practiceId,
  practiceAccess,
}: {
  onClose: () => void
  practiceId: string
  practiceAccess: PracticeAccess | null
}) => {
  const [searchedEmail, setSearchedEmail] = useState('')
  const [searchingByEmail, setSearchingByEmail] = useState(false)
  const [fetchedUserId, setFetchedUserId] = useState<string | null>(null)

  const { showError } = useContext(PopUpMessageContext)
  const searchByEmail = useCallback(
    async (val: FieldMapValue): Promise<ValidationErrors> => {
      const { email } = val
      if (!email) {
        showError('Please enter an email address')
        return { email: 'Please enter an email address' }
      }
      setSearchingByEmail(true)
      try {
        const res = await getUserIdFromEmail({ email })

        setSearchingByEmail(false)
        setFetchedUserId(res.data)
        setSearchedEmail(email)
        return undefined
      } catch (err: any) {
        setSearchingByEmail(false)
        showError(err?.message || 'Error searching for user')
        return { [FORM_ERROR]: err?.message || 'Error searching for user' }
      }
    },
    [showError],
  )
  return (
    <DefaultModal
      isOpen
      onClose={onClose}
      overlayHeader
      render={() => (searchingByEmail ? (
          <HStack spacing={2} borderRadius={4} p={3} w="100%">
            <CircularProgress
              size={6}
              color={colors.green.hex}
              isIndeterminate
            />
            <Text>Searching for user...</Text>
          </HStack>
      ) : (
          <VStack
            spacing={0}
            borderRadius={4}
            p={2}
            align="flex-start"
            bg="gray.50"
            w="100%"
          >
            <Text fontFamily="Comfortaa" pt={2} px={3}>
              Add User to Practice
            </Text>
            {searchedEmail ? (
              <SelectOrAddPracticeAdmin
                onClose={onClose}
                searchedEmail={searchedEmail}
                fetchedUserId={fetchedUserId}
                practiceAccess={practiceAccess}
                practiceId={practiceId}
              />
            ) : (
              <>
                <Text color="gray.500" fontFamily="Open Sans" pt={2} px={3}>
                  Step 1: Enter user email
                </Text>
                <SimpleForm
                  theme="detailed"
                  boxProps={{
                    bg: 'transparent',
                    boxShadow: 'none',
                    padding: '0.2rem',
                  }}
                  field={step1Field}
                  onSubmit={searchByEmail}
                />
              </>
            )}
          </VStack>
      ))
      }
    />
  )
}

const PracticeUserView = ({
  id,
  practiceId,
  access,
  isEmailTarget,
  setEmailTarget,
}: {
  id: string
  practiceId: string
  access: PracticeUserRoleItem
  isEmailTarget: boolean
  setEmailTarget: () => Promise<void>
}) => {
  const [isSettingEmailTarget, setIsSettingEmailTarget] = useState(false)

  const onSetEmailTarget = useCallback(async () => {
    setIsSettingEmailTarget(true)
    try {
      await setEmailTarget()
    } catch (err: any) {
      console.error(err)
    }
    setIsSettingEmailTarget(false)
  }, [setEmailTarget])
  return (
    <HStack
      sx={{ ':nth-of-type(2n)': { background: 'rgb(245,245,245)' } }}
      px={2}
      py={1}
      w="100%"
    >
      <VStack spacing={0} flex={1} align="flex-start">
        <HStack>
          <Text>
            {access?.fname} {access?.lname}
          </Text>
          <Tooltip
            placement="top"
            hasArrow
            bg={isEmailTarget ? colors.green.hex : 'gray.500'}
            color="white"
            label={
              isEmailTarget
                ? 'Sending invoice notifications to this account'
                : 'Click to set as invoice email recipient'
            }
          >
            <IconButton
              variant="ghost"
              isLoading={isSettingEmailTarget}
              size="xs"
              onClick={onSetEmailTarget}
              aria-label="Set as invoice notification recipient"
              border={isEmailTarget ? '1px solid green' : '1px solid #777'}
              icon={
                <EmailIcon
                  color={isEmailTarget ? colors.green.hex : 'gray.500'}
                />
              }
            />
          </Tooltip>
        </HStack>
        <Text color="gray.600" fontSize="sm">
          {access?.email}
        </Text>
      </VStack>
      <VStack spacing={0} align="flex-end">
        <HStack>
          <Text lineHeight={1} fontSize="sm" color="red.600" fontWeight={600}>
            Remove practice access
          </Text>
          <DeleteButton
            itemName="practice membership"
            onDelete={() => editPracticeUser({ userId: id, role: null, practiceId })
            }
          />
        </HStack>
        <HStack>
          <Text fontSize="sm" color="gray.600" fontWeight={600}>
            Role
          </Text>
          <Editable
            field={practiceRoleField}
            value={access.role}
            onSubmit={(role) => editPracticeUser({ userId: id, role, practiceId }).then(
              (res) => res.data,
            )
            }
          />
        </HStack>
      </VStack>
    </HStack>
  )
}
const PracticeUsersView = ({ practiceId }: { practiceId: string }) => {
  const [isAddingUser, setIsAddingUser] = useState(false)

  const { data: practiceUsersRecord } = usePracticeUsers(practiceId, adminRoles)

  const practiceUsers = useArrayFromRecord(practiceUsersRecord)

  const { data: practiceAccess } = useDocument<PracticeAccess>(
    PRACTICE_ACCESS,
    practiceId,
  )

  const { showError, showSuccess } = useContext(PopUpMessageContext)
  const handleSetEmailTarget = useCallback(
    async (id: string) => {
      try {
        await updateDoc(
          doc(PRACTICE_ACCESS_REF, practiceId),
          'sendInvoicesTo',
          practiceAccess?.sendInvoicesTo === id ? null : id,
        )
        showSuccess('Invoice email recipient updated')
      } catch (err: any) {
        showError(err?.message || 'Error updating invoice email recipient')
      }
    },
    [showError, showSuccess, practiceAccess, practiceId],
  )

  return (
    <>
      <Expandable
        initExpanded
        header={() => (
          <HStack pr={2} w="100%">
            <Text py={1}>Practice Admins</Text>
            <ActionButton
              onClick={(e) => {
                e.stopPropagation()
                setIsAddingUser(true)
              }}
              ml="auto"
              color="white"
              border="none"
              bg={colors.green.hex}
              size="xs"
            >
              Add Practice Admin
            </ActionButton>
          </HStack>
        )}
      >
        <VStack divider={<Divider />} w="100%" align="flex-start" spacing={0}>
          {practiceUsers?.length ? (
            practiceUsers.map((user) => (
              <PracticeUserView
                setEmailTarget={() => handleSetEmailTarget(user.id)}
                isEmailTarget={practiceAccess?.sendInvoicesTo === user.id}
                key={user.id}
                practiceId={practiceId}
                id={user.id}
                access={user}
              />
            ))
          ) : (
            <Text p={1} color="gray.600">
              No practice admins
            </Text>
          )}
        </VStack>
      </Expandable>
      {isAddingUser ? (
        <AddPracticeUserModal
          practiceAccess={practiceAccess}
          practiceId={practiceId}
          onClose={() => setIsAddingUser(false)}
        />
      ) : null}
    </>
  )
}

export const PracticeAccessView = ({ practiceId }: { practiceId: string }) => (
  <VStack
    bg="white"
    borderRadius={4}
    border="1px solid #cdcdcd"
    shadow="md"
    px={3}
    py={2}
    spacing={0}
    align="flex-start"
    w="100%"
  >
    <PracticeUsersView practiceId={practiceId} />
  </VStack>
)
