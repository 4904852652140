import { TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  FlexProps,
  HStack,
  StackDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  A4_DIMS_PIXELS,
  Assessment,
  ASSESSMENTS,
  AssessmentStatus,
  assessmentTemplatesCollection,
  colors,
  defaultInitialValue,
  Descendant,
  EditorVersion,
  FieldMapValue,
  getNestedUserFields,
  makeAllFieldsAdmin,
  mergeAssessmentData,
  PopulatedAssessment,
  PopulatedAssessmentInterface,
  UpdateCallback,
  User,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'

import { doc, updateDoc } from 'firebase/firestore'
import { set as nestedSet } from 'nested-property'
import * as React from 'react'
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Text as SlateText } from 'slate'
import { ASSESSMENTS_REF, USERS_REF } from '../../collections/collections'
import { useApp } from '../../contexts/AppContext'
import { AssessmentContext } from '../../contexts/AssessmentContext'
import { FormLabelsContext } from '../../contexts/FormLabelsContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../contexts/ScreenContext'
import { UserContext } from '../../contexts/UserContext'
import { updateAssessmentAnswers } from '../../hooks/backend/assessments'

import { useCollectionItem } from '../../hooks/backend/useCollectionItem'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { useAuth } from '../../store/auth'
import { ActionLog } from '../ActionLog'
import { ActionButton } from '../Buttons/ActionButton'
import { BlueButton } from '../Buttons/BlueButton'
import { DataView } from '../DataView'
import { Expandable } from '../Expandable'
import { DefaultModal } from '../Modals/DefaultModal'
import { ReadOnlyEditor, useEditorWidth } from '../RichText'
import { AssessmentTemplateView } from '../Templates/TemplateViewModal'
import { TemplateViewProps } from '../Templates/types'
import { AssessmentLog } from '../Users/Profile/Log/AssessmentLog'
import { AssessmentPayments } from '../Users/Profile/Payments/AssessmentPayments'
import { AdminInsurancePlansView } from './InsurancePlansView'

const AssessmentAnswers: React.FC<{
  assessment: PopulatedAssessment
  assessmentId: string
  onSave?: (data: FieldMapValue) => Promise<UpdateCallback>
  onSubmit?: () => Promise<any>
  width: number
}> = ({
  assessment, onSubmit, onSave, assessmentId, width,
}) => {
  const { data, corrections } = assessment
  const fields = useMemo(
    () => makeAllFieldsAdmin(getNestedUserFields(true, assessment)),
    [assessment],
  )
  const { processResponse } = useContext(PopUpMessageContext)
  const baseStoragePath = useMemo(
    () => `${ASSESSMENTS}/${assessmentId}/corrections`,
    [assessmentId],
  )
  const [submitting, setSubmitting] = useState(false)

  return (
    <Box w="100%" px={2}>
      <DataView
        withEditModal
        isCorrecting={!!data}
        baseStoragePath={baseStoragePath}
        alwaysExpanded
        field={fields}
        data={data}
        onSubmit={onSave}
        updateField={async (path, value) => {
          if (!assessmentId) return { error: 'internal error' }
          const updated = { ...corrections }
          nestedSet(updated, path, value)
          return updateAssessmentAnswers({
            id: assessmentId,
            data: updated,
          })
            .then(() => processResponse({ success: 'Updated!' }))
            .catch((err) => processResponse({ error: err.message }))
        }}
        corrections={corrections}
      />
      {!assessment.submittedOn && onSubmit ? (
        <BlueButton
          mt={4}
          size="sm"
          isLoading={submitting}
          onClick={async () => {
            setSubmitting(true)
            try {
              await onSubmit()
            } catch (err) {
              console.error(err)
            }
            setSubmitting(false)
          }}
        >
          Submit Questionnaire
        </BlueButton>
      ) : null}
      <Expandable
        mt={3}
        bg="gray.50"
        width={`${width - 40}px`}
        borderRadius={4}
        border="1px solid #cdcdcd"
        headerProps={{ bg: 'white', borderRadius: 4 }}
        header={() => (
          <Text fontWeight={600} py={1} fontSize="lg" px={2} color="gray.600">
            Insurance Plans
          </Text>
        )}
      >
        <AdminInsurancePlansView />
      </Expandable>
      <Expandable
        mt={3}
        width={`${width - 40}px`}
        borderRadius={4}
        bg="gray.50"
        headerProps={{ bg: 'white', borderRadius: 4 }}
        border="1px solid #cdcdcd"
        header={() => (
          <Text fontWeight={600} py={1} fontSize="lg" px={2} color="gray.600">
            Log
          </Text>
        )}
      >
        <Box overflowX="hidden" w="100%" height="600px">
          <AssessmentLog maxHeight={600} width={width - 40} />
        </Box>
      </Expandable>
    </Box>
  )
}

const useAutomaticTemplate = (assessment: PopulatedAssessment) => {
  const { mergedData, insuranceProvider } = assessment || {}

  return React.useMemo(() => {
    const { plan } = mergedData?.['insurance-info']?.primaryCoverage || {}
    if (plan && plan.templateId) return plan.templateId
    if (insuranceProvider && insuranceProvider.defaultTemplateId) {
      return insuranceProvider.defaultTemplateId
    }
    return undefined
  }, [mergedData, insuranceProvider])
}

// fix for buggy v1 stuff
const normalizeV1Text = (text: Descendant[]): Descendant[] => text.map((node) => (SlateText.isText(node) ? { type: 'span', children: [node] } : node))
const getAssessmentSubmitText = (
  sentOn?: number,
): TemplateViewProps['submitText'] => (sentOn
  ? { submit: 'Resend assessment', confirm: 'Confirm resend' }
  : { submit: 'Send Assessment', confirm: 'Confirm send' })

const awaitingText: Descendant[] = [
  {
    type: 'span',
    children: [
      { text: "Awaiting assessment results, we'll get back to you soon!" },
    ],
  },
]

const AssessmentHistory = ({
  assessment,
  updateText,
}: {
  assessment: PopulatedAssessment
  updateText: (version: EditorVersion, text: Descendant[]) => void
}) => {
  const {
    results,
    sentOn,
    draft,
    draftSavedOn,
    draftEditorVersion,
    editorVersion: resultsVersion,
    draftSavedBy,
    sentBy,
    previousResults,
  } = assessment || {}
  const noHistory = useMemo(() => {
    if (!draft && !sentOn && !previousResults?.length) return true
    return false
  }, [draft, sentOn, previousResults])
  if (noHistory) {
    return (
      <Flex align='center' px={2} py={1} borderBottom='1px solid #cdcdcd' gap={2}>
        <TimeIcon w={4} color="gray.500" />
        <Text fontStyle="italic" color="gray.500" fontSize="sm">
          No history
        </Text>
      </Flex>
    )
  }
  return (
    <Expandable
      noAnimate
      borderBottom="1px solid #cdcdcd"
      headerProps={{
        bg: 'white',
        _hover: {
          bg: 'blackAlpha.50',
        },
      }}
      header={() => (
        <Flex gap={1.5} p={1} py={2} align="center" w="100%">
          <TimeIcon w={4} color="gray.500" />
          <HStack gap={1} divider={<StackDivider />}>
            {sentOn ? (
              <Text
                lineHeight={1}
                fontWeight={600}
                color="gray.500"
                fontSize="sm"
              >
                Sent on {new Date(sentOn).toLocaleDateString()}
              </Text>
            ) : null}
            {previousResults?.length ? (
              <Text
                lineHeight={1}
                fontWeight={600}
                color="gray.500"
                fontSize="sm"
              >
                {previousResults.length} previous assessment
                {previousResults.length !== 1 ? 's' : ''}
              </Text>
            ) : null}
            {draftSavedOn ? (
              <Text
                lineHeight={1}
                fontWeight={600}
                color="gray.500"
                fontSize="sm"
              >
                Draft saved on {new Date(draftSavedOn).toLocaleDateString()}
              </Text>
            ) : null}
          </HStack>
        </Flex>
      )}
    >
      {draftSavedOn || sentOn ? (
        <Flex
          w="100%"
          px={2}
          py={1}
          gap={1}
          align="flex-start"
          bg="gray.50"
          flexFlow="column"
          style={{
            borderTop: '1px solid #dedede',
            borderBottom: '1px solid #dedede',
          }}
        >
          {draft && draftSavedOn ? (
            <HStack flex={1} minW="0">
              <ActionButton
                fontSize="x-small"
                p={1}
                bg="white"
                minW="36px"
                size="xs"
                onClick={() => updateText(draftEditorVersion || 'v1', draft)}
              >
                VIEW
              </ActionButton>
              <ActionLog
                style={{ width: 'auto' }}
                action="Draft saved"
                on={draftSavedOn}
                by={draftSavedBy}
              />
            </HStack>
          ) : null}

          {previousResults?.length
            ? previousResults.map((res) => (
                <HStack key={res.sentOn} flex={1} minW="0">
                  <ActionButton
                    fontSize="x-small"
                    p={1}
                    minW="36px"
                    bg="white"
                    size="xs"
                    onClick={() => updateText(res.editorVersion || 'v1', res.results)
                    }
                  >
                    VIEW
                  </ActionButton>
                  <ActionLog
                    style={{ width: 'auto' }}
                    action="Assessment sent"
                    on={res.sentOn || undefined}
                    by={res.sentBy || undefined}
                  />
                </HStack>
            ))
            : null}
          {sentOn && results ? (
            <HStack justify="flex-end" flex={1} minW="0">
              <ActionButton
                fontSize="x-small"
                p={1}
                size="xs"
                minW="36px"
                bg="white"
                onClick={() => updateText(resultsVersion || 'v1', results)}
              >
                VIEW
              </ActionButton>
              <ActionLog
                style={{ width: 'auto' }}
                action={
                  previousResults?.length
                    ? 'Assessment re-sent'
                    : 'Assessment sent'
                }
                on={sentOn}
                by={sentBy}
              />
            </HStack>
          ) : null}
        </Flex>
      ) : null}
    </Expandable>
  )
}

const AssessmentEditor: React.FC<{
  admin?: boolean
  assessmentId?: string
  assessment: PopulatedAssessment
  width: number
  height: number
  status?: AssessmentStatus
}> = ({
  admin, assessment, width, height,
}) => {
  const {
    results,
    sentOn,
    draft,
    draftSavedOn,
    draftEditorVersion,
    editorVersion: resultsVersion,
  } = assessment || {}
  const templateId = useAutomaticTemplate(assessment)

  const { item: template } = useCollectionItem(assessmentTemplatesCollection, templateId) || {}

  const getInitialText = useCallback(() => {
    let version: EditorVersion = 'v1'

    let text = awaitingText
    const setToResults = () => {
      text = results || defaultInitialValue
      version = resultsVersion || 'v1'
    }
    const setToDraft = () => {
      text = draft || defaultInitialValue
      version = draftEditorVersion || 'v1'
    }
    if (admin) {
      if (sentOn && draftSavedOn && draft && results) {
        if (draftSavedOn > sentOn) setToDraft()
        else setToResults()
      } else if (results) setToResults()
      else if (draft) setToDraft()
      else if (template) {
        text = template.templateText as Descendant[]
      } else text = defaultInitialValue
    } else if (sentOn && results) setToResults()
    return { text: version === 'v1' ? normalizeV1Text(text) : text, version }
  }, [
    admin,
    sentOn,
    draft,
    draftSavedOn,
    results,
    template,
    draftEditorVersion,
    resultsVersion,
  ])
  const init = useRef(getInitialText())
  const [initialText, setInitialText] = useState<Descendant[]>(
    init.current.text,
  )
  const [editorVersion, setEditorVersion] = useState<EditorVersion>(
    init.current.version,
  )

  const updateInitialText = useCallback(
    (version: EditorVersion, text: Descendant[]) => {
      setEditorVersion(version)
      setInitialText(version === 'v1' ? normalizeV1Text(text) : [...text])
    },
    [],
  )
  const [headerHeight, setHeaderHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  useResizeObserver(headerRef, (entry) => {
    setHeaderHeight(entry.target.scrollHeight)
  })

  const editorSize = useEditorWidth(width - 16, height - headerHeight - 10)

  const submitText = useMemo(() => getAssessmentSubmitText(sentOn), [sentOn])
  return (
    <Box w="100%" h="100%" overflow="hidden">
      <Box w="100%" ref={headerRef}>
        <AssessmentHistory
          assessment={assessment}
          updateText={updateInitialText}
        />
      </Box>
      <Box flex={1} minH="0" w="100%">
        <AssessmentTemplateView
          submitText={submitText}
          templateId={templateId}
          version={editorVersion}
          initialText={initialText}
          size={editorSize}
        />
      </Box>
    </Box>
  )
}

const AssessmentModalContainer = ({
  children,
  flexProps,
  ...props
}: BoxProps & { flexProps?: FlexProps }) => (
  <Box p={2} bg="gray.100" position="relative" height="100%" {...props}>
    <Flex
      minH="100%"
      borderRadius={6}
      boxShadow="md"
      w="100%"
      bg="white"
      direction="column"
      alignItems="flex-start"
      h="100%"
      overflowY="auto"
      flexGrow={1}
      flex={1}
      {...flexProps}
    >
      {children}
    </Flex>
  </Box>
)

const AssessmentModalAssessmentView = ({
  admin,
  width,
  height,
}: {
  assessment?: Assessment | PopulatedAssessmentInterface
  admin: boolean
  width: number
  height: number
}) => {
  const { appName } = useApp()
  const { selectedAssessment, assessmentId } = useContext(UserContext)
  const { status } = selectedAssessment || {}

  let body: ReactElement | null = null

  const editorWidth = useMemo(
    () => Math.min(width - 24, A4_DIMS_PIXELS[0]),
    [width],
  )
  const [headerHeight, setHeaderHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  useResizeObserver(headerRef, (entry) => {
    setHeaderHeight(entry.contentRect.height)
  })

  const bodyHeight = useMemo(
    () => height - headerHeight - 20,
    [height, headerHeight],
  )
  const editorSize = useEditorWidth(editorWidth - 10, bodyHeight)

  const usedVersion = useMemo(() => {
    if (selectedAssessment?.results) {
      return selectedAssessment.editorVersion || 'v1'
    }
    if (selectedAssessment?.draft) {
      return selectedAssessment.draftEditorVersion || 'v1'
    }
    return selectedAssessment?.editorVersion || 'v2'
  }, [selectedAssessment])

  if (appName === 'providers-app') {
    if (!selectedAssessment?.sentOn) {
      body = (
        <Center w="100%">
          <Text
            p={2}
            fontStyle="italic"
            fontFamily="hero-new"
            fontSize="lg"
            color="gray.600"
          >
            {selectedAssessment?.submittedOn
              ? 'Assessment not yet sent'
              : 'Questionnaire not yet submitted'}
          </Text>
        </Center>
      )
    } else {
      body = (
        <Box w="100%">
          <ReadOnlyEditor
            {...editorSize}
            version={usedVersion}
            value={selectedAssessment?.results || []}
          />
        </Box>
      )
    }
  } else if (appName === 'app') {
    if (!selectedAssessment?.submittedOn) {
      body = (
        <Center w="100%">
          <Text
            p={2}
            fontStyle="italic"
            fontFamily="hero-new"
            fontSize="lg"
            color="gray.600"
          >
            Questionnaire not yet submitted
          </Text>
        </Center>
      )
    } else {
      body = (
        <AssessmentEditor
          status={status}
          assessmentId={assessmentId}
          admin={admin}
          width={editorWidth}
          height={bodyHeight}
          assessment={selectedAssessment!}
        />
      )
    }
  }

  return (
    <AssessmentModalContainer
      height={height}
      width={width}
      flexProps={{ bg: 'white' }}
      overflowY="hidden"
    >
      <HStack
        ref={headerRef}
        borderTopRadius={6}
        bg="white"
        w="100%"
        height="36px"
        px={3}
        py={1}
        borderBottom="1px solid #cdcdcd"
      >
        <Text
          fontWeight={600}
          fontSize="md"
          color="gray.600"
          fontFamily="Open Sans"
        >
          Assessment Results
        </Text>
      </HStack>
      {body}
    </AssessmentModalContainer>
  )
}

export const AssessmentModal: React.FC<{
  assessment?: Assessment | PopulatedAssessmentInterface
  id?: string
  admin?: boolean
  onClose: () => void
  isOpen: boolean
  user: User
}> = ({
  assessment, onClose, isOpen, id,
}) => {
  const me = useAuth((s) => s.user)
  const { showPayments } = me || {}
  const { selectedAssessment, assessmentId } = useContext(UserContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const onSaveAnswers = useCallback(
    async (data: FieldMapValue) => {
      if (assessmentId) {
        try {
          await updateAssessmentAnswers({
            data,
            id: assessmentId,
          })
          return processResponse({ success: 'Answers updated!' })
        } catch (err: any) {
          return processResponse({ error: err.message })
        }
      }
      return processResponse({ error: 'No assessment' })
    },
    [assessmentId, processResponse],
  )

  const assessmentContextData = useMemo(
    () => ({ assessment: selectedAssessment }),
    [selectedAssessment],
  )

  const screenDims = useContext(ScreenContext)
  const { isMobile, width: screenWidth } = screenDims
  const expandedPaymentsWidth = useMemo(
    () => (isMobile ? screenWidth - 60 : Math.min(screenWidth / 2.5, 650)),
    [screenWidth, isMobile],
  )

  const [overrideValue, setOverrideValue] = useState(showPayments)
  useEffect(() => {
    setOverrideValue(showPayments)
  }, [showPayments])

  const toggleShowPayments = useCallback(() => {
    if (!me) return { error: 'Not signed in' }
    setOverrideValue(!showPayments)
    return updateDoc(doc(USERS_REF, me.id), { showPayments: !showPayments })
  }, [showPayments, me])

  const mergedData = useMemo(
    () => mergeAssessmentData(assessment),
    [assessment],
  )
  const update = useUpdateDoc('questionnaire')
  const labelsContextData = useMemo(
    () => ({
      value: mergedData,
    }),
    [mergedData],
  )

  const { appName } = useApp()

  const { width, height } = useMemo(
    () => ({
      width: screenDims.width - (isMobile ? 16 : 60),
      height: screenDims.height - (isMobile ? 20 : 60),
    }),
    [screenDims, isMobile],
  )

  const editorWidth = useMemo(
    () => Math.min(
      (width - (showPayments ? expandedPaymentsWidth : 0)) / 2,
      A4_DIMS_PIXELS[0],
    ),
    [width, showPayments, expandedPaymentsWidth],
  )

  const infoWidth = useMemo(
    () => width - editorWidth - (showPayments ? expandedPaymentsWidth : 0),
    [width, editorWidth, showPayments, expandedPaymentsWidth],
  )

  return (
    <AssessmentContext.Provider value={assessmentContextData}>
      <DefaultModal
        contentProps={{
          background: 'transparent',
          maxW: 'unset',
          height: `${height}px`,
          width: `${width}px`,
          maxH: 'unset',
        }}
        closeOnOverlayClick={false}
        overlayHeader
        onClose={onClose}
        bodyProps={{
          display: 'flex',
          overflow: 'hidden',
          background: 'white',
          borderRadius: 8,
          width: '100%',
          height: '100%',
        }}
        isOpen={isOpen}
        render={() => (
          <Flex minH="100%" position="relative" w="100%">
            {appName === 'app' ? (
              <AssessmentModalAssessmentView
                width={editorWidth}
                height={height}
                assessment={assessment}
                admin
              />
            ) : null}
            {assessmentId && selectedAssessment ? (
              <AssessmentModalContainer width={`${infoWidth}px`}>
                <FormLabelsContext.Provider value={labelsContextData}>
                  <AssessmentAnswers
                    width={infoWidth}
                    onSave={onSaveAnswers}
                    onSubmit={async () => {
                      if (!me) return { error: 'Internal error' }
                      if (!id) return { error: 'no assessment id' }
                      return update(doc(ASSESSMENTS_REF, id), '', {
                        submittedOn: Date.now(),
                        submittedBy: me.id,
                        submittedByGroup:
                          appName === 'app' ? 'admin' : 'practice',
                      })
                    }}
                    assessmentId={assessmentId}
                    assessment={selectedAssessment}
                  />
                </FormLabelsContext.Provider>
              </AssessmentModalContainer>
            ) : null}
            {selectedAssessment ? (
              <AssessmentModalContainer
                zIndex={2}
                position={isMobile ? 'absolute' : 'relative'}
                width={overrideValue ? `${expandedPaymentsWidth}px` : '0px'}
                // transition='width 400ms'
                right={0}
              >
                <HStack
                  width="100%"
                  bg="white"
                  px={3}
                  py={1}
                  color="gray.600"
                  borderTopRadius={6}
                  borderBottom="1px solid #cdcdcd"
                >
                  <Text fontWeight={600} fontFamily="Open Sans">
                    Payments
                  </Text>
                </HStack>
                <Flex
                  width={`${expandedPaymentsWidth - 16}px`}
                  height="100%"
                  alignItems="flex-start"
                  py={2}
                  px={3}
                  position="relative"
                  bg="gray.50"
                  overflowY="auto"
                >
                  <AssessmentPayments small />
                </Flex>
                <Flex
                  align="flex-start"
                  position="absolute"
                  h="100%"
                  alignItems="center"
                  pointerEvents="none"
                  left={showPayments ? '-14px' : '-4px'}
                  w="24px"
                >
                  <Tooltip label="Payments" placement="left">
                    <Button
                      zIndex={2}
                      p={0}
                      pointerEvents="auto"
                      size="sm"
                      // top={isMobile ? 'unset' : 2}
                      color={colors.green.hex}
                      onClick={toggleShowPayments}
                      borderRightRadius={0}
                      borderColor={colors.green.hex}
                      borderWidth="1px"
                      borderRightWidth={showPayments ? '0px' : '1px'}
                      bg="white"
                      fontSize="lg"
                      fontWeight={700}
                      position="absolute"
                      w={isMobile ? '24px' : '20px'}
                      minW="0"
                      height="40px"
                    >
                      $
                    </Button>
                  </Tooltip>
                </Flex>
              </AssessmentModalContainer>
            ) : null}
          </Flex>
        )}
      />
    </AssessmentContext.Provider>
  )
}
