import { Assessment, PopulatedAssessment, WithId } from '@hb/shared/types'
import { objectToArray } from '@hb/shared/utils'
import React, {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react'
import { usePopulatedAssessment } from '../hooks/backend/user/usePopulatedAssessment'
import { useUserAssessments } from '../hooks/backend/user/useUserAssessments'
import { useAuth } from '../store/auth'

type UserAssessmentContextData = {
  assessments: Record<string, Assessment>
  assessmentsArr: WithId<Assessment>[]
  loading: boolean
  error: string | null
  mostRecent: PopulatedAssessment | undefined
}
export const UserAssessmentsContext = createContext<UserAssessmentContextData>({
  assessments: {},
  assessmentsArr: [],
  loading: false,
  error: null,
  mostRecent: undefined,
})

export const UserAssessmentsProvider = ({ children }: PropsWithChildren) => {
  const authUser = useAuth((s) => s.authUser)
  const { data: assessments, error, loading } = useUserAssessments(authUser?.uid)
  const mostRecentAssessment = useMemo(() => {
    const mostRecent = Object.entries(assessments || {}).sort(([, a], [, b]) => (a.createdOn < b.createdOn ? 1 : -1))[0]
    return mostRecent ? { id: mostRecent[0], ...mostRecent[1] } : undefined
  }, [assessments])
  const mostRecent = usePopulatedAssessment(mostRecentAssessment)
  const contextData = useMemo(() => ({
    assessments: assessments || {},
    assessmentsArr: objectToArray(assessments || {}),
    loading,
    error,
    mostRecent,
  }), [assessments, loading, error, mostRecent])
  return (
    <UserAssessmentsContext.Provider value={contextData}>
      {children}
    </UserAssessmentsContext.Provider>
  )
}

export const useMyAssessments = () => useContext(UserAssessmentsContext)
