import { FieldTypes } from '@hb/shared/constants'
import { DropdownField, FieldMap } from '@hb/shared/types'
import { serviceTypeField, serviceTypeOtherField } from './Ability/fields'
import { toClaimName } from './utils'

export const status: DropdownField = {
  placeholder: 'Status',
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'processing', text: 'Processing' },
    { id: 're-processing', text: 'Re-processing' },
    { id: 'processed-in', text: 'Processed In-Net' },
    { id: 'processed-oon', text: 'Processed OON' },
    { id: 'paid', text: 'Paid' },
  ],
}

export const claimField: FieldMap = {
  name: 'Claim',
  toName: toClaimName,
  children: {
    serviceType: serviceTypeField,
    serviceTypeOther: serviceTypeOtherField,
    status,
    submissionDate: {
      type: FieldTypes.DATE,
      placeholder: 'Submission date',
    },
    serviceDates: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'Date(s) of service...',
    },
    cptCodes: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'CPT Codes/Billed Charges',
    },
    // billedCharges: {
    //   optional: true,
    //   type: FieldTypes.TEXTAREA,
    //   placeholder: 'Billed charges',
    // },
    sessionNumber: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'Session #',
    },
    claimNumber: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'Claim #',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
}
