import {
  AuthData,
  GoogleUserProps,
  User,
  UserCallback,
  UserGroup,
  UserRole,
  USERS,
} from '@hb/shared'
import {
  createUserWithEmailAndPassword,
  getMultiFactorResolver,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  multiFactor,
  RecaptchaVerifier,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  User as FirebaseUser,
} from 'firebase/auth'
import { doc } from 'firebase/firestore'
import { create } from 'zustand'
import { auth } from '../backend/auth'
import { db } from '../backend/db'
import {
  dbGetOrCreateUser,
  populateUserData,
} from '../hooks/backend/user/userUtils'
import { showError, showSuccess, standaloneToast } from '../toast'

const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
  if (match) return match[2]
  return null
}

const removeCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const useAuth = create<AuthData>(() => ({
  user: null,
  authUser: null,
  role: null,
  ref: null,
  admin: false,
  unsubscribe: null,
  captcha: null,
  accessLevel: null,
  claims: null,
  loading: true,
  enrollingUser: null,
  // signInStage: 'first-factor',
  // @ts-ignore
  recaptchaVerifier: new RecaptchaVerifier(auth, '2fa-recaptcha-container', {
    size: 'invisible',
    callback: () => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      // useAuth.setState({ captcha: response })
    },
  }),
  enrolledFactors: null,
  multiFactorResolver: null,
  selectedEnrollmentFactor: null,
}))

// const [cookies, , removeCookie] = useCookies()
// const { inviteId } = cookies as { inviteId?: string }
const getUserCallback = (c: UserCallback) => {
  const inviteId = getCookie('inviteId')
  const { success, error } = c
  if (inviteId) removeCookie('inviteId')
  if (success) {
    showSuccess(success)
  }
  if (error) {
    auth.signOut()
    showError(error)
  }
}

const onSnapshot = (authU: GoogleUserProps, dbUser: Omit<User, 'id'>) => {
  if (dbUser) {
    useAuth.setState({ user: populateUserData(authU, dbUser), loading: false })
  } else {
    getUserCallback({ error: 'Error getting user' })
  }
}

export const signInCallback = (newUser: FirebaseUser | null) => {
  const u = newUser
  if (u) {
    const curr = useAuth.getState()
    if (curr.unsubscribe) curr.unsubscribe()
    useAuth.setState({ loading: true })

    // get multi factor enrollments
    const { enrolledFactors } = multiFactor(u)

    if (enrolledFactors.length === 0) {
      useAuth.setState({
        enrolledFactors,
        loading: false,
        enrollingUser: u,
      })
      return
    }

    u.getIdTokenResult(true).then((r) => {
      let role: UserRole = 'user'
      if (r.claims?.superAdmin) role = 'super-admin'
      else if (r.claims?.admin) role = 'admin'
      const isPracticeMember = Object.keys(r.claims.practiceAccess || {}).length > 0
      let accessLevel: UserGroup = 'patient'
      if (r.claims?.admin) accessLevel = 'admin'
      else if (isPracticeMember) accessLevel = 'practice'
      useAuth.setState({
        claims: r.claims,
        role,
        ref: doc(db, USERS, u.uid),
        enrollingUser: null,
        selectedEnrollmentFactor: null,
        enrolledFactors,
        authUser: u,
        admin: r.claims?.admin === true || r.claims?.superAdmin === true,
        accessLevel,
      })
      if (u.emailVerified) {
        dbGetOrCreateUser(u, getUserCallback, onSnapshot)
      } else {
        useAuth.setState({ loading: false })
      }
    })
  } else {
    if (useAuth.getState().user) standaloneToast({ description: 'Signed out', colorScheme: 'gray', status: 'info' })
    useAuth.setState({
      loading: false,
      user: null,
      accessLevel: null,
      claims: null,
      role: null,
      admin: false,
      ref: null,
      authUser: null,
      enrollingUser: null,
      captcha: null,
      enrolledFactors: null,
      multiFactorResolver: null,
      selectedEnrollmentFactor: null,
      unsubscribe: null,
    })
  }
}
// getRedirectResult(auth)
//   .then((res) => signInCallback(res?.user || null))
//   .catch((err) => {
//     console.error(err)
//   })
auth.onAuthStateChanged(signInCallback)

export const signOut = () => {
  useAuth.setState({ loading: true })
  const unsubFromUser = useAuth.getState().unsubscribe
  if (unsubFromUser) unsubFromUser()
  auth.signOut()
}

const handleAuthError = (err: any) => {
  if (err.code === 'auth/multi-factor-auth-required') {
    const resolver = getMultiFactorResolver(auth, err)
    useAuth.setState({ multiFactorResolver: resolver, enrolledFactors: resolver.hints, enrollingUser: auth.currentUser })
  } else {
    console.error(err)
    showError(err.message)
    throw new Error(err.message)
  }
}

export const signInWithGoogle = async () => {
  await setPersistence(auth, indexedDBLocalPersistence)
  const provider = new GoogleAuthProvider()
  try {
    const user = await signInWithPopup(auth, provider)
    signInCallback(user.user)
  } catch (err: any) {
    handleAuthError(err)
  }
}
export const signInWithEmailPassword = async ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  try {
    await setPersistence(auth, indexedDBLocalPersistence)
    const user = await signInWithEmailAndPassword(auth, email, password)
    signInCallback(user.user)
    showSuccess('Signed in!')
  } catch (err: any) {
    handleAuthError(err)
  }
}

export const registerWithEmailPassword = async ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  await setPersistence(auth, indexedDBLocalPersistence)
  return createUserWithEmailAndPassword(auth, email, password)
}
