import { CollectionItem, LocalCollectionState } from '@hb/shared/collections'
import { colors } from '@hb/shared/constants'
import { PracticeUserAccess, UserRoleItem } from '@hb/shared/types'
import { getFullName } from '@hb/shared/utils'
import { useMemo } from 'react'
import { useAuth } from '../../../store/auth'

export const getAdminsColorText = (
  admins?: Array<
    CollectionItem<UserRoleItem> | CollectionItem<PracticeUserAccess>
  >,
) => admins?.map((a) => getFullName(a)).join(', ') || 'No admins assigned'
export const useAdminsWithColor = (
  admins: LocalCollectionState<UserRoleItem>,
  enabled?: boolean,
  color?: string | null,
) => {
  const admin = useAuth((s) => s.admin)
  const adminsWithColor = useMemo(
    () => (admin && color ? admins?.items.filter((a) => a.adminColor === color) : []),
    [admins, color, admin],
  )

  const mapped = useMemo(
    () => Object.values(colors).reduce(
      (acc, value) => {
        acc[value.hex] = admins?.items.filter((a) => a.adminColor === value.hex) || []
        return acc
      },
        {} as Record<string, any>,
    ),
    [admins],
  )

  return {
    admins: adminsWithColor,
    text: enabled ? getAdminsColorText(adminsWithColor) : '',
    mapped,
  }
}
