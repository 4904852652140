import { ChatIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  Claim,
  CLAIMS, colors,
  DataColumn,
  DataColumns,
  formatPhoneNumber, getInovalonClaimStatusColor,
  getOptionFromId,
  getReverseName,
} from '@hb/shared'

import React from 'react'
import { Link } from 'react-router-dom'
import { useLogInfo } from '../../../hooks'
import { DisplayButton, DisplayButtonBase } from '../../Buttons/DisplayButton'
import { EllipsisText } from '../../EllipsisText'
import { serviceTypeField } from '../../Users/Profile/Claims/Ability/fields'
import { getIsUrgent } from '../../Users/Profile/UrgentPopover'
import { SortColorCircle } from '../../Users/UrgentBadge'
import { SortButton } from '../SortButton'
import { ListUrgentHeader } from './users/ListUrgentPopover'
import { LogColumnPopover } from './users/LogColumnPopover'
import {
  getDeliveredOnColumn, getOverduePaymentColumn, getRenderCollectionNextAction, getUnreadMessagesColumn, insurerColumn,
} from './users/shared'

type ClaimColumn = DataColumn<any, Claim>

const nameColumn: ClaimColumn = {
  title: 'Patient Name',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="lname">PATIENT NAME</SortButton>
    </Flex>
  ),
  width: 215,
  sortKey: 'lname',
  Render: ({ data: claim }) => {
    const { id, abilitySnippet } = claim
    const { TransStatus: status } = abilitySnippet || {}
    const statusColor = getInovalonClaimStatusColor(status)
    return (
      <Flex mb={1}>
        <Link style={{ opacity: 1 }} to={`/admin/claims/${id}/claims`}>
          <Tooltip
            placement="top-start"
            bg={statusColor}
            color="white"
            label={status}
          >
            <Button
              fontWeight={500}
              justifyContent="flex-start"
              fontSize="md"
              variant="link"
            >
              <Text
                textAlign="left"
                w="200px"
                color={statusColor}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {getReverseName(claim)}
              </Text>
            </Button>
          </Tooltip>
        </Link>
      </Flex>
    )
  },
}

const statusColumn: ClaimColumn = {
  title: 'Status',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="status">STATUS</SortButton>
    </Flex>
  ),
  width: 120,
  sortKey: 'status',
  Render: ({ data: claim }) => {
    const { abilitySnippet } = claim
    const { TransStatus: status } = abilitySnippet || {}
    const statusColor = getInovalonClaimStatusColor(status)
    return (
      <Flex mb={1}>
        <Text
          textAlign="left"
          w="200px"
          color={statusColor}
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {status || 'N/A'}
        </Text>
      </Flex>
    )
  },
}

type UrgentColumnItem = {
  urgentColor?: string
  urgentSort?: string
}
export const getUrgentColumn = <T extends UrgentColumnItem>(): DataColumn<UrgentColumnItem, T> => ({
  title: 'Urgent',
  Header: () => (
    <Box p={1}>
      <ListUrgentHeader />
    </Box>
  ),
  sortKey: 'urgentSort',
  flexProps: { justify: 'center' },
  width: 40,
  Render: ({ data: user, cell, preview: { openPreview } }) => {
    const { id, urgentColor } = user || {}
    const urgent = getIsUrgent(user)
    return (
      <SortColorCircle
        onClick={(e) => openPreview({
          id,
          item: 'urgent',
          position: { x: e.clientX, y: e.clientY },
          cell,
        })
        }
        withAdmins
        color={urgentColor}
        urgent={urgent}
      />
    )
  },
})

export const claimLogColumn: ClaimColumn = {
  title: 'Log',
  Header: () => <LogColumnPopover />,
  width: 160,
  isLazy: true,
  Render: ({
    data: claim, cell, isScrolling, preview: { openPreview },
  }) => {
    const {
      log, phone, id, email,
    } = claim || {}
    const { lastUpdated } = useLogInfo(log)
    const formatted = formatPhoneNumber(phone)
    if (isScrolling) {
      return (
        <Center w="100%">
          <Spinner />
        </Center>
      )
    }

    return (
      <Stack flex={1} direction="row">
        <DisplayButtonBase
          onClick={(e) => openPreview({
            id,
            item: 'log',
            position: { x: e.clientX, y: e.clientY },
            cell,
          })
          }
          aria-label="log"
          icon={<ChatIcon color={lastUpdated ? 'blue.400' : 'gray'} />}
        />
        <DisplayButton
          aria-label="email"
          icon={<EmailIcon />}
          text={email || 'None'}
        />
        <DisplayButton
          aria-label="phone"
          icon={<PhoneIcon />}
          text={formatted}
        />
      </Stack>
    )
  },
}
export const claimLinkedColumn: ClaimColumn = {
  title: 'System',
  Header: () => <Text>SYSTEM</Text>,
  // sortKey: 'abilityId',
  Render: ({ data }) => {
    const { abilityId } = data
    return (
      <Badge color={abilityId ? colors.green.hex : colors.orange.hex}>
        {abilityId ? 'ABILITY' : 'LEGACY'}
      </Badge>
    )
  },
}

export const claimServiceTypeColumn: ClaimColumn = {
  title: 'Service Type',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="serviceType">SERVICE TYPE</SortButton>
    </Flex>
  ),
  sortKey: 'serviceType',
  Render: ({ data }) => {
    const { serviceType, serviceTypeOther } = data || {}
    let text = ''
    if (serviceType === 'other') {
      text = `Other (${serviceTypeOther || 'Not specified'})`
    } else {
      text = serviceType
        ? getOptionFromId(serviceTypeField.options, serviceType)?.text
          || serviceType
          || 'Not specified'
        : 'Not specified'
    }
    return <Text>{text}</Text>
  },
}

const providerColumn: ClaimColumn = {
  title: 'Provider',
  Header: () => <SortButton sortKey="midwife">PROVIDER</SortButton>,
  sortKey: 'midwife',
  Render: ({ data }) => <EllipsisText text={data?.midwife || 'None'} />,
}

export const claimNextActionColumn: ClaimColumn = {
  title: 'Next Action Date',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="nextActionDate">NEXT ACTION</SortButton>
    </Flex>
  ),
  width: 300,
  isLazy: true,
  sortKey: 'nextActionDate',
  Render: getRenderCollectionNextAction<Claim>(CLAIMS),
}

export const claimsColumns: DataColumns<Claim> = {
  urgent: getUrgentColumn(),
  // unreadMessages: unreadMessagesColumn as any,
  unreadThreadMessages: getUnreadMessagesColumn(),
  deliveredOn: getDeliveredOnColumn(),
  name: nameColumn,
  status: statusColumn,
  // linked: claimLinkedColumn,
  serviceType: claimServiceTypeColumn,
  insurer: insurerColumn as any,
  provider: providerColumn,
  nextAction: claimNextActionColumn,
  log: claimLogColumn,
  overduePayments: getOverduePaymentColumn(),
}
