import {
  Box, Collapse, Divider, Text, VStack,
} from '@chakra-ui/react'
import {
  DropdownField,
  formatField,
  getDefaultPatientRelateCode,
  InsuranceCoverage,
  InsuranceProvider,
  policyOwnerRelationshipField,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { Condition, FormElement } from '../../../../../forms'
import {
  customPayerIdPrimary,
  customRelateCodePrimary,
  shouldUseCustomPayerId,
  shouldUseCustomRelateCodeField,
} from '../fields'

export const SubmitClaimMainCoverageDetails = ({
  insuranceProvider,
  authorizationsField,
  coverage,
}: {
  coverage: InsuranceCoverage | null | undefined
  insuranceProvider: InsuranceProvider | undefined | null
  authorizationsField: DropdownField
}) => {
  const policyOwner = useMemo(
    () => formatField(
      policyOwnerRelationshipField,
      coverage?.policyOwnerRelationship,
    ),
    [coverage],
  )
  const defaultRelateCode = useMemo(
    () => getDefaultPatientRelateCode(policyOwner),
    [policyOwner],
  )
  return (
    <Collapse unmountOnExit style={{ width: '100%' }} in={!!coverage}>
      <VStack
        border="1px solid #cdcdcd"
        borderRadius={4}
        spacing={0}
        my={2}
        bg="white"
        boxShadow="md"
        align="flex-start"
        w="100%"
        px={3}
        py={2}
      >
        <Text color="gray.600" fontSize="md">
          {insuranceProvider?.name}
        </Text>
        <Divider my={2} />
        <FormElement
          field={authorizationsField}
          name="primaryCoverageAuthRef"
        />
        <Divider my={2} />
        <FormElement
          name="shouldUseCustomPayerIdPrimary"
          field={shouldUseCustomPayerId}
        />
        <Condition
          basePath=""
          condition={(v) => !v?.shouldUseCustomPayerIdPrimary}
        >
          {!insuranceProvider?.abilityId ? (
            <Text color="red.600" fontSize="md">
              Insurance provider is missing Payer ID (Insurers Page)
            </Text>
          ) : (
            <Text>Payer ID: {insuranceProvider?.abilityId || 'None'}</Text>
          )}
        </Condition>
        <FormElement name="customPayerIdPrimary" field={customPayerIdPrimary} />
        <Divider my={2} />
        <FormElement
          name="shouldUseCustomRelateCodePrimary"
          field={shouldUseCustomRelateCodeField}
        />
        <Condition
          condition={(v) => !v?.shouldUseCustomRelateCodePrimary}
          basePath=""
        >
          <Box px={2}>
            <Text>Policy owner relationship: {policyOwner}</Text>
            <Text>Default relate code: {defaultRelateCode || 'None'}</Text>
          </Box>
        </Condition>
        <FormElement
          name="customRelateCodePrimary"
          field={customRelateCodePrimary}
        />
      </VStack>
    </Collapse>
  )
}
