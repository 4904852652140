import { providersCollection } from '@hb/shared/collections/collections/insurer'
import {
  InsuranceCoverage, InsuranceCoverageId, InsurancePlans, isInsuranceCoverageRequest,
} from '@hb/shared/types'
import { getCurrentlyOnMedicaidPlan, getInsurancePlan, getInsurancePlans } from '@hb/shared/utils/assessments'
import { arrayToObject } from '@hb/shared/utils/data'
import React, {
  createContext, PropsWithChildren, useContext, useMemo, useState,
} from 'react'
import { useCollection } from '../../../collections/hooks/useCollection'
import { UserContext } from '../../../contexts/UserContext'

type TemplateInsurancePlansContextData = {
  insurancePlans: InsurancePlans
  selectPlan: (planId: InsuranceCoverageId) => void
  selectedPlan: InsuranceCoverage | null
  selectedPlanId: InsuranceCoverageId
}

export const InsurancePlansTemplateContext = createContext<TemplateInsurancePlansContextData>({
  insurancePlans: {
    primaryCoverage: null,
    mainCoverage: null,
    medicaidCoverage: null,
    secondaryCoverage: null,
  },
  selectedPlan: null,
  selectPlan: () => {},
  selectedPlanId: 'primaryCoverage',
})

const useTemplateInsurancePlansData = (): TemplateInsurancePlansContextData => {
  const { selectedAssessment: assessment } = useContext(UserContext)
  const { items: insurers } = useCollection(providersCollection)
  const insurancePlans = useMemo(
    () => getInsurancePlans(assessment, arrayToObject(insurers) || {}),
    [assessment, insurers],
  )

  const [selectedPlanId, setPlanId] = useState<InsuranceCoverageId>(
    getCurrentlyOnMedicaidPlan(assessment)
      ? 'medicaidCoverage'
      : 'primaryCoverage',
  )
  const selectedPlan = useMemo(() => {
    const plan = getInsurancePlan(insurancePlans, selectedPlanId)
    if (!plan || isInsuranceCoverageRequest(plan)) return null
    return plan
  }, [insurancePlans, selectedPlanId])

  return useMemo<TemplateInsurancePlansContextData>(
    () => ({
      insurancePlans,
      selectPlan: (id: InsuranceCoverageId) => setPlanId(id),
      selectedPlanId,
      selectedPlan,
    }),
    [insurancePlans, selectedPlanId, selectedPlan],
  )
}

export const TemplateInsurancePlansProvider = ({ children }: PropsWithChildren) => {
  const contextData = useTemplateInsurancePlansData()
  return (
    <InsurancePlansTemplateContext.Provider value={contextData}>
      {children}
    </InsurancePlansTemplateContext.Provider>
  )
}

export const useTemplateInsurancePlans = () => useContext(InsurancePlansTemplateContext)
