import { TemplateKey } from '@hb/shared'
import React, { FC } from 'react'
import { DefaultModal, DefaultModalProps } from '../Modals/DefaultModal'
import { useEditorWidth } from '../RichText/hooks'
import {
  ConsentFormTemplateProvider,
  DocumentTemplateProvider,
} from './contexts'
import { TemplateEditor } from './TemplateEditor'

export type TemplateModalProps = BaseModalProps & {
  templateId: string
}

type BaseModalProps = Pick<DefaultModalProps, 'isOpen' | 'onClose'>

export const BaseTemplateModal: FC<BaseModalProps> = ({ isOpen, onClose }) => {
  // const { height: screenHeight } = useContext(ScreenContext)
  // const height = useMemo(() => `${screenHeight - 160}px`, [screenHeight])
  const sizeProps = useEditorWidth()
  return (
    <DefaultModal
      isOpen={isOpen}
      isCentered={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      overlayHeader
      contentProps={{ style: { padding: 0 }, width: 'auto' }}
      bodyProps={{ style: { padding: 0 }, width: 'auto' }}
      size="4xl"
      onClose={onClose}
      render={() => <TemplateEditor {...sizeProps} />}
    />
  )
}

export const TemplateEditorModal: FC<
  TemplateModalProps & {
    templateType: TemplateKey
  }
> = ({ templateId, templateType, ...props }) => (
  <DocumentTemplateProvider templateType={templateType} templateId={templateId}>
    <BaseTemplateModal {...props} />
  </DocumentTemplateProvider>
)

export const ConsentFormTemplateModal: FC<
  TemplateModalProps & {
    templateId: string
  }
> = ({ templateId, ...props }) => (
  <ConsentFormTemplateProvider templateId={templateId}>
    <BaseTemplateModal {...props} />
  </ConsentFormTemplateProvider>
)
