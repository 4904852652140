import {
  Box, Button, Collapse, HStack, Text, VStack,
} from '@chakra-ui/react'
import { FieldTypes } from '@hb/shared/constants'
import {
  FileDBValue, FileField, ThreadType, UserGroup,
} from '@hb/shared/types'
import React, { useCallback, useContext, useState } from 'react'
import { copyChatFileToAssessment } from '../../backend'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { UserContext } from '../../contexts/UserContext'
import { useAccessLevelField } from '../../hooks/backend/user/useAccessLevelField'
import { CollapseError } from '../CollapseError'
import { FileView, StandaloneInput } from '../forms/Input'
import { DefaultModal } from '../Modals/DefaultModal'
import { SearchAssessments } from './SearchAssessments'

const copiedFileField: FileField = {
  type: FieldTypes.FILE,
  name: '',
  placeholder: 'Copied File',
}

export const CopyChatFileToAssessmentModal = ({
  onClose,
  file,
  threadId,
  threadType,
}: {
  onClose: () => void
  file: FileDBValue
  threadId: string
  threadType: ThreadType
}) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const [submitting, setSubmitting] = useState(false)

  const { assessmentId: contextAssessmentId, patientRef } = useContext(UserContext)

  const { appName } = useApp()
  const [assessmentId, setAssessmentId] = useState<string | null>(contextAssessmentId || null)
  const [error, setError] = useState<string | null>(null)

  const [accessLevel, setAccessLevel] = useState<UserGroup | null>(
    appName === 'providers-app' ? 'practice' : null,
  )

  const accessLevelField = useAccessLevelField()
  const onSubmit = useCallback(async () => {
    if (!assessmentId) return processResponse({ error: 'Please select an assessment' })
    if (!accessLevel) return processResponse({ error: 'Please select an access level' })
    setSubmitting(true)
    setError(null)
    try {
      await copyChatFileToAssessment({
        accessLevel,
        assessmentId,
        threadType,
        file,
        threadId,
      })
    } catch (err: any) {
      setSubmitting(false)
      setError(
        err?.message || 'Error occurred while copying file to assessment',
      )
      return processResponse({
        error:
          err?.message || 'Error occurred while copying file to assessment',
      })
    }
    setSubmitting(false)
    onClose()
    return processResponse({ success: 'File copied to assessment' })
  }, [accessLevel, assessmentId, processResponse, threadId, threadType, file, onClose])
  return (
    <DefaultModal
      isOpen={true}
      overlayHeader
      onClose={onClose}
      headerProps={{
        onClick: (e) => {
          e.stopPropagation()
        },
      }}
      size="xl"
      scrollBehavior="inside"
      render={() => (
        <VStack align="flex-start" w="100%" bg="gray.50" p={3}>
          <Text fontFamily="Open Sans" px={2}>
            Copy File to Assessment
          </Text>
          <Box
            w="100%"
            border="1px solid #cdcdcd"
            overflow="hidden"
            borderRadius={6}
          >
            <FileView noFullView field={copiedFileField} data={file} />
          </Box>
          {accessLevelField ? (
            <StandaloneInput
              theme="detailed"
              field={accessLevelField}
              value={accessLevel}
              onChange={(value) => setAccessLevel(value)}
            />
          ) : null}
          <Collapse style={{ width: '100%' }} in={!!accessLevel}>
            <SearchAssessments
              onBack={() => setAssessmentId(null)}
              assessmentId={assessmentId}
              patientId={patientRef?.id}
              onSubmit={async (id) => {
                setAssessmentId(id)
              }}
            />
          </Collapse>
          <CollapseError error={error} />
          <HStack justify="flex-end" w="100%">
            <Button
              onClick={onClose}
              isDisabled={submitting}
              size="sm"
              bg="white"
              border="1px solid #cdcdcd"
              fontWeight={500}
              fontFamily="Open Sans"
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              isLoading={submitting}
              size="sm"
              _hover={{ bg: 'green.300' }}
              fontWeight={500}
              fontFamily="Open Sans"
              colorScheme="green"
            >
              Copy File
            </Button>
          </HStack>
        </VStack>
      )}
    />
  )
}
