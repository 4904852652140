import { LocalCollectionState } from '@hb/shared/collections'
import { CollectionFilter, DataListTab, UserRoleItem } from '@hb/shared/types'
import { FieldPath } from 'firebase/firestore'
import { createContext } from 'react'

export const DataListContext = createContext<{
  sortKey: string | FieldPath
  sortBy:(k: string | FieldPath, direction?: 'asc' | 'desc') => void
  toggleSortDirection: () => void
  filterBy: (filter: CollectionFilter) => void
  removeFilter: (filterKey: string | FieldPath) => void
  gridView: boolean
  filters: Array<CollectionFilter>
  userFilters: Array<CollectionFilter>
  admins: LocalCollectionState<UserRoleItem>
  tab: DataListTab<any> | null
  sortDesc: boolean
    }>({
      sortKey: 'urgentSort',
      filterBy: () => {},
      sortBy: () => {},
      removeFilter: () => {},
      toggleSortDirection: () => {},
      admins: {
        error: null,
        items: [],
        loading: false,
      },
      gridView: false,
      filters: [],
      userFilters: [],
      tab: null,
      sortDesc: true,
    })
