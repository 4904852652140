import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  Alert,
  CheckboxField,
  DropdownField,
  FieldMap,
  FieldMapValue,
  FormElement,
  TextField,
  UpdateCallback,
  UserGroup,
  WithId,
} from '@hb/shared'
import { FieldTypes, reactionIcons } from '@hb/shared/constants'
import { getDateString } from '@hb/shared/utils'
import { deleteField } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { updateAssessmentMiscarriedStatus } from '../../../backend'
import {
  PopUpMessageContext,
  ScreenContext,
  useApp,
  UserContext,
} from '../../../contexts'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { addMetadata } from '../../../utils'
import { ActionLog } from '../../ActionLog'
import { DeleteButton } from '../../Buttons'
import { SimpleForm } from '../../forms'
import { Editable } from '../../forms/Input'
import { DefaultModal } from '../../Modals/DefaultModal'
import { UserBadge } from '../UserBadge'

const alertTextField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Alert',
  // optional: true,
}

const alertUrgentField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'URGENT',
}

const alertTypeField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Type',
  options: [
    { id: 'assessment', text: 'Pregnancy' },
    { id: 'patient', text: 'Patient' },
  ],
}

const alertIconOptions = { ...reactionIcons }

const defaultIcon = <WarningTwoIcon color="red.600" w={4} h={4} />

const alertAccessLevelField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Access Level',
  options: [
    { id: 'admin', text: 'Admin' },
    { id: 'practice', text: 'Practice' },
  ],
}
const customIconField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Custom Icon',
  options: Object.keys(alertIconOptions).map((id) => ({
    text: id,
    id,
  })),
  renderOption: (option) => (
    <Flex align="center">
      <Box w="20px" fontSize="lg" mr={2}>
        {reactionIcons[option.id as keyof typeof reactionIcons]}
      </Box>
    </Flex>
  ),
  optional: true,
}

const AlertIconPopover = ({
  icon,
  onChange,
}: {
  icon?: string | null
  onChange: (updated: string | null) => Promise<UpdateCallback>
}) => {
  const [changingTo, setChangingTo] = useState<string | null | undefined>()
  const { processResponse } = useContext(PopUpMessageContext)
  const handleChangeTo = useCallback(
    async (updated: string | null) => {
      setChangingTo(updated)
      try {
        await onChange(updated)
      } catch (err: any) {
        processResponse({ error: err.message || 'Error updating alert icon' })
      }
      setChangingTo(undefined)
    },
    [onChange, processResponse],
  )
  return (
    <Popover trigger="hover" strategy="fixed">
      <PopoverTrigger>
        <IconButton
          aria-label="Custom Icon"
          variant="unstyled"
          overflow="hidden"
          _hover={{
            bg: 'blackAlpha.200',
          }}
          borderRadius="full"
          size="xs"
          icon={
            <Center>
              {icon ? (
                <Box fontSize="md">
                  {reactionIcons[icon as keyof typeof reactionIcons]}
                </Box>
              ) : (
                defaultIcon
              )}
            </Center>
          }
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="auto">
          <PopoverArrow />
          <PopoverBody borderRadius={6} overflow="hidden" p={0}>
            <Flex
              flexFlow="row wrap"
              justify="center"
              bg="gray.50"
              maxW="180px"
            >
              <IconButton
                aria-label="Clear Icon"
                borderRadius="full"
                variant="unstyled"
                isLoading={changingTo === null}
                size="sm"
                icon={defaultIcon}
                onClick={async () => handleChangeTo(null)}
              />
              {Object.keys(alertIconOptions).map((id) => (
                <IconButton
                  key={id}
                  aria-label={id}
                  borderRadius="full"
                  isLoading={changingTo === id}
                  variant="unstyled"
                  size="sm"
                  icon={
                    <Box>
                      {alertIconOptions[id as keyof typeof alertIconOptions]}
                    </Box>
                  }
                  onClick={async () => handleChangeTo(id)}
                />
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const AlertIcon = ({ icon }: { icon?: string | null }) => (
  <Center>
    {icon ? (
      <Box fontSize="md">
        {reactionIcons[icon as keyof typeof reactionIcons]}
      </Box>
    ) : (
      defaultIcon
    )}
  </Center>
)

const AlertEdit = ({
  id,
  alert,
  onDelete,
  onSubmit,
}: {
  id: string
  alert: string | Alert
  onDelete: () => Promise<UpdateCallback>
  onSubmit: (value: FieldMapValue) => Promise<UpdateCallback>
}) => {
  const text = typeof alert === 'string' ? alert : alert?.text
  const urgent = typeof alert === 'string' ? false : alert?.urgent
  const customIcon = typeof alert === 'string' ? null : alert?.customIcon
  const updatedBy = typeof alert === 'string' ? null : alert?.updatedBy
  return (
    <Box borderRadius={4} overflow="hidden" bg="white" shadow="md" width="100%">
      <HStack
        spacing={1}
        borderBottom="1px solid #cdcdcd"
        px={2}
        bg="gray.100"
        w="100%"
        py={1}
      >
        <AlertIconPopover
          icon={customIcon}
          onChange={(updated) => onSubmit(
            typeof alert === 'string'
              ? { text: alert, customIcon: updated }
              : { ...alert, customIcon: updated },
          )
          }
        />
        <Text color="gray.500" fontWeight={600} fontSize="sm">
          {getDateString(parseInt(id, 10), 'short')}
        </Text>
        <HStack ml="auto">
          {updatedBy ? (
            <Box>
              <UserBadge userId={updatedBy} />
            </Box>
          ) : null}
          <DeleteButton itemName="Alert" onDelete={onDelete} />
        </HStack>
      </HStack>
      <Box w="100%" px={1} pb={1}>
        <Editable
          value={text}
          onSubmit={(v) => onSubmit(
            typeof alert === 'string' ? { text: v } : { ...alert, text: v },
          )
          }
          field={alertTextField}
        />
        <Editable
          index={1}
          value={urgent}
          onSubmit={(v) => {
            const updated = typeof alert === 'string'
              ? { text: alert, urgent: v }
              : { ...alert, urgent: v }
            return onSubmit(updated)
          }}
          field={alertUrgentField}
        />
      </Box>
    </Box>
  )
}

const AlertsView = ({
  alerts,
  accessLevel,
  type,
  onDelete,
  onSubmit,
}: {
  alerts?: Record<number, string | Alert>
  accessLevel?: UserGroup
  type: 'assessment' | 'patient'
  onDelete: (key: string) => Promise<UpdateCallback>
  onSubmit: (key: string, value: any) => Promise<UpdateCallback>
}) => {
  const sorted = useMemo(
    () => Object.keys(alerts || {}).sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10),
    ),
    [alerts],
  )
  return (
    <VStack w="100%" spacing={1}>
      {alerts ? (
        sorted.map((key) => (
          <AlertEdit
            id={key}
            key={key}
            onDelete={() => onDelete(key)}
            onSubmit={(data) => onSubmit(key, data)}
            alert={alerts[parseInt(key, 10)]}
          />
        ))
      ) : (
        <Text width="101%" fontSize="sm" color="gray.600">
          No alerts
        </Text>
      )}
      {/* <AddItem
        placeholder="Alert description"
        itemName="Alert"
        onAdd={(value) => onSubmit(`${Date.now()}`, { text: value })}
      /> */}
      <AddAlertPopover type={type} accessLevel={accessLevel} inList />
    </VStack>
  )
}

const AddAlertModal = ({
  onClose,
  accessLevel,
  type,
}: {
  onClose: () => void
  accessLevel?: UserGroup
  type?: 'assessment' | 'patient'
}) => {
  const { selectedAssessment, practicePatientRef, adminPatientRef } = useContext(UserContext)
  const { adminRef, practiceRef } = selectedAssessment || {}
  const { appName } = useApp()
  const update = useUpdateDoc()
  const field = useMemo<FieldMap>(() => {
    const children: Record<string, FormElement> = {}
    if (appName === 'app' && !accessLevel) {
      children.access = alertAccessLevelField
    }
    if (selectedAssessment && !type) {
      children.type = alertTypeField
    }
    children.text = alertTextField
    children.urgent = alertUrgentField
    children.customIcon = customIconField

    return {
      name: 'New Alert',
      children,
    }
  }, [appName, selectedAssessment, accessLevel, type])
  return (
    <DefaultModal
      isOpen
      onClose={onClose}
      overlayHeader
      render={() => (
        <SimpleForm
          theme="detailed"
          boxProps={{ bg: 'gray.50' }}
          field={field}
          onSubmit={async (data) => {
            const usedAccessLevel = accessLevel || data.access
            const assessmentRef = appName === 'providers-app' || usedAccessLevel === 'practice'
              ? practiceRef
              : adminRef
            const patientRef = appName === 'providers-app' || usedAccessLevel === 'practice'
              ? practicePatientRef
              : adminPatientRef
            const updatedRef = (type || data.type) === 'assessment' ? assessmentRef : patientRef
            return update(updatedRef, 'alerts', {
              [Date.now().toString()]: {
                text: data.text,
                urgent: data.urgent || false,
                customIcon: data.customIcon || null,
              },
            }).then(() => {
              onClose()
              return { success: 'Added alert' }
            })
          }}
        />
      )}
    />
  )
}
const MiscarriedBadge = () => (
  <Text
    lineHeight={1.4}
    px={1}
    mx={1}
    borderRadius={3}
    fontWeight={600}
    fontSize="sm"
    bg="red.500"
    color="white"
  >
    MISCARRIED
  </Text>
)

const MiscarriedContent = () => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)
  const {
    miscarried,
    miscarriedUpdatedBy,
    miscarriedUpdatedByGroup,
    miscarriedUpdatedOn,
  } = selectedAssessment || {}
  return (
    <VStack w="100%" spacing={0}>
      <Editable
        onSubmit={async (v) => {
          if (!assessmentId) return { error: 'No pregnancy ID' }
          return updateAssessmentMiscarriedStatus({
            assessmentId,
            miscarried: v,
          })
            .then(() => ({ success: 'Updated miscarried status' }))
            .catch((e: any) => ({
              error: e.message || 'Error updating miscarried status',
            }))
        }}
        value={miscarried}
        field={{
          type: FieldTypes.CHECKBOX,
          placeholder: 'Patient has miscarried',
        }}
      />
      {miscarriedUpdatedBy ? (
        <ActionLog
          action="Updated"
          on={miscarriedUpdatedOn}
          by={miscarriedUpdatedBy}
          group={miscarriedUpdatedByGroup}
        />
      ) : null}
    </VStack>
  )
}

const MiscarriedPopover = () => (
  <Popover closeOnBlur={false} strategy="fixed" isLazy placement="bottom">
    <PopoverTrigger>
      <HStack>
        <Button variant="unstyled">
          <MiscarriedBadge />
        </Button>
      </HStack>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody borderRadius={6} overflow="hidden" p={0}>
          <HStack bg="red.600" borderBottom="1px solid #cdcdcd" px={2} py={1}>
            <Text color="red.100" fontWeight={600}>
              MISCARRIED
            </Text>
            <PopoverCloseButton color="gray.50" />
          </HStack>
          <Stack bg="gray.50" spacing={0}>
            <VStack spacing={1} p={2}>
              <Text fontSize="xs" color="gray.500" fontWeight="bold">
                MISCARRIED STATUS
              </Text>
              <MiscarriedContent />
            </VStack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
)

const PatientAlertPopover = ({ access }: { access: 'practice' | 'admin' }) => {
  const {
    user, adminPatientRef, selectedAssessment, practicePatientRef,
  } = useContext(UserContext)
  const {
    adminAlerts,
    practiceAlerts,
    adminRef: adminAssessmentRef,
    practiceRef: practiceAssessmentRef,
  } = selectedAssessment || {}
  const assessmentAlertsRef = useMemo(
    () => (access === 'admin' ? adminAssessmentRef : practiceAssessmentRef),
    [access, adminAssessmentRef, practiceAssessmentRef],
  )
  const patientAlertsRef = useMemo(
    () => (access === 'admin' ? adminPatientRef : practicePatientRef),
    [access, adminPatientRef, practicePatientRef],
  )
  const {
    adminAlerts: adminPatientAlerts,
    practiceAlerts: practicePatientAlerts,
  } = user || {}
  const patientAlerts = useMemo(
    () => (access === 'admin' ? adminPatientAlerts : practicePatientAlerts),
    [access, adminPatientAlerts, practicePatientAlerts],
  )

  const assessmentAlerts = useMemo(
    () => (access === 'admin' ? adminAlerts : practiceAlerts),
    [access, adminAlerts, practiceAlerts],
  )

  const update = useUpdateDoc('alert')
  const { appName } = useApp()
  const allAlerts = useMemo<WithId<Partial<Alert>>[]>(() => {
    const all = {
      ...patientAlerts,
      ...assessmentAlerts,
    }
    return Object.values(all).reduce((acc, curr, idx) => {
      if (typeof curr === 'string') {
        return [...acc, { text: curr, id: `text-alert-${idx}` }]
      }
      return [...acc, { ...curr, id: `alert-${idx}` }]
    }, [] as WithId<Partial<Alert>>[])
  }, [patientAlerts, assessmentAlerts])

  return (
    <Popover
      closeOnBlur={false}
      strategy="fixed"
      trigger="hover"
      isLazy
      placement="bottom"
    >
      <Flex cursor="pointer" align="center">
        <PopoverTrigger>
          <VStack spacing={0}>
            <Text
              fontFamily="Comfortaa"
              fontSize="xs"
              fontWeight={600}
              lineHeight={1.1}
              color="gray.600"
            >
              {access === 'admin' ? 'ADMIN' : 'PRACTICE'} ALERTS
            </Text>
            <HStack
              // w='100%'
              py={1}
              px="0.4rem"
              borderRadius={4}
              maxW="140px"
              justify="center"
              bg="whiteAlpha.800"
              overflow="hidden"
              minW="120px"
              spacing={2}
            >
              <HStack divider={<StackDivider />} w="100%" spacing={1}>
                {allAlerts.length > 0 ? (
                  allAlerts.map((a) => (
                    <HStack key={`${a.id}`} spacing={1}>
                      <AlertIcon icon={a.customIcon} />
                      <Text
                        fontSize="sm"
                        color="gray.600"
                        fontWeight={600}
                        whiteSpace="nowrap"
                      >
                        {a.text}
                      </Text>
                    </HStack>
                  ))
                ) : (
                  <Text fontSize="xs" color="gray.500" fontWeight={600}>
                    No alerts
                  </Text>
                )}
              </HStack>
            </HStack>
          </VStack>
        </PopoverTrigger>
      </Flex>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody borderRadius={6} overflow="hidden" p={0}>
            <HStack bg="red.600" px={2} py={1}>
              <Text color="red.100" fontWeight={600}>
                {access === 'admin' ? 'ADMIN' : 'PRACTICE'} ALERTS
              </Text>
              <PopoverCloseButton color="gray.50" />
            </HStack>
            <Stack bg="gray.50" spacing={0}>
              <Box borderTop="1px solid #cdcdcd" py={2} px={3}>
                <Text fontSize="xs" color="gray.500" fontWeight="bold">
                  PREGNANCY ALERTS
                </Text>
                <AlertsView
                  type="assessment"
                  alerts={assessmentAlerts}
                  accessLevel={access}
                  onDelete={(key) => update(assessmentAlertsRef, `alerts.${key}`, deleteField())
                  }
                  onSubmit={(key, data) => update(
                    assessmentAlertsRef,
                    `alerts.${key}`,
                    addMetadata(data, appName, false),
                  )
                  }
                />
              </Box>
              <Box borderTop="1px solid #cdcdcd" py={2} px={3}>
                <Text fontSize="xs" color="gray.500" fontWeight="bold">
                  PATIENT ALERTS
                </Text>
                <AlertsView
                  type="patient"
                  accessLevel={access}
                  alerts={patientAlerts}
                  onDelete={(key) => update(patientAlertsRef, `alerts.${key}`, deleteField())
                  }
                  onSubmit={(key, data) => update(patientAlertsRef, `alerts.${key}`, data)
                  }
                />
              </Box>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
const AddAlertPopover = ({
  accessLevel,
  type,
  inList,
}: {
  accessLevel?: UserGroup
  type?: 'assessment' | 'patient'
  inList?: boolean
}) => {
  const [addingAlert, setAddingAlert] = useState(false)
  const { isMobile } = useContext(ScreenContext)
  const { assessmentId, selectedAssessment } = useContext(UserContext)
  const { miscarried } = selectedAssessment || {}
  const [reportingMiscarried, setReportingMiscarried] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const handleReportMiscarried = useCallback(async () => {
    if (!assessmentId) {
      processResponse({ error: 'No pregnancy ID' })
      return
    }
    setReportingMiscarried(true)
    try {
      await updateAssessmentMiscarriedStatus({
        assessmentId,
        miscarried: true,
      })
    } catch (e: any) {
      processResponse({
        error: e?.message || 'Error updating miscarried status',
      })
    }
    setReportingMiscarried(false)
  }, [assessmentId, processResponse])

  return (
    <Box>
      <Popover
        trigger="hover"
        closeOnBlur={false}
        strategy="fixed"
        isLazy
        placement="bottom"
      >
        <PopoverTrigger>
          {inList ? (
            <Button
              // variant="unstyled"
              variant="outline"
              size="xs"
              bg="white"
              colorScheme="red"
              fontSize="xs"
            >
              + ADD ALERT
            </Button>
          ) : (
            <Button
              aria-label="Add Alert"
              variant="ghost"
              gap={1}
              color="blackAlpha.600"
              size="sm"
              _hover={{
                bg: 'blackAlpha.200',
              }}
            >
              <WarningTwoIcon w={4} h={4} />
              {isMobile ? <Text>No alerts</Text> : null}
            </Button>
          )}
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="220px" p={0} boxShadow="1px 1px 3px #00000077">
            <PopoverArrow />
            <PopoverBody borderRadius={6} overflow="hidden" bg="gray.50">
              <VStack spacing={1}>
                <Button
                  w="100%"
                  onClick={() => setAddingAlert(true)}
                  color="red.600"
                  bg="white"
                  size="sm"
                  borderColor="red.600"
                  borderWidth="1px"
                >
                  ADD{type ? ` ${type === 'assessment' ? 'PREGNANCY' : 'PATIENT'}` : ''} ALERT
                </Button>
                {assessmentId && !miscarried ? (
                  <Button
                    w="100%"
                    onClick={handleReportMiscarried}
                    isLoading={reportingMiscarried}
                    bg="red.600"
                    size="sm"
                    color="white"
                  >
                    REPORT MISCARRIED
                  </Button>
                ) : null}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {addingAlert ? (
        <AddAlertModal
          type={type}
          accessLevel={accessLevel}
          onClose={() => setAddingAlert(false)}
        />
      ) : null}
    </Box>
  )
}

export const ProfileHeaderAlerts = () => {
  const { appName } = useApp()
  const { selectedAssessment, user } = useContext(UserContext)

  const { adminAlerts: adminUserAlerts, practiceAlerts: practiceUserAlerts } = user || {}
  const { adminAlerts, practiceAlerts, miscarried } = selectedAssessment || {}

  const { isMobile } = useContext(ScreenContext)

  const hasNoAdminAlerts = useMemo(
    () => !(
      Object.keys(adminAlerts || {}).length
        + Object.keys(adminUserAlerts || {}).length
    ),
    [adminAlerts, adminUserAlerts],
  )
  const hasNoPracticeAlerts = useMemo(
    () => !(
      Object.keys(practiceAlerts || {}).length
        + Object.keys(practiceUserAlerts || {}).length
    ),
    [practiceAlerts, practiceUserAlerts],
  )
  const hasNoAlerts = useMemo(
    () => hasNoAdminAlerts && hasNoPracticeAlerts,
    [hasNoAdminAlerts, hasNoPracticeAlerts],
  )
  let body = <AddAlertPopover />
  if (appName === 'providers-app') {
    if (hasNoPracticeAlerts) {
      body = <AddAlertPopover accessLevel="practice" />
    } else {
      body = <PatientAlertPopover access="practice" />
    }
  } else if (!hasNoAlerts) {
    body = (
      <>
        {hasNoAdminAlerts ? (
          <AddAlertPopover accessLevel="admin" />
        ) : (
          <PatientAlertPopover access="admin" />
        )}
        {hasNoPracticeAlerts ? (
          <AddAlertPopover />
        ) : (
          <PatientAlertPopover access="practice" />
        )}
      </>
    )
  }

  return (
    <HStack py={1} justify="center" w={isMobile ? '100%' : 'auto'} spacing={2}>
      {miscarried ? <MiscarriedPopover /> : null}
      {body}
    </HStack>
  )
}
