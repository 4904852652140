import {
  AssessmentDocument,
  ConsentForm, Mode, TemplateNodeFieldMap, UserRoleItem,
} from '@hb/shared'
import {
  createContext,
  createRef, RefObject,
} from 'react'
import { EditorSize } from './hooks'

export const TextEditorContext = createContext<
  {
    mode: Mode
    document: ConsentForm | AssessmentDocument | undefined
    toBeSignedByUser: UserRoleItem | null
    editorDomElement: RefObject<HTMLDivElement | undefined>
    signerLoading: boolean
  } & EditorSize
>({
  mode: 'View',
  document: undefined,
  signerLoading: false,
  editorDomElement: createRef(),
  toBeSignedByUser: null,
  baseZoom: 1,
  height: 0,
  width: 0,
})

export const TextEditorFormContext = createContext<{
  field: TemplateNodeFieldMap | null
  readOnly: boolean
}>({ field: null, readOnly: false })
