import {
  Center, CircularProgress, Flex, Text,
} from '@chakra-ui/react'
import { DocData } from '@hb/shared/collections'
import {
  DataColumn,
  DataTableProps,
  isListDividerItem,
  ListItem,
  UserRowComponentProps,
  WithId,
} from '@hb/shared/types'
import { getReverseName } from '@hb/shared/utils'
import React, {
  forwardRef, useContext, useEffect, useMemo, useState,
} from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { DataGridContext, SearchBarContext, useApp } from '../../contexts'
import { usePopulatedItem } from '../../hooks/backend/usePopulatedItem'
import { useAuth } from '../../store'
import { getRowBackground } from '../DataView/utils'
import { outerElementType } from './DesktopDataList'
import { ListDividerView } from './dividers/ListDividerView'
import { usePreview } from './hooks'
import { MobileRowHeaders } from './RowHeaders'
import { getFlexColumnWidth, getItemsWithDividers } from './utils'

const ListItemView = <Snippet extends WithId>({
  style,
  index,
  isScrolling,
  data,
}: ListChildComponentProps<UserRowComponentProps<Snippet>>) => {
  const {
    items,
    inlineColumns,
    blockColumns,
    preview,
    auth,
    searchQuery,
    app,
    onRowClick,
    admins,
    tabName,
    tab,
  } = data
  const item = items[index]
  const background = tab.getItemBackgroundColor
    ? tab.getItemBackgroundColor(item, index)
    : getRowBackground(index)

  if (isListDividerItem(item)) {
    return (
      <ListDividerView mobileLayout item={item} style={style} />
    )
  }

  return (
    <Flex
      overflow="hidden"
      width="100%"
      p={2}
      fontSize="sm"
      flexFlow="column"
      align="center"
      key={`${item.id}_${index}`}
      style={style}
    >
      <Flex
        borderRadius={4}
        flexFlow="column"
        w="100%"
        onClick={onRowClick ? () => onRowClick(item.id) : undefined}
        py={1}
        px={2}
        bg={background}
        boxShadow="1px 1px 3px #00000077"
        _hover={
          onRowClick
            ? {
              cursor: 'pointer',
              bg: 'gray.100',
            }
            : undefined
        }
      >
        {Object.keys(blockColumns).map((key) => {
          const { Render: render, flexProps } = blockColumns[key].mobile || blockColumns[key]
          const hasMobileRender = !!blockColumns[key].mobile
          return (
            <Flex
              w="100%"
              key={key}
              align="center"
              justify="center"
              h="36px"
              {...flexProps}
              style={{ ...flexProps?.style }}
            >
              {!hasMobileRender ? (
                <Text fontSize="sm" fontWeight={600} color="gray.600">
                  {blockColumns[key].title}
                </Text>
              ) : null}
              {render({
                isScrolling,
                data: item,
                searchQuery,
                app,
                auth,
                tabName,
                preview,
                admins,
                cell: {
                  columnIndex: 0,
                  rowIndex: index,
                },
              })}
            </Flex>
          )
        })}
        <Flex w="100%" justify="space-between" flexFlow="row wrap">
          {Object.keys(inlineColumns).map((key, i) => {
            const {
              Render: render,
              flexProps,
              width,
            } = inlineColumns[key].mobile || inlineColumns[key]
            const hasMobileRender = !!inlineColumns[key].mobile
            return (
              <Flex
                height="36px"
                align="center"
                py={1}
                key={key}
                {...flexProps}
                width={width ? `${width}px` : 'unset'}
                style={{ ...flexProps?.style }}
              >
                {!hasMobileRender ? (
                  <Text
                    whiteSpace="nowrap"
                    fontSize="sm"
                    fontWeight={600}
                    color="gray.600"
                  >
                    {inlineColumns[key].title}
                  </Text>
                ) : null}
                {render({
                  isScrolling,
                  data: item,
                  app,
                  admins,
                  searchQuery,
                  auth,
                  tabName,
                  preview,
                  cell: {
                    columnIndex: i,
                    rowIndex: index,
                  },
                })}
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export const MobileDataList = forwardRef<
  VariableSizeList<UserRowComponentProps<WithId<DocData>>>,
  Omit<DataTableProps<WithId<any>>, 'itemHeight'>
>(
  (
    {
      items,
      columns,
      collection,
      admins,
      tabName,
      tab,
      width,
      height,
      sortKey,
      sortAsc,
      onRowClick,
    },
    ref,
  ) => {
    const [displayedMessage, setDisplayedMessage] = useState('')
    const [scrollTop, setScrollTop] = useState(0)
    const previewState = usePreview()
    const { preview } = previewState
    const { searchQuery } = useContext(SearchBarContext)

    const { inlineColumns, blockColumns } = useMemo(
      () => Object.entries(columns).reduce(
        (acc, [key, val]) => {
          const mobileWidth = val.mobile ? val.mobile.width : val.width
          if (mobileWidth && mobileWidth < (width - 20) / 2) {
            acc.inlineColumns[key] = val
          } else {
            acc.blockColumns[key] = val
          }
          return acc
        },
          { blockColumns: {}, inlineColumns: {} } as {
            blockColumns: Record<string, DataColumn<WithId<DocData>>>
            inlineColumns: Record<string, DataColumn<WithId<DocData>>>
          },
      ),
      [columns, width],
    )

    const { arr, map } = useMemo(() => {
      const values = Object.entries(items || {}).map(([key, val]) => ({
        ...val,
        id: key,
      }))
      return {
        arr: values as Array<WithId<ListItem>>,
        map: values.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {} as Record<string, WithId<ListItem>>,
        ),
      }
    }, [items])
    const previewItem = useMemo(
      () => (preview?.id ? map[preview.id] : null),
      [preview, map],
    )
    const populated = usePopulatedItem(previewItem, collection)
    const reverseName = useMemo(() => getReverseName(populated), [populated])
    const auth = useAuth()
    const app = useApp()

    const itemHeight = useMemo(() => {
      let totalInlineWidth = 0
      Object.values(inlineColumns).forEach(
        ({ width: desktopWidth, mobile }) => {
          totalInlineWidth += mobile?.width || desktopWidth || 0
        },
      )
      const numInlineRows = Math.ceil(totalInlineWidth / (width - 90))

      return numInlineRows * 36 + Object.values(blockColumns).length * 36 + 20
    }, [inlineColumns, blockColumns, width])

    const flexColWidth = useMemo(
      () => getFlexColumnWidth({ width, columns }),
      [columns, width],
    )

    const [remounting, setRemounting] = useState(false)
    useEffect(() => {
      setRemounting(true)
      const timeout = setTimeout(() => {
        setTimeout(() => setRemounting(false), 100)
      }, 500)
      return () => clearTimeout(timeout)
    }, [flexColWidth])
    const itemsWithDividers = useMemo(
      () => getItemsWithDividers(arr, columns, sortKey, sortAsc ? 'asc' : 'desc'),
      [arr, columns, sortKey, sortAsc],
    )

    const itemData = useMemo<UserRowComponentProps<WithId<any>>>(
      () => ({
        columns,
        auth,
        items: itemsWithDividers,
        app,
        tabName,
        blockColumns,
        inlineColumns,
        onRowClick,
        searchQuery,
        tab,
        admins,
        flexColWidth,
        preview: previewState,
      }),
      [
        app,
        itemsWithDividers,
        auth,
        columns,
        flexColWidth,
        previewState,
        searchQuery,
        onRowClick,
        admins,
        tab,
        tabName,
        blockColumns,
        inlineColumns,
      ],
    )

    // const keys = Object.keys(columns)
    // const numCols = keys.length
    const { keys } = useMemo(() => {
      const ks = Object.keys(columns)
      return { keys: ks, numCols: ks.length }
    }, [columns])

    return (
      <DataGridContext.Provider
        value={{
          data: map || null,
          columns,
          item: populated,
          collection,
          display: setDisplayedMessage,
          width,
          scrollTop,
          height,
          ...previewState,
          itemHeight,
          // item: populated,
          reverseName,
          clearMessage: () => setDisplayedMessage(''),
        }}
      >
        <Flex pos="relative" direction="column">
          <Flex justify="center" pos="relative">
            <Flex
              opacity={displayedMessage ? 1 : 0}
              pos="absolute"
              right="10px"
              shadow="md"
              p={2}
              zIndex={3}
              bg="white"
              borderRadius="md"
            >
              {displayedMessage}
            </Flex>
            <MobileRowHeaders keys={keys} columns={columns} />
          </Flex>
          {remounting ? (
            <Center width={`${width}px`} height={`${height}px`}>
              <CircularProgress isIndeterminate color="#777" size={10} />
            </Center>
          ) : (
            <VariableSizeList
              ref={ref}
              outerElementType={outerElementType}
              itemKey={(e) => `${isListDividerItem(itemsWithDividers[e]) ? 'divider' : itemsWithDividers[e].id}_${e}`
              }
              style={{
                background: '#f0f0f0',
                // overflow: 'overlay',
                transition: 'height 500ms',
                overflowX: 'hidden',
              }}
              onScroll={({ scrollOffset }) => {
                previewState.closePreview()
                setScrollTop(scrollOffset)
              }}
              itemCount={itemsWithDividers.length}
              itemData={itemData}
              itemSize={(idx) => (isListDividerItem(itemsWithDividers[idx]) ? 28 : itemHeight)
              }
              useIsScrolling
              width={width}
              height={height}
            >
              {ListItemView}
            </VariableSizeList>
          )}
        </Flex>
      </DataGridContext.Provider>
    )
  },
)
