import { EditorVersion, PopulatedNode } from '@hb/shared/editor'
import { TemplateKey } from '@hb/shared/types'
import { UpdateCallback } from '@hb/shared/types/firebase/shared'
import { getShortcutArgs } from '@hb/shared/utils/templates/shortcutArgs'
import React, {
  PropsWithChildren, useCallback, useContext, useMemo,
} from 'react'
import { Descendant } from 'slate'
import { UserContext } from '../../../contexts/UserContext'
import { saveAssessmentDraft, submitAssessmentResults } from '../../../hooks/backend/assessments'
import { EditMode, TemplateDataProvider } from './data'
import { TemplateInsurancePlansProvider, useTemplateInsurancePlans } from './plan'
import { TemplateViewProvider } from './view'

export type AssessmentDataProviderProps = {
  type: TemplateKey
  editMode: EditMode
}
export const AssessmentDataProvider = ({ children, type, editMode }: PropsWithChildren<AssessmentDataProviderProps>) => {
  const { selectedPlan } = useTemplateInsurancePlans()
  const { selectedAssessment, user } = useContext(UserContext)
  const shortcutArgs = useMemo(
    () => getShortcutArgs(user, selectedAssessment, selectedPlan),
    [user, selectedAssessment, selectedPlan],
  )

  return (
    <TemplateDataProvider editMode={editMode} type={type} data={shortcutArgs}>
      {children}
    </TemplateDataProvider>
  )
}

export const AssessmentEditorProvider = ({
  children,
  initialText,
}: PropsWithChildren<{ initialText: Descendant[] }>) => {
  const { assessmentId } = useContext(UserContext)

  const onSubmit = useCallback(
    async (text: PopulatedNode[], version: EditorVersion) => {
      if (!assessmentId) return { error: 'internal error' }
      return submitAssessmentResults({
        assessmentId,
        results: text,
        editorVersion: version,
      })
        .then((res) => res.data as UpdateCallback)
        .catch((err) => ({ error: err.message }))
      // submit
    },
    [assessmentId],
  )

  const onSave = useCallback(
    async (value: Descendant[], version: EditorVersion) => {
      if (!assessmentId) return { error: 'internal error' }
      return saveAssessmentDraft({
        assessmentId,
        editorVersion: version,
        draft: value || initialText,
      })
        .then((res) => res.data as UpdateCallback)
        .catch((err) => ({ error: err.message }))
    },
    [assessmentId, initialText],
  )

  return (
    <TemplateInsurancePlansProvider>
      <TemplateViewProvider initialText={initialText} onSave={onSave} onSubmit={onSubmit}>
        <AssessmentDataProvider editMode='document' type='assessments' >{children}</AssessmentDataProvider>
      </TemplateViewProvider>
    </TemplateInsurancePlansProvider>
  )
}
