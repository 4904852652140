/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  AssessmentSnippet,
  ASSESSMENT_SNIPPETS,
  getPracticeAssessmentCollectionPath,
  PracticeAssessmentData,
} from '@hb/shared'
import { colors, messageThreadCollections } from '@hb/shared/constants'
import { MessageThread, ThreadType } from '@hb/shared/types/messaging'
import React, { useContext, useMemo } from 'react'
import { ScreenContext, useApp, usePracticeAccess } from '../../contexts'
import { useDocument } from '../../hooks/backend/useDocument'
import chatIcon from '../../icons/chat_green.svg'
import { useAuth } from '../../store'
import { PulsingCircle } from '../Animation/PulsingCirlce'
import './styles.css'
import { ThreadView } from './ThreadView/ThreadView'

export const ThreadPopover = ({
  threadType,
  id,
}: {
  threadType: ThreadType
  id: string
}) => {
  const me = useAuth((s) => s.authUser)
  const { data: thread } = useDocument<MessageThread>(
    messageThreadCollections[threadType],
    id,
  )

  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const { data: assessment } = useDocument<
    AssessmentSnippet | PracticeAssessmentData
  >(
    appName === 'providers-app' && selectedPracticeId
      ? getPracticeAssessmentCollectionPath(selectedPracticeId)
      : ASSESSMENT_SNIPPETS,
    threadType === ThreadType.ASSESSMENT ? id : null,
  )

  const { width: screenWidth } = useContext(ScreenContext)
  const width = useMemo(() => Math.min(screenWidth, 450), [screenWidth])
  const unread = !!assessment?.unreadThreadMessages
    || !!(me && thread?.unreadBy?.includes(me.uid))
  const { isMobile } = useContext(ScreenContext)
  // const mostRecentMessageFromMyGroup = xor(mostRecentMessage?.sentFromAdmin, appName)
  return (
    <HStack maxW="350px" justify="flex-end">
      {/* {mostRecentMessage ? (
        <ThreadMessageView horizontal message={mostRecentMessage} />
      ) : null} */}
      <Popover
        closeOnBlur={false}
        strategy="fixed"
        isLazy
        placement={isMobile ? 'bottom-end' : 'left-start'}
      >
        {({ onClose, isOpen }) => (
          <>
            <PopoverTrigger>
              <Box>
                <Tooltip
                  placement="top"
                  hasArrow
                  label={appName === 'app' ? 'Provider Chat' : 'HB Chat'}
                >
                  <IconButton
                    bg="gray.50"
                    className={unread ? 'pulsating-shadow' : 'not-pulsating-shadow'}
                    size="sm"
                    borderRadius={6}
                    aria-label="Thread"
                    icon={
                      <Flex gap={1} px={unread ? 2 : 0} align='center'>
                        {
                          unread ? (
                            <Text opacity={0.8} lineHeight={1} fontSize="xs" color={colors.green.hex} fontWeight="bold" >
                              UNREAD
                            </Text>
                          ) : null
                        }
                        <Image
                          transform="scale(-1,1)"
                          width="24px"
                          opacity={0.7}
                          // filter="drop-shadow(1px 1px 2px rgba(0,0,0,0.3))"
                          src={chatIcon}
                          alt="Thread"
                        />
                        {unread ? (
                            <PulsingCircle style={{
                              position: 'absolute', height: '14px', width: '14px', top: '1px', right: '-4px',
                            }} />
                        ) : null}
                      </Flex>
                    }
                  />
                </Tooltip>
              </Box>
            </PopoverTrigger>
            <PopoverContent border="none" width={`${width}px`}>
              <ThreadView
                width={width}
                isOpen={isOpen}
                threadType={threadType}
                assessmentThreadUnread={unread}
                threadId={id}
                onBack={onClose}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </HStack>
  )
}
