import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import {
  assessmentTemplatesCollection, authAppealsTemplatesCollection, authInstructionsTemplatesCollection, claimTemplatesCollection, clinicalsTemplatesCollection, consentFormTemplatesCollection, invoiceTemplatesCollection,
} from '@hb/shared/collections'
import React, { useContext, useState } from 'react'
import { ScreenContext } from '../../contexts/ScreenContext'
import { SelectedTemplate, TemplateContext } from '../../contexts/TemplateContext'
import { useCollectionItem } from '../../hooks'
import { PageContainer } from '../Views/PageContainer'
import { TemplateEditorModal } from './TemplateEditModal'
import { TemplatesSection } from './TemplatesSection'

export const TemplatesView: React.FC = () => {
  const [selected, setSelected] = useState<
  SelectedTemplate | null
  >(null)
  const { height } = useContext(ScreenContext)
  const select = (c: SelectedTemplate) => {
    setSelected(c)
  }
  const deselect = () => {
    setSelected(null)
  }
  const { item } = useCollectionItem(selected?.collection || assessmentTemplatesCollection, selected?.templateId)

  return (
    <PageContainer>
      <Tabs
        isLazy
        borderRadius={4}
        boxShadow="md"
        w="100%"
        position="relative"
        background="white"
      >
        <TemplateContext.Provider
          value={{
            selected,
            select,
            deselect,
          }}
        >
          <Box borderBottom="1px solid #cdcdcd" py={2} px={4}>
            <Text fontSize="lg">Templates</Text>
          </Box>
          <TabList>
            <Tab>Assessments</Tab>
            <Tab>Claims</Tab>
            <Tab>Clinicals</Tab>
            <Tab>Auth Appeals</Tab>
            <Tab>Auth Instructions</Tab>
            <Tab>Invoices / Receipts</Tab>
            <Tab>Consent Forms</Tab>
          </TabList>
          <TabPanels
            position="relative"
            height={`${height - 200}px`}
            overflowY="auto"
          >
            <TabPanel p={0}>
              <TemplatesSection
                collection={assessmentTemplatesCollection}
                templateType="assessments"
              />
            </TabPanel>
            <TabPanel p={0}>
              <TemplatesSection
                collection={claimTemplatesCollection}
                templateType="claims"
              />
            </TabPanel>
            <TabPanel p={0}>
              <TemplatesSection
                collection={clinicalsTemplatesCollection}
                templateType="clinicals"
              />
            </TabPanel>
            <TabPanel p={0}>
              <TemplatesSection
                collection={authAppealsTemplatesCollection}
                templateType="authAppeals"
              />
            </TabPanel>
            <TabPanel p={0}>
              <TemplatesSection
                collection={authInstructionsTemplatesCollection}
                templateType="authInstructions"
              />
            </TabPanel>
            <TabPanel p={0}>
              <TemplatesSection
                collection={invoiceTemplatesCollection}
                templateType="invoiceAndReceipt"
              />
            </TabPanel>
            <TabPanel p={0}>
              <TemplatesSection
                collection={consentFormTemplatesCollection}
                templateType="consentForm"
              />
            </TabPanel>
          </TabPanels>
        </TemplateContext.Provider>
        {selected ? (
          <TemplateEditorModal
            templateId={selected.templateId}
            templateType={selected.templateType}
            isOpen
            onClose={deselect}
          />
        ) : null}
      </Tabs>
    </PageContainer>
  )
}
