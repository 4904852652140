import { CheckIcon, CloseIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { LocalCollectionState } from '@hb/shared/collections'
import { FieldTypes } from '@hb/shared/constants'
import {
  AssessmentSnippet,
  CheckboxField,
  DataColumn,
  DataColumnRenderProps, TextField, UserRoleItem, WithId,
} from '@hb/shared/types'
import { getFullName, getInitials, getReverseName } from '@hb/shared/utils'
import { deleteField, doc, DocumentReference } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo,
} from 'react'
import { Field, Form } from 'react-final-form'
import { db } from '../../../../backend/db'
import { DataListContext } from '../../../../contexts/DataListContext'
import { ThemeContext } from '../../../../contexts/ThemeContext'
import { DataGridContext } from '../../../../contexts/UserGridContext'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { DeleteButton } from '../../../Buttons/DeleteButton'
import { ColorsCircle, ColorsSelect, ColorsSelectPopover } from '../../../ColorsSelect'
import { StandaloneInput } from '../../../forms'
import Input from '../../../forms/Input'

const urgentField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Urgent',
}

const useAssignedAdminColors = (admins: LocalCollectionState<UserRoleItem>) => {
  const { items } = admins
  const options = useMemo(() => items.filter((a) => !!a.adminColor).map((a) => {
    const fullName = getFullName(a)
    return ({ name: fullName, initials: getInitials(fullName), hex: a.adminColor || '' })
  }), [items])

  return options
}

const useAssignedAdminsFilter = (adminsState: LocalCollectionState<UserRoleItem>) => {
  const { filters, filterBy, removeFilter } = useContext(DataListContext)
  const options = useAssignedAdminColors(adminsState)
  const { items: admins } = adminsState
  const value = useMemo(() => {
    const filter = filters.find((f) => f[0] === 'assignedAdmins')
    if (!filter) return []
    return Array.isArray(filter[2]) ? filter[2].map((adminId) => admins.find((a) => a.id === adminId)?.adminColor || '') : []
  }, [admins, filters])

  const onChange = useCallback((v: string[]) => {
    const newAdmins = v.map((color) => admins.find((a) => a.adminColor === color)?.id || '').filter((a) => !!a) as string[]
    if (newAdmins.length) filterBy(['assignedAdmins', 'in', newAdmins])
    else removeFilter('assignedAdmins')
  }, [admins, filterBy, removeFilter])

  return { options, value, onChange }
}

const useAssignedAdminsSelect = <T extends WithId<{assignedAdmins: string[]}>>(adminsState: LocalCollectionState<UserRoleItem>, item: T, updateRef: DocumentReference | null) => {
  const options = useAssignedAdminColors(adminsState)
  const { items: admins } = adminsState
  const { assignedAdmins = [] } = item || {}
  const colorsValue = assignedAdmins.map((id) => admins.find((a) => a.id === id)?.adminColor || '')
  // const isUrgent = getIsUrgent(item)
  const colors = assignedAdmins.map((id) => admins.find((a) => a.id === id)?.adminColor || '#777')
  const update = useUpdateDoc('assigned admins')

  const handleSubmit = useCallback((updatedColors: string[]) => {
    const adminIds = updatedColors.map((color) => admins.find((a) => a.adminColor === color)?.id || '').filter((a) => !!a) as string[]
    update(updateRef, 'assignedAdmins', adminIds)
  }, [admins, update, updateRef])

  return {
    options, colorsValue, handleSubmit, colors,
  }
}

const AssignedAdminsFilter = ({ adminsState }: {adminsState: LocalCollectionState<UserRoleItem>}) => {
  const { options, value, onChange } = useAssignedAdminsFilter(adminsState)
  return (
    <Flex p={2} flexFlow='row wrap'>
        <ColorsSelect onChange={onChange} value={value} allColors={options}/>
    </Flex>
  )
}

export const AssignedAdminsHeader = ({
  urgentKey,
}: {
  urgentKey?: string
}) => {
  const {
    filters, filterBy, removeFilter, admins,
  } = useContext(DataListContext)
  // const { urgentColor = colors.red.hex } = getIsUrgent(item) || {}

  const { value } = useAssignedAdminsFilter(admins)

  const urgentFilter = useMemo(
    () => (urgentKey ? filters.find((f) => f[0] === urgentKey) : null),
    [filters, urgentKey],
  )

  const handleUrgentToggle = useCallback(
    (urgent: boolean) => {
      if (!urgentKey) return
      if (urgent) {
        filterBy([urgentKey, '==', true])
      } else {
        removeFilter(urgentKey)
      }
    },
    [filterBy, removeFilter, urgentKey],
  )

  return (
    <Center px={1}>
      <Popover
        placement="right"
        // closeOnBlur={false}
        isLazy
        strategy="fixed"
      >
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Box p={1} cursor='pointer'>
                <ColorsCircle withStrikethrough value={value || []} />
              </Box>
            </PopoverTrigger>
            <PopoverContent p={0} w='190px'>
              <PopoverArrow />
              <Flex direction="column" w="100%">
                <Flex gap={1} pr={1}>
                  <Flex flex={1} minW="0">
                    <AssignedAdminsFilter adminsState={admins} />
                  </Flex>
                  <IconButton
                    variant="ghost"
                    w={6}
                    h={6}
                    minW={0}
                    aria-label="close"
                    size="xs"
                    borderRadius="full"
                    onClick={onClose}
                    icon={<CloseIcon w={2.5} h={2.5} color="gray.500" />}
                  />
                </Flex>
                <Flex px={2}>
                  {urgentKey ? (
                    <StandaloneInput
                      field={urgentField}
                      value={!!urgentFilter}
                      onChange={handleUrgentToggle}
                    />
                  ) : null}
                </Flex>
              </Flex>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Center>
  )
}

const urgentReasonField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Urgent reason',
}
const themeValue = { theme: 'basic', placeholderAbove: true } as const
export const AssignedAdminsContent = <T extends WithId<{ assignedAdmins: string[] }>>({
  item,
  admins,
}: {
  item: T
  admins: LocalCollectionState<UserRoleItem>
}) => {
  const { collection } = useContext(DataGridContext)
  const updateRef = useMemo(
    () => (item.id
      ? doc(
        db,
        collection,
        item.id,
      )
      : null),
    [collection, item],
  )

  const { colors, options, handleSubmit: submitColor } = useAssignedAdminsSelect(admins, item, updateRef)
  const update = useUpdateDoc('urgent reason')

  const displayedName = getReverseName(item)
  return (
    <ThemeContext.Provider value={themeValue}>
      <PopoverCloseButton />
      <PopoverHeader fontSize="sm" fontWeight={600}>
        <Flex align="center">
          <ColorsSelectPopover
            allColors={options}
            withStrikethrough
            onChange={submitColor}
            value={colors}
          />
          <Text color="gray.600" fontFamily="Comfortaa" ml={2}>
            {displayedName}
          </Text>
        </Flex>
      </PopoverHeader>
      <PopoverBody p={0}>
        {item ? (
          <Form
            onSubmit={({ reason }) => update(updateRef, 'urgentReason', reason)}
            initialValues={{
              reason: 'urgentReason' in item ? item?.urgentReason : '',
            }}
          >
            {({ handleSubmit, submitting }) => (
              <Flex px={2} align="flex-start">
                <Flex py={2} flexGrow={1}>
                  <Field name="reason">
                    {(props) => <Input {...props} field={urgentReasonField} />}
                  </Field>
                </Flex>
                <Flex pt={1} ml="auto">
                  <IconButton
                    variant="ghost"
                    size="xs"
                    aria-label="delete"
                    icon={<CheckIcon />}
                    onClick={handleSubmit}
                    isLoading={submitting}
                  />
                  <DeleteButton
                    itemName="Urgent reason"
                    onDelete={() => update(updateRef, 'urgentReason', deleteField())
                    }
                  />
                </Flex>
              </Flex>
            )}
          </Form>
        ) : (
          <Text px={2} py={1}>
            Missing user
          </Text>
        )}

        {/* {isClaim ? null : <OtherReasons item={item} />} */}
      </PopoverBody>
    </ThemeContext.Provider>
  )
}

const AssignedAdminsCircle = ({
  assignedAdmins,
  urgent,
  admins: { items: admins },
}: {
  assignedAdmins?: Array<string>
  urgent?: boolean
  admins: LocalCollectionState<UserRoleItem>
}) => {
  const colors = (assignedAdmins || []).map(
    (id) => admins.find((a) => a.id === id)?.adminColor || '#777',
  )
  const text = (assignedAdmins || [])
    .map((id) => admins.find((a) => a.id === id)?.fname || 'No admin')
    .join(', ')
  return (
    <Box p="3px" bg="#efefef" borderRadius="full" boxShadow="0 0 5px #00000088">
      <Tooltip
        bg="gray.400"
        color="white"
        textShadow="1px 1px 3px black"
        placement="top"
        hasArrow
        label={text}
      >
        <Center cursor="pointer" pos="relative">
          <ColorsCircle value={colors} />
          {urgent ? (
            <WarningIcon
              pos="absolute"
              pointerEvents="none"
              color="whiteAlpha.800"
              w={3}
              h={3}
            />
          ) : null}
        </Center>
      </Tooltip>
    </Box>
  )
}

const AssignedAdmins = <
  T extends WithId<{ assignedAdmins: string[]; isUrgent: boolean }>,
>({
    data,
    admins,
  }: DataColumnRenderProps<T>) => {
  const { assignedAdmins, isUrgent } = data
  return (
    <Popover placement="right" isLazy strategy="fixed">
      <PopoverTrigger>
        <Box>
          <AssignedAdminsCircle
            assignedAdmins={assignedAdmins}
            admins={admins}
            urgent={isUrgent}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <AssignedAdminsContent admins={admins} item={data} />
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

export const assignedAdminsColumn: DataColumn<AssessmentSnippet> = {
  title: 'Urgent',
  Header: () => (
    <Box px={1} >
      <AssignedAdminsHeader />
    </Box>
  ),
  // sortKey: 'urgentSort',
  flexProps: { justify: 'center' },
  width: 40,
  Render: AssignedAdmins,
}
