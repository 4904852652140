import { Descendant, EditorVersion, PopulatedNode } from '@hb/shared/editor'
import { SendFaxArgs, UpdateCallback } from '@hb/shared/types'
import React, {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react'

type TemplateViewContextValue = {
  onSave:
    | ((
        text: Descendant[],
        editorVersion: EditorVersion
      ) => Promise<UpdateCallback>)
    | undefined
  onSubmit:
    | ((
        text: PopulatedNode[],
        editorVersion: EditorVersion,
        name: string
      ) => Promise<UpdateCallback>)
    | undefined
  onFax:
    | ((
        args: Omit<SendFaxArgs, 'assessmentId' | 'category'>
      ) => Promise<UpdateCallback>)
    | undefined
  initialText?: Descendant[]
}

const TemplateViewContext = createContext<TemplateViewContextValue>({
  onSave: () => {
    throw new Error('Outside of template view context')
  },
  onSubmit: () => {
    throw new Error('Outside of template view context')
  },
  onFax: () => {
    throw new Error('Outside of template view context')
  },
})

export const TemplateViewProvider = ({
  children,
  onSave,
  onSubmit,
  initialText,
  onFax,
}: PropsWithChildren<Partial<TemplateViewContextValue>>) => {
  const contextValue = useMemo<TemplateViewContextValue>(
    () => ({
      onSave, onSubmit, onFax, initialText,
    }),
    [onSave, onSubmit, onFax, initialText],
  )
  return (
    <TemplateViewContext.Provider value={contextValue}>
      {children}
    </TemplateViewContext.Provider>
  )
}

export const useTemplateView = () => useContext(TemplateViewContext)
