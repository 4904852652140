import { LinkIcon } from '@chakra-ui/icons'
import {
  Button, HStack, Input,
  Spinner,
  Stack, Text, VStack,
} from '@chakra-ui/react'
import {
  Claim, colors, LinkClaimArgs,
} from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import { PopUpMessageContext } from '../../../../../contexts/PopUpMessage/PopUpMessageContext'
import { linkClaim } from '../../../../../hooks/backend/claims'

// provide assessment id for new claim, claim ID for existing claim
export const LinkAbilityClaim = ({
  claimId,
  assessmentId,
  claim,
  isRelink,
  alwaysOpen,
  onCancel,
  onComplete,
}: {
  claimId: string | null
  assessmentId: string | null
  claim?: Claim
  onCancel?: () => void
  alwaysOpen?: boolean
  onComplete?: () => void
  isRelink?: boolean
}) => {
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false)
  const [linking, setLinking] = useState(!!alwaysOpen)
  const { showSuccess, showError } = useContext(PopUpMessageContext)

  const onSubmit = useCallback(() => {
    if (!claimId && !assessmentId) {
      showError('no claim or assessment ID provided')
      return
    }
    if (claimId && assessmentId) {
      showError('both claim and assessment ID provided')
      return
    }
    if (!id) {
      showError("Enter the claim ID from ability's system")
      return
    }
    setLoading(true)
    linkClaim({ dbId: claimId, abilityId: id, assessmentId } as LinkClaimArgs)
      .then(() => {
        setLoading(false)
        if (onComplete) onComplete()
        showSuccess('Linked!')
      })
      .catch((err: any) => {
        setLoading(false)
        console.error(err)
        showError(
          err.message === 'Not found'
            ? "Couldn't find that Inovalon claim"
            : 'Internal error',
        )
      })
  }, [id, claimId, showError, showSuccess, onComplete, assessmentId])
  // if (claim?.submittedToAbility) {
  //   return (
  //     <HStack p={2}>
  //       <Spinner color={colors.green.hex} />
  //       <Text>Claim is being validated by Inovalon</Text>
  //     </HStack>
  //   )
  // }
  return (
    <VStack align="flex-start" w="100%">
      {claim?.submittedToAbility ? (
        <HStack p={2}>
          <Spinner color={colors.green.hex} />
          <Text>Claim is being validated by Inovalon</Text>
        </HStack>
      ) : null}
      <Text fontWeight={600} fontSize="sm" color="gray.500">
        {isRelink ? 'RE-LINK TO ABILITY' : 'LINK TO ABILITY'}
      </Text>
      <Stack width={isRelink ? 'auto' : '100%'} direction="row">
        <Button
          disabled={loading}
          _hover={{ bg: '#cedf40' }}
          onClick={() => {
            if (isRelink) {
              if (onCancel) onCancel()
            } else setLinking(!linking)
          }}
          bg={isRelink || linking ? '#ababab' : '#afbd20'}
          color="white"
          px={isRelink ? 2 : 4}
          fontSize="sm"
          size="sm"
        >
          {linking || isRelink ? (
            'CANCEL'
          ) : (
            <>
              <LinkIcon mr={isRelink ? 0 : 2} />
              {isRelink ? null : 'LINK CLAIM'}
            </>
          )}
        </Button>
        <Stack
          flex={1}
          direction="row"
          opacity={isRelink || linking ? 1 : 0}
          pointerEvents={isRelink || linking ? 'auto' : 'none'}
        >
          <Input
            disabled={(!linking && !isRelink) || loading}
            placeholder="Inovalon claim ID"
            size="sm"
            variant="outline"
            value={id}
            onChange={({ target: { value } }) => setId(value)}
          />
          <Button
            onClick={onSubmit}
            isLoading={loading}
            px={4}
            fontSize="sm"
            size="sm"
            bg="#afbd20"
            color="white"
          >
            {isRelink || claim?.submittedToAbility ? 'RE-LINK' : 'LINK'}
          </Button>
        </Stack>
      </Stack>
    </VStack>
  )
}
