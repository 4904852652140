import {
  Button,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'

import React, { useContext, useMemo } from 'react'
import { ScreenContext, UserContext } from '../../../../contexts'
import deliveryIcon from '../../../../icons/breastfeeding_green.svg'
import { ReportDeliveryContent } from './ReportDeliveryContent'

// const getDeliveryComplete = (delivery: Partial<Delivery>) => {
//   const { newbornFname, newbornLname, newbornWeight, newbornSex, deliveryTypes, location, locationOther, summary } = delivery
// }

export const ReportDeliveryPopover = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isMobile } = useContext(ScreenContext)
  const { deliveredOn, delivery } = selectedAssessment || {}
  const text = useMemo(() => {
    if (deliveredOn && deliveredOn !== Infinity) return 'DELIVERED'
    if (delivery?.isTransfer) return 'TRANSFER'
    return 'DELIVERY / TRANSFER'
  }, [deliveredOn, delivery])
  const hasData = useMemo(() => !!deliveredOn || !!delivery?.isTransfer, [deliveredOn, delivery])
  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      isLazy
      placement={isMobile ? 'bottom' : 'left-start'}
      strategy="fixed"
    >
      <PopoverTrigger>
        <Flex w="auto">
          <Tooltip
            hasArrow
            placement="bottom"
            label={
              deliveredOn
                ? 'Click to view / update'
                : 'Click to report delivery'
            }
          >
            <Button
              size={isMobile ? 'xs' : 'sm'}
              fontWeight={300}
              w={hasData ? '140px' : '200px'}
              fontFamily="hero-new"
              bg={hasData ? colors.green.hex : 'gray.50'}
              color="gray.800"
              _hover={{ bg: hasData ? 'gray.300' : 'green.300' }}
            >
              <HStack spacing={0}>
                {hasData ? (
                  <Image
                    width="21px"
                    src={deliveryIcon}
                    opacity={hasData ? 1 : 0.5}
                    filter={
                      hasData ? 'grayscale(100%) brightness(200%)' : 'none'
                    }
                  />
                ) : null}
                <Text
                  height="14px"
                  fontFamily="Comfortaa"
                  px={1}
                  fontSize={isMobile ? 'xs' : 'sm'}
                  fontWeight={600}
                  color={hasData ? 'gray.50' : colors.green.hex}
                >
                  {text}
                </Text>
              </HStack>
            </Button>
          </Tooltip>
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        h="auto"
        maxH="400px"
        maxW="600px"
        minW='350px'
        w="auto"
        borderRadius={8}
        overflowY="auto"
        overflowX="hidden"
      >
        {isOpen ? <ReportDeliveryContent onClose={onClose} /> : null}
        <PopoverArrow bg={colors.green.hex} />
      </PopoverContent>
    </Popover>
  )
}
