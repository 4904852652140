import { FlexProps } from '@chakra-ui/react'
import { DocumentData } from 'firebase/firestore'
import { CSSProperties, FC } from 'react'
import { type LocalCollectionState } from '../../collections/types'
import { SortKey } from '../common'
import { UpdateCallback } from '../firebase/shared'
import { FieldMap } from '../forms/fields'
import { UserRoleItem } from '../users/role'
import { FieldMapValue, WithId } from '../utils'
import { AppData } from './app'
import { AuthData } from './auth'
import { type CollectionFilter, type UseCollectionArgs } from './populated'

export interface DataListProps<Snippet extends DocumentData> {
  selectUser: (userId: string) => void
  sortBy: (k: SortKey<Snippet>) => void
  items?: Record<string, Snippet> | null
  active: boolean
}

export type Preview = {
  id: string
  item: string
  position: { x: number; y: number }
  cell: { rowIndex: number; columnIndex: number }
}

export type BasePresetFilter = {
  label: string
  filter: CollectionFilter
}

export type DropdownPresetFilter = {
  label: string
  filters: BasePresetFilter[]
  searchable?: boolean
  filter?: never
}

export type PresetFilter = BasePresetFilter | DropdownPresetFilter
export const isDropdownPresetFilter = (
  filter: PresetFilter,
): filter is DropdownPresetFilter => (filter as DropdownPresetFilter).filter === undefined

export interface GridChildComponentProps<T = any> {
  columnIndex: number
  rowIndex: number
  style: CSSProperties
  data: T
  isScrolling?: boolean | undefined
}
export type UserCellComponentProps<
  Snippet extends DocumentData = DocumentData,
> = {
  items: Array<Snippet | ListDividerItem>
  auth: AuthData
  tab: DataListTab<Snippet>
  preview: {
    openPreview: (p: Preview) => void
    closePreview: () => void
    preview: Preview | null
  }
  onRowClick?: (id: string) => void
  app: AppData
  tabName: string
  searchQuery: string
  columns: Record<string, DataColumn<Snippet>>
  admins: LocalCollectionState<UserRoleItem>
  flexColWidth: number
  listWidth: number
  listHeight: number
}

export type UserRowComponentProps<
  Snippet extends DocumentData = DocumentData,
> = {
  items: Snippet[]
  auth: AuthData
  tab: DataListTab<Snippet>
  preview: {
    openPreview: (p: Preview) => void
    closePreview: () => void
    preview: Preview | null
  }
  app: AppData
  tabName: string
  admins: LocalCollectionState<UserRoleItem>
  searchQuery: string
  onRowClick?: (id: string) => void
  blockColumns: Record<string, DataColumn<Snippet>>
  inlineColumns: Record<string, DataColumn<Snippet>>
  flexColWidth: number
}

export type DataListTab<Data extends DocumentData> = Pick<
  UseCollectionArgs<Data>,
  'role' | 'stage' | 'archived' | 'filters'
> &
  Pick<
    DataTableProps<Data>,
    | 'columns'
    | 'defaultSortKey'
    | 'presetFilters'
    | 'getItemBackgroundColor'
    | 'secondarySortKey'
    | 'searchStringPath'
  > & {
    collection: string
    transformData?: DataTableProps<Data>['transformData']
    rightJustifyTab?: boolean
    itemName: string
    itemHeight?: number
    creation?: {
      field: FieldMap
      itemName?: string
      initialData?: Partial<FieldMapValue> | (() => Partial<FieldMapValue>)
      submitText?: string
      onCreate: (data: Partial<FieldMapValue>, appData: AppData) => Promise<UpdateCallback>
    }
  }

export type TransformData<InData, OutData> = (data: InData) => OutData
export type DataTableProps<
  Snippet extends DocumentData,
> = DataListProps<Snippet> & {
  columns: Record<string, DataColumn<Snippet>>
  // renderCell?: FC<GridChildComponentProps<Data[]>>,
  transformData?: TransformData<Snippet[], Snippet[]>
  presetFilters?: Array<PresetFilter>
  tab: DataListTab<Snippet>
  onRowClick?: (id: string) => void
  defaultSortKey: SortKey<Snippet>
  secondarySortKey?: SortKey<Snippet>
  width: number
  height: number
  searchStringPath?: keyof Snippet
  loading: boolean
  itemHeight?: number
  tabName: string
  admins: LocalCollectionState<UserRoleItem>
  sortKey: string
  numRows: number
  getItemBackgroundColor?: (item: Snippet, index: number) => string
  collection: string
  sortAsc: boolean
  toggleSortDirection: () => void
  // filtered: Record<string, UserRoleItem> | null
}

export type UserDataTableProps = DataTableProps<UserRoleItem>

export type DataColumnRenderProps<Snippet extends DocumentData> = {
  data: Snippet
  auth: AuthData
  app: AppData
  preview: {
    openPreview: (p: Preview) => void
    closePreview: () => void
    preview: Preview | null
  }
  tabName: string
  isScrolling?: boolean
  searchQuery: string
  admins: LocalCollectionState<UserRoleItem>
  cell: { rowIndex: number; columnIndex: number }
}

export type ListMonthDivider = {
  _type: 'divider',
  type: 'month',
  propPath: string
}

export type ListDividerItem = {
  _type: 'divider',
  label: string
}

// eslint-disable-next-line no-underscore-dangle
export const isListDividerItem = (item: WithId<any> | ListDividerItem): item is ListDividerItem => item._type === 'divider'

export type ListDivider = ListMonthDivider
export type ListDividerType = ListDivider['type']

export interface DataColumn<Snippet extends DocumentData, BaseType extends Snippet = Snippet> {
  isLazy?: boolean
  Header: FC
  title: string
  width?: number
  Render: FC<DataColumnRenderProps<WithId<BaseType>>>
  dividers?: ListMonthDivider[]
  mobile?: {
    width: number
    flexProps?: FlexProps
    Render: FC<DataColumnRenderProps<WithId<BaseType>>>
  }
  flexProps?: FlexProps
  sortKey?: keyof DocumentData & string
  defaultSortDirection?: 'asc' | 'desc'
  sortFunc?: (a: WithId<BaseType>, b: WithId<BaseType>) => number
}
// Helper type that extracts the arguments of a function

export type DataColumns<Data extends DocumentData> = {
  [Key: string]: DataColumn<any, Data>;
};
