import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DragHandleIcon,
  EditIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Collapse,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  defaultStageValidate,
  DocData,
  FieldMap,
  WithId,
} from '@hb/shared'
import { FORM_ERROR, ValidationErrors } from 'final-form'
import {
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  setDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import React, {
  FC, useCallback, useMemo, useState,
} from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd'
import { db } from '../../../backend'
import { useApp } from '../../../contexts'
import { addMetadata } from '../../../utils'
import { DeleteButton } from '../../Buttons/DeleteButton'
import { TooltipIconButton } from '../../Buttons/TooltipIconButton'
import { reorder } from '../../utils'
import { SimpleForm } from './SimpleForm'

const ListItemEdit = <T extends {}>({
  value,
  onDelete,
  isDragging,
  onUpdate,
  field,
  expandedHeight,
  getBackgroundImageUrl,
  noItemEdit,
  index,
  itemName,
  getTitle,
  Preview,
  dragHandleProps,
}: {
  value: WithId<T>
  index: number
  isNew?: boolean
  onDelete: () => Promise<void>
  expandedHeight?: number
  itemName: string
  noItemEdit?: boolean
  field: FieldMap
  isDragging: boolean
  onUpdate: (newValue: Partial<WithId<T>>) => Promise<ValidationErrors>
  getBackgroundImageUrl?: (v: WithId<T>) => string | undefined
  getTitle?: (v: WithId<T>) => string
  Preview: FC<{ value: WithId<T> }>
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}) => {
  const [expanded, setExpanded] = useState(false)
  const [editing, setIsEditing] = useState(false)
  const height = useMemo(
    () => (expanded ? `${expandedHeight}px` : 'auto'),
    [expanded, expandedHeight],
  )
  const backgroundImage = useMemo(() => {
    if (!getBackgroundImageUrl) return undefined
    const url = getBackgroundImageUrl(value)
    return url ? `url(${url})` : undefined
  }, [value, getBackgroundImageUrl])
  const title = useMemo(
    () => (getTitle ? getTitle(value) : `${itemName} ${index + 1}`),
    [value, getTitle, itemName, index],
  )
  return (
    <Box
      border="1px solid #cdcdcd"
      borderRadius={6}
      bg="gray.50"
      userSelect="none"
      backgroundImage={backgroundImage}
      backgroundSize="cover"
      backgroundPosition="center"
      boxShadow={`1px 1px 4px ${isDragging ? '#00000033' : '#00000000'}`}
      w="100%"
    >
      <Collapse unmountOnExit in={!editing} style={{ width: '100%' }}>
        <VStack
          transition="height 300ms"
          height={height}
          spacing={0}
          align="flex-start"
          w="100%"
        >
          <HStack bg="whiteAlpha.400" px={1} py={1} w="100%">
            <Text
              pl={2}
              color="gray.600"
              fontFamily="Hero-New"
              fontSize="lg"
              py={1}
            >
              {title}
            </Text>
            <HStack spacing={1} ml="auto">
              {expandedHeight ? (
                <TooltipIconButton
                  label={expanded ? 'COLLAPSE' : 'EXPAND'}
                  size="xs"
                  bg="transparent"
                  onClick={() => setExpanded(!expanded)}
                  icon={
                    <VStack spacing={0}>
                      <ChevronUpIcon
                        transition="transform 300ms"
                        transform={`translate(0, 4px) rotate(${expanded ? 180 : 0}deg)`}
                        width={4}
                        height={4}
                      />
                      <ChevronDownIcon
                        transition="transform 300ms"
                        transform={`translate(0, -4px)  rotate(${expanded ? -180 : 0}deg)`}
                        width={4}
                        height={4}
                      />
                    </VStack>
                  }
                />
              ) : null}
              {noItemEdit ? null : (
                <TooltipIconButton
                  label="EDIT"
                  onClick={() => setIsEditing(true)}
                  size="xs"
                  bg="transparent"
                  icon={<EditIcon w={4} />}
                />
              )}
              {dragHandleProps ? (
                <IconButton
                  aria-label="Reorder"
                  boxShadow="1px 1px 4px #00000044"
                  bg="transparent"
                  size="xs"
                  icon={<DragHandleIcon w={3} />}
                  {...dragHandleProps}
                />
              ) : null}
            </HStack>
          </HStack>
          <Preview value={value} />
          <HStack px={2} mt="auto" justify="flex-end" pb={2} w="100%">
            <DeleteButton size="xs" itemName={itemName} onDelete={onDelete} />
          </HStack>
        </VStack>
      </Collapse>
      <Collapse unmountOnExit in={!!editing} style={{ width: '100%' }}>
        <Box>
          <SimpleForm
            onCancel={() => setIsEditing(false)}
            field={field}
            theme="detailed"
            onSubmit={(v) => onUpdate(v as Partial<WithId<T>>).then((res) => {
              if (res) return res
              setIsEditing(false)
              return undefined
            })
            }
            value={value}
          />
        </Box>
      </Collapse>
    </Box>
  )
}

type ListEditProps<T extends DocData> = {
  getItemBackgroundUrl?: (v: WithId<T>) => string | undefined
  itemName: string
  getItemTitle?: (v: WithId<T>) => string
  ItemPreview: FC<{ value: WithId<T> }>
  expandedItemHeight?: number
  newItemField?: FieldMap
  itemField: FieldMap
  onNewItemClick?: () => void
}

type CollectionListEditProps<T extends DocData> = ListEditProps<T> & {
  listName: string
  collectionRef: CollectionReference<T>
  value: Array<WithId<T>>
  noItemEdit?: boolean
  orderProp?: string
}

type InnerListEditProps<T extends DocData> = ListEditProps<T> & {
  propertyPath: string
  value: Array<WithId<T>>
  title: string
  noItemEdit?: boolean
  onUpdateItem: (
    id: string,
    newValue: Partial<WithId<T>>
  ) => Promise<ValidationErrors>
  onDeleteItem: (id: string) => Promise<void>
  onAddItem: (newVal: T) => Promise<ValidationErrors>
}

const InnerListEdit = <T extends DocData>({
  value,
  propertyPath,
  itemField,
  expandedItemHeight,
  ItemPreview,
  getItemTitle,
  onDeleteItem,
  onUpdateItem,
  noItemEdit,
  onAddItem,
  getItemBackgroundUrl,
  onNewItemClick,
  itemName,
  title,
}: InnerListEditProps<T>) => {
  const [creatingNew, setCreatingNew] = useState<boolean>(false)

  const newItemId = useMemo(
    () => (creatingNew ? `${Date.now()}` : undefined),
    [creatingNew],
  )

  return (
    <VStack flex={1} bg="gray.100" spacing={0} borderRadius={6}>
      <HStack
        bg="white"
        borderTopRadius={6}
        px={2}
        py={1}
        w="100%"
        borderBottom="1px solid #777"
      >
        <Text
          fontFamily="Hero-New"
          px={3}
          pb={1}
          pt={2}
          color="gray.600"
          fontWeight="bold"
        >
          {title}
        </Text>
        <HStack ml="auto">
          <Tooltip
            placement="top"
            hasArrow
            label={`CREATE ${itemName.toUpperCase()}`}
          >
            <IconButton
              color="gray.600"
              onClick={() => {
                if (onNewItemClick) onNewItemClick()
                else setCreatingNew(true)
              }}
              size="sm"
              variant="ghost"
              aria-label="add"
              icon={<AddIcon />}
            />
          </Tooltip>
        </HStack>
      </HStack>
      <Collapse style={{ width: '100%' }} in={!creatingNew}>
        <Droppable droppableId={propertyPath}>
          {(provided, snapshot) => (
            <VStack
              p={2}
              boxShadow={`1px 1px ${snapshot.isDraggingOver ? 4 : 2}px 0px rgba(0,0,0,0.2)`}
              ref={provided.innerRef}
              w="100%"
            >
              {value?.length ? (
                value.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <Box
                        width="100%"
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                      >
                        <ListItemEdit
                          Preview={ItemPreview}
                          itemName={itemName}
                          field={itemField}
                          expandedHeight={expandedItemHeight}
                          getTitle={getItemTitle}
                          noItemEdit={noItemEdit}
                          getBackgroundImageUrl={getItemBackgroundUrl}
                          index={index}
                          onDelete={() => onDeleteItem(item.id)}
                          dragHandleProps={draggableProvided.dragHandleProps}
                          onUpdate={async (newValue) => onUpdateItem(item.id, newValue)
                          }
                          isDragging={draggableSnapshot.isDragging}
                          value={item}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))
              ) : (
                <Text p={2} fontSize="md" color="gray.500">
                  {snapshot.isDraggingOver ? '' : `No ${itemName}s`}
                </Text>
              )}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </Collapse>
      <Collapse style={{ width: '100%' }} unmountOnExit in={!!creatingNew}>
        <Box bg="gray.500">
          <SimpleForm
            field={itemField}
            onCancel={() => setCreatingNew(false)}
            onSubmit={async (newVal) => {
              const validate = itemField.validate || defaultStageValidate(itemField, true)
              const validationErrors = validate(newVal)
              if (validationErrors) return validationErrors
              try {
                await onAddItem({ ...newVal, id: newItemId })
              } catch (err: any) {
                console.error(err)
              }
              setCreatingNew(false)
              return undefined
            }}
          />
        </Box>
      </Collapse>
    </VStack>
  )
}

export const CollectionListEdit = <T extends DocData>({
  collectionRef,
  orderProp = 'rank',
  listName,
  value,
  noItemEdit,
  ...props
}: CollectionListEditProps<T>) => {
  const { itemField } = props

  const sortedByOrder = useMemo(
    () => value.sort((a, b) => a[orderProp] - b[orderProp]),
    [value, orderProp],
  )

  const handleUpdateItem = useCallback(
    async (id: string, newValue: Partial<WithId<T>>) => {
      const validate = itemField.validate || defaultStageValidate(itemField, true)
      const validationErrors = validate(newValue)
      if (validationErrors) return validationErrors
      try {
        const updatedDoc = doc(collectionRef, id)
        await updateDoc<T, DocumentData>(updatedDoc, newValue)
        return undefined
      } catch (e: any) {
        console.error(e)
        return { [FORM_ERROR]: e.message }
      }
    },
    [itemField, collectionRef],
  )
  const handleDeleteItem = useCallback(
    async (id: string) => {
      const deletedDoc = doc(collectionRef, id)
      await deleteDoc(deletedDoc)
    },
    [collectionRef],
  )

  const { appName } = useApp()

  const handleDragEnd: OnDragEndResponder = useCallback(
    async (result) => {
      const { source, destination } = result
      // dropped outside the list
      if (!destination) return
      if (!source) return

      if (source.droppableId === destination.droppableId) {
        const sourceIndex = source.index
        const destinationIndex = destination.index
        const sourceId = sortedByOrder?.[sourceIndex]?.id
        const destinationId = sortedByOrder?.[destinationIndex]?.id
        if (!sourceId || !destinationId) return
        const reordered = reorder(value, result)
        const batch = writeBatch(db)
        reordered.forEach((item, idx) => {
          const originalIndex = value.findIndex((v) => v.id === item.id)
          if (originalIndex !== idx) {
            const docRef = doc(collectionRef, item.id)
            batch.update(docRef, { [orderProp]: idx })
          }
        })
        await batch.commit()
      }
    },
    [sortedByOrder, collectionRef, orderProp, value],
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <HStack align="flex-start" p={4} w="100%">
        <InnerListEdit<T>
          {...props}
          propertyPath={orderProp}
          value={sortedByOrder}
          noItemEdit={noItemEdit}
          title={capitalizeFirstLetter(listName)}
          onAddItem={async (newVal) => setDoc(doc(collectionRef), addMetadata(newVal, appName, true))
            .then(() => undefined)
            .catch((err: any) => ({ [FORM_ERROR]: err.message }))
          }
          onDeleteItem={handleDeleteItem}
          onUpdateItem={handleUpdateItem}
        />
      </HStack>
    </DragDropContext>
  )
}
