import { Flex, Text } from '@chakra-ui/react'
import { ProviderAuthentication } from '@hb/shared/types'
import { getFullName } from '@hb/shared/utils'
import React from 'react'
import { useCachedPractice } from '../../../../collections/hooks/cached'
import { ActionLog } from '../../../ActionLog'
import { DataLabel } from '../../../forms/Input/DataLabel'

export const AuthenticationView = ({
  authentication,
}: {
  authentication: ProviderAuthentication
}) => {
  const { data: practice } = useCachedPractice(authentication.practiceId)

  return (
  <Flex w='100%' p={1}>
    <Flex gap={1} bg='white' w='100%' boxShadow='2px 2px 5px #00000055' borderRadius={4} flexFlow="column" py={2} px={3}>
      <DataLabel lineHeight={1}>Provider Authentication</DataLabel>
      <ActionLog action='Authenticated' by={authentication.userId} on={authentication.authenticatedOn} />
      <Text fontSize='sm' color='gray.700' >Supervising provider: {getFullName(practice)}</Text>
    </Flex>
  </Flex>
  )
}
