import * as React from 'react'

import { Text } from '@chakra-ui/react'
import {
  DropdownField,
  DropdownOptionItem,
  DynamicDropdownField, FieldTypes,
  InputElement,
} from '@hb/shared'
import { forwardRef, useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { DropdownInput } from '../Select/DropdownInput'
import { useDynamicDropdownOptions } from './hooks'

const DynamicDropdown: InputElement<DynamicDropdownField> = forwardRef((props, ref) => {
  const { input, field, style } = props
  const { notApplicableText } = field || {}

  const { onChange, name } = input
  const { values } = useFormState()

  const options = useDynamicDropdownOptions({
    field: field as DynamicDropdownField,
    name,
    rootValue: values,
  })
  const handleChange = React.useCallback(
    (id?: string) => {
      if (id === 'other') {
        onChange({ target: { value: 'other' } })
        return
      }
      const match = options?.find((i: DropdownOptionItem) => i.id === id)
      if (match) {
        onChange({ target: { value: match.id } })
      } else {
        onChange({ target: { value: undefined } })
      }
    },
    [onChange, options],
  )
  const dropdownField = useMemo<DropdownField>(() => ({
    ...field,
    type: FieldTypes.DROPDOWN,
    options,
  }), [field, options])
  return options && options.length ? (
    <DropdownInput
      ref={ref}
      {...props}
      style={style}
      field={dropdownField}
      input={{
        ...input,
        onChange: (e: any) => handleChange(e),
      }}
    />
  ) : (
    <Text
      px={4}
      py={2}
      bg='white'
      borderRadius='6px'
      boxShadow='0 0 2px #333'
      color='gray.400'
      fontSize='sm'
      fontStyle='italic'
    >
      {notApplicableText || 'N/A'}
    </Text>
  )
})

export default DynamicDropdown
