import { Box, ButtonProps } from '@chakra-ui/react'
import { FieldTypes, UpdateCallback } from '@hb/shared'
import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { ThemeContext } from '../contexts/ThemeContext'
import { ActionButton } from './Buttons/ActionButton'
import { Container } from './Container'
import { FormElement } from './forms'

export const AddItem: React.FC<{
  onAdd: (value: string) => Promise<UpdateCallback>
  loading?: boolean
  itemName?: string
  placeholder?: string
  buttonProps?: ButtonProps
}> = ({
  onAdd, itemName = 'Item', placeholder, buttonProps,
}) => {
  const [active, setActive] = useState(false)
  const [value, setValue] = useState<string>('')
  const [addLoading, setAddLoading] = useState(false)

  const onButtonClick = useCallback(async () => {
    if (active && value) {
      setAddLoading(true)
      await onAdd(value)
      setAddLoading(false)
      setActive(false)
      setValue('')
    } else {
      setActive(true)
    }
  }, [active, value, onAdd])

  const onCancel = useCallback(() => {
    setActive(false)
    setValue('')
  }, [])

  const onSubmit = useCallback(({ name }: { name: string }) => {
    onAdd(name)
    setActive(false)
    setValue('')
    return undefined
  }, [onAdd])

  return (
    <ThemeContext.Provider value={{ theme: 'basic' }}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Container
            style={{
              height: 'auto',
              padding: '0 0.5rem',
              flexFlow: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <Container
              style={{
                height: 'auto',
                width: active ? 150 : 0,
                overflow: 'hidden',
                transition: 'width 500ms',
              }}
            >
              <Box px='0.1rem' mr={1} borderRadius={4} bg="white">
                <FormElement
                  name="name"
                  field={{
                    type: FieldTypes.TEXT,
                    placeholder: placeholder || `${itemName} Name`,
                    //  optional: true,
                  }}
                />
              </Box>
            </Container>
            <Container
              style={{
                height: 'auto',
                width: 'auto',
                alignItems: 'center',
              }}
            >
              <ActionButton
                colorScheme="gray"
                mt={0}
                // ml={2}
                size="xs"
                bg="whiteAlpha.700"
                onClick={active ? handleSubmit : onButtonClick}
                isLoading={addLoading}
                {...buttonProps}
              >
                {`+ Add${active ? '' : ` ${itemName}`}`}
              </ActionButton>
            </Container>
            <Container style={{ width: 10, opacity: 0 }} />
            <Container
              style={{
                height: 'auto',
                // overflow: 'hidden',
                opacity: active ? 1 : 0,
                width: active ? 70 : 0,
                padding: '5px 0',
                alignItems: 'center',
                transition: 'all 333ms',
              }}
            >
              <ActionButton
                colorScheme="gray"
                mt={0}
                size="xs"
                onClick={onCancel}
              >
                Cancel
              </ActionButton>
            </Container>
            {/* <LoadingOverlay loading={loading} /> */}
          </Container>
        )}
      </Form>
    </ThemeContext.Provider>
  )
}
