import {
  Text, VStack,
} from '@chakra-ui/react'

import React from 'react'

export const ClaimInfo = ({
  header,
  children,
}: {
  header: string
  children: React.ReactNode
}) => (
  <VStack flex={1} p={1} pr={3} align="flex-start" spacing={0}>
    <Text isTruncated color="gray.500" fontSize="xs" fontWeight={600}>
      {header}
    </Text>
    {children}
  </VStack>
)
