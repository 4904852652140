import {
  InsuranceCoverage,
  PopulatedAssessment, ShortcutArgs, User,
} from '../../types'

export const getShortcutArgs = (
  user?: User | null,
  assessment?: PopulatedAssessment,
  selectedPlan?: InsuranceCoverage | null,
): ShortcutArgs => {
  const midwife = assessment?.midwife
  const prm = assessment?.payments?.prm || midwife?.prm
  const retainer = Object.values(assessment?.payments?.due || {}).filter(
    (p) => p.type === 'retainer',
  )[0]?.amount || assessment?.midwife?.retainer
  const deposits = Object.values(assessment?.payments?.due || {}).filter(
    (p) => p.type === 'deposit',
  )

  return {
    user: user || undefined,
    assessment,
    plan: selectedPlan,
    prm,
    retainer,
    deposits,
    practice: assessment?.midwife,
  }
}
