import { getValidUrl, splitTextLines, splitTextParts } from '@hb/shared'
import React, { useMemo } from 'react'
import { SiteLink } from '../SiteLink'

export const ParsedText = ({ children }: { children: string }) => {
  const parsed = useMemo(() => {
    // split by all white space
    // const parts = children.split(' ')
    const lines = splitTextLines(children)
    return lines.reduce(
      (lineAcc, currLine) => [
        ...lineAcc,
        ...splitTextParts(currLine).reduce(
          (acc, part, i) => {
            if (part === ' ') return acc
            const validUrl = getValidUrl(part)
            if (validUrl) {
              const siteLink = <SiteLink key={part} href={validUrl} />
              const added = currLine.length > 1 && i === currLine.length - 1
                ? [siteLink]
                : [siteLink, ' ']
              return [...acc, ...added]
            }

            return [
              ...acc,
              currLine.length > 1 && i === currLine.length - 1
                ? part
                : `${part} `,
            ]
          },
          [] as Array<string | React.JSX.Element>,
        ),
        '\n',
      ],
      [] as Array<string | React.JSX.Element>,
    )
  }, [children])
  return <span>{parsed}</span>
}
