import {
  Template,
  TemplateKey,
  templateKeyToCollection,
  UpdateCallback,
} from '@hb/shared'
import { collection, doc, setDoc } from 'firebase/firestore'
import React, {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react'
import { db } from '../../../backend/db'
import { useApp } from '../../../contexts/AppContext'
import { useDocument } from '../../../hooks/backend/useDocument'
import { addMetadata } from '../../../utils/data'

import { TemplateEditorProvider } from './editor'
import { TemplatePracticeDataProvider, useTemplatePractice } from './practice'

const useAssessmentTemplateArgs = () => {
  const { practice } = useTemplatePractice()
  return useMemo(() => ({ practice }), [practice])
}

const TemplateEditorProviderBody = ({
  children,
  onSubmit,
  template,
  templateType,
  isLoading,
}: PropsWithChildren<{
  onSubmit: ComponentProps<typeof TemplateEditorProvider>['onSubmit']
  template: Template | null
  templateType: TemplateKey
  isLoading: boolean
}>) => {
  const shortcutArgs = useAssessmentTemplateArgs()
  return (
    <TemplateEditorProvider
      template={template}
      onSubmit={onSubmit}
      isLoading={isLoading}
      type={templateType}
      shortcutArgs={shortcutArgs}
    >
      {children}
    </TemplateEditorProvider>
  )
}

export const DocumentTemplateProvider = ({
  children,
  templateType,
  templateId,
}: PropsWithChildren<{
  templateType: TemplateKey
  templateId: string
}>) => {
  const { appName } = useApp()

  const templateCollectionPath = useMemo(
    () => templateKeyToCollection[templateType],
    [templateType],
  )
  const { data: template, loading: isLoading } = useDocument<Template>(
    templateCollectionPath,
    templateId,
  )

  const onSubmit = useCallback(
    async (updated: Template): Promise<UpdateCallback> => {
      const templateCollectionRef = collection(db, templateCollectionPath)
      const submitted = addMetadata(updated, appName, false)
      try {
        await setDoc(doc(templateCollectionRef, templateId), submitted, {
          merge: true,
        })
        return { success: 'Updated template!' }
      } catch (err: any) {
        console.error(err)
        return { error: err?.message || 'Error saving template' }
      }
    },
    [appName, templateId, templateCollectionPath],
  )
  return (
    <TemplatePracticeDataProvider>
      <TemplateEditorProviderBody
        templateType={templateType}
        isLoading={isLoading}
        onSubmit={onSubmit}
        template={template}
      >
        {children}
      </TemplateEditorProviderBody>
    </TemplatePracticeDataProvider>
  )
}
