import { CalendarIcon } from '@chakra-ui/icons'
import {
  Flex, FlexProps, HStack, Text,
} from '@chakra-ui/react'
import {
  ASSESSMENTS_ADMIN,
  Claim,
  colors,
  FieldTypes,
  getCoverageLabel,
  getCoverageText,
  getDateString,
  InsuranceCoverage,
  InsuranceCoverageId,
  ListItem,
  NextAction,
  parseNextActionDate,
  providersCollection,
  TextAreaField,
  UpdateCallback,
} from '@hb/shared'

import {
  deleteField,
  doc,
  DocumentReference,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import React, {
  FC, useCallback, useMemo, useState,
} from 'react'
import { db } from '../../../backend'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'
import { useMe } from '../../../hooks/backend/useMe'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { Editable } from '../../forms/Input/index'

const nextActionField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Next action',
  optional: true,
}

const NextActionContent = ({
  nextAction,
  onSubmit,
  flexProps,
}: {
  nextAction: NextAction | undefined
  onSubmit: (updated: string) => Promise<UpdateCallback>
  flexProps?: FlexProps
}) => {
  const [isEditing, setIsEditing] = useState(false)
  return (
    <Flex align="flex-start" minW="0" flex={1} {...flexProps}>
      <Editable
        onSubmit={onSubmit}
        value={nextAction?.text}
        editableStackProps={{ bg: 'transparent', boxShadow: 'none' }}
        openCallback={() => setIsEditing(true)}
        closeCallback={() => setIsEditing(false)}
        dataCellProps={{
          fontSize: 'sm',
          borderRadius: '4px',
          border: '1px solid #cdcdcd',
          background: 'white',
          px: 2,
        }}
        style={{
          flex: 1,
          minWidth: 0,
        }}
        field={nextActionField}
      />
      {nextAction?.updatedOn && !isEditing ? (
        <HStack px={1} py={2} spacing={1}>
          <CalendarIcon width={3} color="gray.600" />
          <Text fontWeight={500} color="gray.600" fontSize="xs">
            {getDateString(nextAction.updatedOn, 'short', false)}
          </Text>
        </HStack>
      ) : null}
    </Flex>
  )
}

export const CollectionNextActionContent: FC<{
  collection: string
  id?: string
  item?: ListItem
}> = ({ item, collection, id }) => {
  // const { nextAction } = item || {}
  const { nextAction, nextActionText, nextActionDate } = (item as {
      nextAction?: NextAction
      nextActionText?: string
      nextActionDate?: number
    }) || {}
  const displayedNextAction = useMemo<NextAction>(
    () => nextAction || {
      text: nextActionText || '',
      date: nextActionDate || 0,
    },
    [nextAction, nextActionText, nextActionDate],
  )

  // const nextAction = item && 'nextAction' in item ? item.nextAction : {}
  const update = useUpdateDoc()
  const ref = useMemo(
    () => (id ? (doc(db, collection, id) as DocumentReference<ListItem>) : null),
    [collection, id],
  )
  const getNextAction = useGetNextAction()
  const handleSubmit = useCallback(
    async (updatedText: string | null): Promise<UpdateCallback> => {
      if (!ref) return { error: 'Internal error' }
      const updated = getNextAction(updatedText || '')
      if (collection === 'claims') {
        const batch = writeBatch(db)
        batch.update(ref, 'nextActionText', updatedText)
        batch.update(
          ref,
          'nextActionDate',
          parseNextActionDate(updatedText || ''),
        )
        batch.update(ref, 'nextAction', updated)
        try {
          return batch
            .commit()
            .then(() => ({ success: 'Next action updated!' }))
        } catch (err: any) {
          console.error(err)
          return { error: 'Internal error' }
        }
      }
      return update(ref, 'nextAction', updated)
    },
    [update, collection, ref, getNextAction],
  )
  return (
    <NextActionContent
      nextAction={displayedNextAction}
      onSubmit={handleSubmit}
    />
  )
}

export const AssessmentNextAction = ({
  item,
  collection,
  id,
  ...props
}: FlexProps & { item?: ListItem; collection: string }) => (
  <Flex
    width="100%"
    px={1}
    bg={`${colors.pink.hex}99`}
    align="center"
    {...props}
  >
    <Text
      whiteSpace="nowrap"
      fontSize="sm"
      position="relative"
      px={2}
      color="gray.500"
      fontWeight={600}
    >
      Next Action:
    </Text>
    <CollectionNextActionContent id={id} collection={collection} item={item} />
  </Flex>
)

export const useGetNextAction = () => {
  const me = useMe()
  return useCallback(
    (text: string) => {
      if (!me) throw new Error('Not logged in')
      return {
        text,
        updatedOn: Date.now(),
        updatedBy: me.uid,
      }
    },
    [me],
  )
}

export const useSubmitCoverageNextAction = (
  assessmentId: string,
  id: InsuranceCoverageId,
) => {
  const getNextAction = useGetNextAction()
  return useCallback(
    async (updated: string): Promise<UpdateCallback> => {
      const ref = doc(db, ASSESSMENTS_ADMIN, assessmentId)
      return updateDoc(
        ref,
        `nextActions.${id}`,
        updated ? getNextAction(updated) : deleteField(),
      )
        .then(() => ({
          success: 'Next action updated!',
        }))
        .catch((err: any) => {
          console.error(err)
          return { error: err?.message || 'Internal error' }
        })
    },
    [id, assessmentId, getNextAction],
  )
}

export const CoverageNextActionContent = ({
  assessmentId,
  id,
  nextAction,
}: {
  assessmentId: string
  id: InsuranceCoverageId
  nextAction: NextAction | undefined
}) => {
  const handleSubmit = useSubmitCoverageNextAction(assessmentId, id)
  return (
    <Flex
      bg="whiteAlpha.600"
      borderTop="1px solid #00000033"
      align="flex-start"
      w="100%"
      px={2}
      minW="0"
    >
      <NextActionContent
        flexProps={{ align: 'center' }}
        nextAction={nextAction}
        onSubmit={handleSubmit}
      />
    </Flex>
  )
}

export const CoverageNextAction = ({
  assessmentId,
  id,
  coverage,
  nextAction,
}: {
  assessmentId: string
  id: InsuranceCoverageId
  coverage: InsuranceCoverage
  nextAction: NextAction | undefined
}) => {
  const handleSubmit = useSubmitCoverageNextAction(assessmentId, id)
  const label = getCoverageLabel(id, coverage)
  const { item: insuranceProvider } = useCollectionItem(providersCollection, coverage.insuranceProviderId)
  const coverageText = getCoverageText(coverage, insuranceProvider)
  return (
    <Flex
      bg="whiteAlpha.600"
      borderTop="1px solid #00000033"
      w="100%"
      direction='column'
      pb={1}
      pt={2}
      px={2}
    >
      <Text lineHeight={1} color="#777" whiteSpace="pre" fontSize="0.8rem" fontWeight={600}>
        {label.toUpperCase()} - {coverageText}
      </Text>
      <Flex w="100%">
        <NextActionContent
          nextAction={nextAction}
          onSubmit={handleSubmit}
        />
      </Flex>
    </Flex>
  )
}

const getServiceType = (claim: Claim) => claim?.serviceType || claim?.legacy?.serviceType
export const ClaimNextAction = ({
  claimId,
  claim,
  ...props
}: FlexProps & {
  claim: Claim
  claimId: string
}) => (
  <Flex bg="#efefef" align="flex-start" py={1} w="300px" minW="0" {...props}>
    <Text
      px={2}
      pt={1}
      color="#777"
      whiteSpace="pre"
      fontSize="0.8rem"
      fontWeight={600}
    >
      {getServiceType(claim)?.toString().toUpperCase() || 'NO SERVICE TYPE'}
    </Text>
    <CollectionNextActionContent
      id={claimId}
      item={claim}
      collection="claims"
    />
  </Flex>
)
