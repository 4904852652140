import {
  A4_DIMS_PIXELS,
  CustomElement,
  defaultFont,
  EditorVersion,
  FormattedText,
  isImageNode,
  isLinkNode,
  isSignatureFieldNode,
  isVariableOrFieldNode,
  Mode,
} from '@hb/shared'
import { CSSProperties, useContext, useMemo } from 'react'
import { ScreenContext } from '../../contexts/ScreenContext'
import { fontFamilyFromFontId } from './TemplateToolbar/utils'

export const useNodeStyle = (version: EditorVersion, node: FormattedText) => {
  const {
    fontSize, header, color, font, justify,
  } = node

  return useMemo(() => {
    let display = justify ? 'block' : 'inline'
    if (version === 'v2') display = 'contents'
    return {
      fontSize: `${(fontSize || 1) * 16}px`,
      fontFamily: fontFamilyFromFontId(font || defaultFont.id),
      fontWeight: header ? 600 : 400,
      minWidth: '1px',
      display,
      // display: 'inline-flex',
      color,
    }
  }, [fontSize, header, color, font, version, justify])
}

const getElementDisplayV2 = (node: CustomElement) => {
  if (isSignatureFieldNode(node)) return 'block'
  return isVariableOrFieldNode(node) || isImageNode(node) || isLinkNode(node) || node?.isInline
    ? 'inline-flex'
    : 'block'
}
const getElementDisplay = (node: CustomElement, version: EditorVersion) => {
  const justify = (node.children[0] as FormattedText)?.justify
  switch (version) {
    case 'v1':
      return justify ? 'block' : 'inline'
    case 'v2':
      return getElementDisplayV2(node)
    default:
      throw new Error('Invalid editor version')
  }
}

export const useElementStyle = (
  mode: Mode,
  node: CustomElement,
  version: EditorVersion,
): CSSProperties => useMemo(() => {
  const display = getElementDisplay(node, version)
  const justify = (node.children[0] as FormattedText)?.justify

  let cursor = 'text'
  if (node.type === 'field' || node.type === 'variable') cursor = mode === 'Edit' ? 'pointer' : 'default'
  return {
    display,
    textAlign: justify,
    alignItems: 'center',
    cursor,
    minWidth: '1px',
    flexFlow: 'row wrap',
    whiteSpace: 'pre-wrap',
  }
}, [node, version, mode])

export type EditorSize = {
  baseZoom: number
  width: number
  height: number
}

export type SubmitText = {
  submit: string
  confirm: string
}

export const useEditorWidth = (
  maxWidth?: number,
  manualHeight?: number,
): EditorSize => {
  const { width: screenWidth, height: screenHeight } = useContext(ScreenContext)
  return useMemo(() => {
    const widthFitToScreen = Math.min(screenWidth * 0.9, A4_DIMS_PIXELS[0])
    const width = maxWidth
      ? Math.min(maxWidth, widthFitToScreen)
      : widthFitToScreen
    const height = manualHeight || Math.min(screenHeight * 0.9, A4_DIMS_PIXELS[1])
    return { baseZoom: width / A4_DIMS_PIXELS[0], width, height }
  }, [screenWidth, maxWidth, screenHeight, manualHeight])
}
