import { ChevronDownIcon, LinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CircularProgress,
  Code,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image, Stack, Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  AbilityClaim,
  AbilityClaimError,
  Claim, colors, FieldMap,
  FieldMapValue,
  FieldTypes, getDateString,
  getDateTimeString,
  getInovalonClaimStatusColor, UpdateCallback,
} from '@hb/shared'
import {
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

import React, {
  useCallback,
  useContext, useMemo, useState,
} from 'react'
import { CLAIMS_REF } from '../../../../collections/collections'
import { PopUpMessageContext, UserContext } from '../../../../contexts'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { useFormattedValue } from '../../../../hooks/useFormattedValue'

import { ActionButton, DeleteButton } from '../../../Buttons'
import { FloatingContainer } from '../../../Containers'
import { DataView, GenericEditModal } from '../../../DataView'
import { Expandable } from '../../../Expandable'
import { NoteForm } from '../../../forms/NoteForm'
import { DefaultModal } from '../../../Modals/DefaultModal'
import { AssessmentDocuments } from '../Documents/AssessmentDocuments'
import { DraftView } from '../DraftView'
import { ReferenceNumbersEdit } from '../ReferenceNumberEdit'
import { AbilityClaimView } from './Ability/AbilityClaim'
import { serviceTypeField, serviceTypeOtherField } from './Ability/fields'
import { LinkAbilityClaim } from './Ability/LinkAbilityClaim'
import {
  AbilityClaimSubmission,
  ResubmitAbilityClaim,
} from './Ability/SubmitNewClaim/AddAbilityClaimModal'
import { ClaimSubmissionForm } from './Ability/SubmitNewClaim/ClaimSubmissionForm'
import { ClaimInfo } from './ClaimInfo'
import { ClaimView } from './ClaimView'
import { status } from './fields'

type ClaimsViewData = {
  selectedId: string | null
  select: (id: string) => void
  updatingAbilityId: string | null
  setUpdatingAbilityId: (id: string | null) => void
  creatingNewLegacy: boolean
  setCreatingNewLegacy: (val: boolean) => void
  creatingNewAbilityClaim: boolean
  setCreatingNewAbilityClaim: (val: boolean) => void
}

const ClaimsViewContext = React.createContext<ClaimsViewData>({
  selectedId: null,
  select: () => {},
  updatingAbilityId: null,
  setUpdatingAbilityId: () => {},
  creatingNewLegacy: false,
  setCreatingNewLegacy: () => {},
  creatingNewAbilityClaim: false,
  setCreatingNewAbilityClaim: () => {},
})

export const toClaimName = (data: FieldMapValue) => `${
  data?.serviceType?.toString().toUpperCase() || 'NO SERVICE TYPE'}${
  data.serviceType === 'other' && data.serviceTypeOther
    ? ` (${data.serviceTypeOther})`
    : ''
}: ${data.status?.toString().toUpperCase()} - ${
  data.serviceDates || 'No dates of service'
} - ${data.cptCodes || 'No cpt codes'}`

const claimField: FieldMap = {
  name: 'Claim',
  toName: toClaimName,
  children: {
    serviceType: serviceTypeField,
    serviceTypeOther: serviceTypeOtherField,
    status,
    submissionDate: {
      type: FieldTypes.DATE,
      placeholder: 'Submission date',
    },
    serviceDates: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'Date(s) of service...',
    },
    cptCodes: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'CPT Codes/Billed Charges',
    },
    // billedCharges: {
    //   optional: true,
    //   type: FieldTypes.TEXTAREA,
    //   placeholder: 'Billed charges',
    // },
    sessionNumber: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'Session #',
    },
    claimNumber: {
      optional: true,
      type: FieldTypes.TEXT,
      placeholder: 'Claim #',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
}

const AbilityClaimErrorView = ({ error }: { error: AbilityClaimError }) => {
  const { messsage, name, related_fields } = error
  const fieldName = useMemo(() => {
    const [field] = related_fields || []
    return field?.name || ''
  }, [related_fields])
  const [expanded, setExpanded] = useState(false)
  const statusColor = error.override_enabled === 'Y' ? 'yellow.500' : 'red.500'
  return (
    <VStack
      spacing={0}
      borderRadius={4}
      w="100%"
      borderWidth="2px"
      borderColor={statusColor}
    >
      <HStack w="100%" p={2} bg={statusColor} justify="space-between">
        <VStack align="flex-start" spacing={0}>
          {fieldName ? (
            <Text fontSize="sm" color="white">
              {fieldName.toUpperCase()}{' '}
            </Text>
          ) : null}
          <Text color="white">{name}</Text>
        </VStack>
        <IconButton
          minH="0"
          minW="0"
          _hover={{ bg: 'whiteAlpha.400' }}
          h="auto"
          p={2}
          bg="transparent"
          onClick={() => setExpanded(!expanded)}
          aria-label={expanded ? 'collapse' : 'expand'}
          color="white"
          icon={<ChevronDownIcon />}
        />
      </HStack>
      <Collapse in={expanded} style={{ width: '100%' }}>
        <Text px={2} py={1} whiteSpace="pre-wrap">
          {decodeURI(messsage)}
        </Text>
      </Collapse>
    </VStack>
  )
}
const AbilityClaimErrors = ({
  errors,
}: {
  errors?: Array<AbilityClaimError>
}) => {
  const { overridden, notOverridden } = useMemo(() => {
    const over: Array<AbilityClaimError> = []
    const notOver: Array<AbilityClaimError> = []
    errors?.forEach((e) => {
      if (e.override_enabled === 'Y') over.push(e)
      else notOver.push(e)
    })
    return { overridden: over, notOverridden: notOver }
  }, [errors])
  if (!errors?.length) return null
  return (
    <VStack w="100%" align="flex-start">
      <Text color="red.600" decoration="underline">
        ERRORS
      </Text>
      {notOverridden.map((e, i) => (
        <AbilityClaimErrorView key={`error_${i}`} error={e} />
      ))}
      <Expandable
        header={() => (
          <Text py={1} textDecor="underline" color="yellow.600">
            OVERRIDDEN ERRORS
          </Text>
        )}
      >
        <VStack w="100%">
          {overridden.map((e, i) => (
            <AbilityClaimErrorView key={`error_${i}`} error={e} />
          ))}
        </VStack>
      </Expandable>
    </VStack>
  )
}

const AbilityClaimContent = ({
  claim,
  error,
  systemClaim,
  withErrors,
}: {
  claim: AbilityClaim | null
  systemClaim?: Claim | null
  error: string | null
  withErrors?: boolean
}) => {
  const { setUpdatingAbilityId } = useContext(ClaimsViewContext)
  const firstService = useMemo(
    () => Object.values(claim?.Services || {})[0],
    [claim],
  )
  const serviceType = useFormattedValue(
    serviceTypeField,
    systemClaim?.serviceType,
  )
  const statusColor = useMemo(
    () => getInovalonClaimStatusColor(claim?.TransStatus),
    [claim],
  )

  const canResubmit = useMemo(
    () => claim
      && ['ERROR', 'VALIDATED', 'ACK_PENDING'].includes(claim?.TransStatus || ''),
    [claim],
  )
  if (error) {
    return <Text color="red">{error}</Text>
  }
  if (claim) {
    return (
      <>
        {withErrors ? <AbilityClaimErrors errors={claim?.errors} /> : null}
        <ClaimInfo header="SERVICE TYPE">
          <Text isTruncated>{serviceType || 'NONE'}</Text>
        </ClaimInfo>
        <ClaimInfo header="PATIENT CONTROL NO.">
          <Text isTruncated>{claim.PatientCtlNo || 'NONE'}</Text>
        </ClaimInfo>
        {/* <ClaimInfo header='ID'>
          <Text>{claim.TransID || 'NONE'}</Text>
        </ClaimInfo> */}
        <ClaimInfo header="STATUS">
          <HStack>
            <Text color={statusColor}>{claim.TransStatus || 'NONE'}</Text>
            {canResubmit ? (
              <Button
                bg={colors.green.hex}
                color="white"
                onClick={() => setUpdatingAbilityId(systemClaim?.abilityId || null)
                }
                size="xs"
              >
                RESUBMIT
              </Button>
            ) : null}
          </HStack>
        </ClaimInfo>
        <ClaimInfo header="SERVICE DATE">
          <Text>
            {firstService?.FromDate
              ? `${getDateString(firstService.FromDate, 'short')}`
              : 'NONE'}
          </Text>
        </ClaimInfo>
        <ClaimInfo header="CHARGE">
          <Text>${claim.ClaimCharge}</Text>
        </ClaimInfo>
        <ClaimInfo header="CREATED ON">
          <Text isTruncated>
            {claim.CreateDate
              ? getDateTimeString(new Date(claim.CreateDate), 'short')
              : 'NONE'}
          </Text>
        </ClaimInfo>
        <Tooltip
          bg="#d6cf38"
          color="#251f21"
          hasArrow
          placement="top"
          label="Open in Inovalon (Log into Ease All-Payer first)"
        >
          <IconButton
            variant="ghost"
            borderRadius="full"
            aria-label="Open in Inovalon"
            onClick={(e) => {
              e.stopPropagation()
              window.open(
                `https://portal.focusedi.com/claim/view/${systemClaim?.abilityId}/prof`,
                '_blank',
              )
            }}
            icon={
              <Image
                height="20px"
                src="/images/Inovalon-Logo.svg"
                alt="Ability Logo"
              />
            }
          />
        </Tooltip>
      </>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}

const AbilityClaimMoreContent = ({
  claim,
  loading,
}: {
  claim: AbilityClaim | null
  loading: boolean
}) => {
  if (claim) {
    return (
      <>
        <ClaimInfo header="CLAIM NO.">
          <Text isTruncated>{claim?.ClaimID?.ClaimNo || 'NONE'}</Text>
        </ClaimInfo>
        <ClaimInfo header="CREATE MODE">
          <Text isTruncated>{claim.CreateMode || 'NONE'}</Text>
        </ClaimInfo>

        {/* <ClaimInfo header='ID'>
          <Text>{claim.TransID || 'NONE'}</Text>
        </ClaimInfo> */}
      </>
    )
  }
  if (loading) {
    return (
      <HStack>
        <CircularProgress
          isIndeterminate
          size="24px"
          color={colors.green.hex}
        />
        <Text>Loading...</Text>
      </HStack>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}
const ClaimModal = ({
  selectedId,
  selected,
  select,
  onClose,
}: {
  selectedId: string | null
  selected?: Claim
  select: (id: string) => void
  onClose: () => void
}) => {
  const { user, assessmentId } = useContext(UserContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const onSave = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> => {
      if (!user?.id) return { error: 'no user selected' }
      if (!selectedId) return { error: 'no claim selected' }
      if (!assessmentId) return { error: 'no assessment selected' }
      try {
        const newRef = selectedId === 'new' ? doc(CLAIMS_REF) : doc(CLAIMS_REF, selectedId)
        await setDoc(
          newRef,
          { legacy: data, patientId: user.id, assessmentId },
          { merge: true },
        )
        select(newRef.id)
        return { success: 'Saved' }
      } catch (err: any) {
        return { error: err.message }
      }
    },
    [selectedId, user, assessmentId, select],
  )

  const onDeleteLegacy = useCallback(async (): Promise<UpdateCallback> => {
    if (!selectedId) return { error: 'no claim selected' }
    try {
      const docRef = doc(CLAIMS_REF, selectedId)
      const currentDoc = await getDoc(docRef)
      const currentData = currentDoc.data() as Claim | undefined
      if (currentData?.abilityId) {
        await updateDoc(docRef, 'legacy', deleteField())
      } else await deleteDoc(doc(CLAIMS_REF, selectedId))
      onClose()
      return { success: 'Claim deleted' }
    } catch (err: any) {
      console.error(err)
      return { error: err?.message || 'Error deleting claim' }
    }
  }, [selectedId, onClose])
  const submittedToAbility = useMemo(
    () => (selected as Claim)?.submittedToAbility,
    [selected],
  )
  return (
    <DefaultModal
      size="6xl"
      bodyProps={{ minHeight: '440px' }}
      overlayHeader
      isOpen={!!selectedId}
      onClose={onClose}
      render={() => (
        <Tabs
          isLazy
          defaultIndex={selectedId === 'new' || !selected?.abilityId ? 2 : 0}
        >
          <TabList pt={2}>
            <Tab>CLAIM</Tab>
            {selected?.submittedArgs ? <Tab>SUBMISSION FORM</Tab> : null}
            {submittedToAbility ? <Tab>SUBMITTED TO ABILITY</Tab> : null}
            <Tab>LEGACY</Tab>
            <Tab>DELETE CLAIM</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={2}>
              <VStack>
                <AbilityClaimView
                  withMore
                  claimId={selectedId}
                  claim={selected as Claim | undefined}
                />
              </VStack>
            </TabPanel>
            {selected?.submittedArgs ? (
              <TabPanel p={0}>
                <ClaimSubmissionForm claim={selected} />
              </TabPanel>
            ) : null}
            {submittedToAbility ? (
              <TabPanel p={0}>
                <Code minW="100%" p={4} whiteSpace="pre-wrap">
                  {JSON.stringify(submittedToAbility, null, 2)}
                </Code>
              </TabPanel>
            ) : null}
            <TabPanel padding={0}>
              <DataView
                initEditing={selectedId === 'new'}
                onSubmit={onSave}
                boxProps={{ px: 2 }}
                withEditModal
                field={claimField}
                onEditingChange={() => {
                  if (selectedId === 'new') onClose()
                }}
                onDelete={async () => {
                  if (selectedId === 'new') {
                    onClose()
                    return { success: 'true' }
                  }
                  return onDeleteLegacy()
                }}
                data={
                  selectedId === 'new'
                    ? { submissionDate: Date.now() }
                    : selected?.legacy
                }
              />
            </TabPanel>
            <TabPanel>
              <HStack>
                <DeleteButton
                  itemName="claim"
                  text="DELETE CLAIM"
                  size="md"
                  onDelete={async () => {
                    if (!selectedId) return
                    try {
                      await deleteDoc(doc(CLAIMS_REF, selectedId))
                      onClose()
                    } catch (err: any) {
                      processResponse({
                        error: `Error deleting claim: ${err.message}`,
                      })
                    }
                  }}
                />
                <Text color="gray.600">
                  This will delete the claim from the Hamilton Billing system,
                  but not from Inovalon
                </Text>
              </HStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    />
  )
}

const LinkClaimModal = ({ onClose }: { onClose: () => void }) => {
  const { assessmentId } = useContext(UserContext)
  return (
    <DefaultModal
      overlayHeader
      isOpen
      onClose={onClose}
      render={() => (
        <VStack p={3} w="100%">
          <LinkAbilityClaim
            assessmentId={assessmentId || ''}
            alwaysOpen
            claimId={null}
            onCancel={onClose}
          />
        </VStack>
      )}
    />
  )
}

const NewClaimModal = ({
  select,
  isOpen,
  onClose,
}: {
  select: (id: string) => void
  isOpen: boolean
  onClose: () => void
}) => {
  const { assessmentId, user } = useContext(UserContext)
  const onSave = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> => {
      if (!user?.id) return { error: 'no user selected' }
      if (!assessmentId) return { error: 'no assessment selected' }
      try {
        const newRef = doc(CLAIMS_REF)
        await setDoc(
          newRef,
          { legacy: data, patientId: user.id, assessmentId },
          { merge: true },
        )
        select(newRef.id)
        return { success: 'Saved' }
      } catch (err: any) {
        return { error: err.message }
      }
    },
    [user, assessmentId, select],
  )

  return (
    <GenericEditModal
      field={claimField}
      onSubmit={onSave}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

const ClaimsTabSubmitClaim = ({
  existingAbilityId,
  claim,
  onClose,
}: {
  existingAbilityId?: string | null
  claim: Claim | undefined
  onClose: () => void
}) => (existingAbilityId ? (
    <ResubmitAbilityClaim
      serviceType={claim?.serviceType}
      existingAbilityId={existingAbilityId}
      onClose={onClose}
    />
) : (
    <AbilityClaimSubmission onClose={onClose} />
))

const ClaimsTabContent = ({
  onLegacyCreate,
  onClaimSubmit,
  onLinkClaim,
  selectedClaimId,
  onSelectClaim,
}: {
  onLegacyCreate: () => void
  onClaimSubmit: () => void
  onLinkClaim: () => void
  selectedClaimId: string | null
  onSelectClaim: (id: string | null) => void
}) => {
  const {
    selectedAssessment,
    assessmentId,
    claims: { data: claims },
  } = useContext(UserContext)
  const {
    insuranceProvider,
    claimsNote,
    adminRef,
    patientId,
    drafts,
  } = selectedAssessment || {}
  const sortedClaims = useMemo(
    () => (claims ? Object.entries(claims) : []),
    [claims],
  )
  const selected = useMemo(
    () => (selectedClaimId !== null ? claims?.[selectedClaimId] : undefined),
    [selectedClaimId, claims],
  )

  // const { processResponse } = useContext(PopUpMessageContext)
  // const { appName } = useApp()

  // const handleSubmitDeliveryDate = useCallback(
  //   async (d: FieldMapValue) => {
  //     if (!assessmentId) {
  //       return { error: 'Internal error' }
  //     }

  //     return updateAssessmentDeliveryStatus({
  //       deliveredOn: d.value || null,
  //       assessmentId,
  //       appName,
  //     })
  //       .then(() => processResponse({ success: 'Report delivery updated' }))
  //       .catch((err: any) => {
  //         console.error(err)
  //         return processResponse({
  //           error: err?.message || 'Error updating report delivery',
  //         })
  //       })
  //   },
  //   [processResponse, assessmentId],
  // )

  const update = useUpdateDoc()
  return (
    <VStack spacing={0} px={2} align="flex-start" w="100%">
      <FloatingContainer p={0} style={{ padding: 0 }} bg="white" w="100%">
        <Stack
          px={4}
          pt={4}
          pb={3}
          bg={`${colors.pink.hex}88`}
          pos="relative"
          w="100%"
        >
          <HStack w="100%">
            <ActionButton
              flex={1}
              size="sm"
              bg="white"
              color="green.500"
              fontFamily="Hero-New"
              fontWeight={500}
              onClick={onLegacyCreate}
            >
              + ADD CLAIM (LEGACY)
            </ActionButton>
            <Button
              // colorScheme='telegram'
              size="sm"
              bg="green.300"
              color="white"
              overflow="hidden"
              _hover={{ bg: 'green.200' }}
              onClick={onLinkClaim}
            >
              <Image
                position="absolute"
                opacity={0.2}
                zIndex={0}
                height="80px"
                src="/images/Inovalon-Logo.svg"
                alt="Ability Logo"
              />
              <LinkIcon mr={1} color="white" />
              <Text
                textShadow="1px 1px 2px #555"
                fontFamily="Hero-New"
                fontWeight={500}
                zIndex={2}
              >
                LINK CLAIM
              </Text>
            </Button>
            <Button
              // colorScheme='telegram'
              size="sm"
              bg="green.300"
              color="white"
              overflow="hidden"
              _hover={{ bg: 'green.200' }}
              onClick={onClaimSubmit}
            >
              <Image
                position="absolute"
                opacity={0.2}
                zIndex={0}
                height="80px"
                src="/images/Inovalon-Logo.svg"
                alt="Ability Logo"
              />
              <Text
                textShadow="1px 1px 2px #555"
                fontFamily="Hero-New"
                fontWeight={500}
                zIndex={2}
              >
                SUBMIT CLAIM
              </Text>
            </Button>
          </HStack>
          <VStack w="100%">
            {sortedClaims.map(([claimKey, claimData]) => (
              <ClaimView
                id={claimKey}
                key={claimKey}
                onClick={() => onSelectClaim(claimKey)}
                claim={claimData}
              />
            ))}
          </VStack>
        </Stack>
      </FloatingContainer>
      <ReferenceNumbersEdit id="claimsReferenceNumbers" />
      <NoteForm
        note={claimsNote}
        boxProps={{ mt: 2 }}
        onSubmit={(data) => update(adminRef, 'claimsNote', data)}
      />
      <Flex py={2} gap={2} flexFlow="column" w="100%">
        <DraftView
          name="Claims Template"
          templateKey="claims"
          draft={drafts?.claimAppealsTemplates}
        />
        {assessmentId && patientId ? (
          <AssessmentDocuments
            adminView
            patientId={patientId}
            category="claims"
            assessmentId={assessmentId}
          />
        ) : null}
      </Flex>
      <Box width="100%" p={2}>
        <Text fontWeight={600} color="gray.700">
          {insuranceProvider ? `${insuranceProvider.name} ` : 'Insurer '}
          Claims Cheat Sheet
        </Text>
        <Text
          fontStyle={insuranceProvider?.claimsSheet ? 'normal' : 'italic'}
          color={insuranceProvider?.claimsSheet ? 'black' : 'gray.700'}
          whiteSpace="pre-wrap"
        >
          {insuranceProvider
            ? insuranceProvider.claimsSheet
              || `No claims cheat sheet for ${insuranceProvider.name}`
            : 'No insurance provider'}
        </Text>
      </Box>
      {selectedClaimId ? (
        <ClaimModal
          select={onSelectClaim}
          selected={selected}
          selectedId={selectedClaimId}
          onClose={() => onSelectClaim(null)}
        />
      ) : null}
    </VStack>
  )
}

export const AssessmentClaims = () => {
  const [creatingNewAbilityClaim, setCreatingNewAbilityClaim] = useState(false)
  const [isLinking, setIsLinking] = useState(false)

  const [creatingNewLegacy, setCreatingNewLegacy] = useState(false)
  const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null)
  const [updatingAbilityId, setUpdatingAbilityId] = useState<string | null>(
    null,
  )

  const { claims } = useContext(UserContext)
  const updatingAbilityClaim = useMemo(() => {
    if (!updatingAbilityId) return undefined
    return (
      Object.values(claims.data || {}).find(
        (c) => c.abilityId === updatingAbilityId,
      ) || undefined
    )
  }, [updatingAbilityId, claims])

  const contextData = useMemo<ClaimsViewData>(
    () => ({
      creatingNewAbilityClaim,
      setCreatingNewAbilityClaim,
      select: setSelectedClaimId,
      selectedId: selectedClaimId,
      creatingNewLegacy,
      setCreatingNewLegacy,
      updatingAbilityId,
      setUpdatingAbilityId,
    }),
    [
      selectedClaimId,
      setSelectedClaimId,
      setUpdatingAbilityId,
      updatingAbilityId,
      creatingNewAbilityClaim,
      creatingNewLegacy,
    ],
  )

  return (
    <ClaimsViewContext.Provider value={contextData}>
      <VStack p={2} pos="relative" w="100%">
        {creatingNewAbilityClaim || updatingAbilityId ? (
          <ClaimsTabSubmitClaim
            claim={updatingAbilityClaim}
            onClose={() => {
              setCreatingNewAbilityClaim(false)
              setUpdatingAbilityId(null)
              setSelectedClaimId(null)
            }}
            existingAbilityId={updatingAbilityId}
          />
        ) : (
          <ClaimsTabContent
            selectedClaimId={selectedClaimId}
            onSelectClaim={setSelectedClaimId}
            onLinkClaim={() => setIsLinking(true)}
            onClaimSubmit={() => setCreatingNewAbilityClaim(true)}
            onLegacyCreate={() => setCreatingNewLegacy(true)}
          />
        )}

        {creatingNewLegacy ? (
          <NewClaimModal
            isOpen
            onClose={() => setCreatingNewLegacy(false)}
            select={setSelectedClaimId}
          />
        ) : null}

        {isLinking ? (
          <LinkClaimModal onClose={() => setIsLinking(false)} />
        ) : null}
      </VStack>
    </ClaimsViewContext.Provider>
  )
}
